
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class BannerImage extends Vue {
    @Prop({ default: null }) imageId!: string | null;

    @Prop() tenantId!: number;

    @Prop({ default: false }) lazy: boolean;

    get imageUrl() {
        return `${process.env.VUE_APP_BANNER_IMAGE_BASE_URL}/${this.tenantId}/${this.imageId}`;
    }
}
