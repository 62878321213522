
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
    components: {},
})
export default class GoogleMap extends Vue {
    @Prop({ type: Number, required: false }) latitude!: number;

    @Prop({ type: Number, required: false }) longitude!: number;

    @Prop({ type: Boolean, required: true }) showControls!: boolean;

    @Prop({ type: Boolean, required: true }) showMarker!: boolean;

    @Prop({ type: Number, required: false, default: 15 }) zoom!: number;

    async mounted() {
        await this.refreshMap();
    }

    async refreshMap() {
        // eslint-disable-next-line no-undef
        const mapOptions: google.maps.MapOptions = {
            center: {
                lat: this.latitude,
                lng: this.longitude,
            },
            zoom: this.zoom,
            disableDefaultUI: !this.showControls,
            fullscreenControl: true,
        };

        // eslint-disable-next-line no-undef
        const map = new google.maps.Map(
            document.getElementById("g-map") as HTMLElement,
            mapOptions
        );

        if (this.showMarker) {
            // eslint-disable-next-line no-undef
            new google.maps.Marker({
                position: {
                    lat: this.latitude,
                    lng: this.longitude,
                },
                map,
            });
        }
    }
}
