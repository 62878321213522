
import { Component, Vue } from "vue-property-decorator";
import { FadeTransition } from "vue2-transitions";
import TheTopBar from "@/components/TheTopBar.vue";
import HelloWorld from "@/components/HelloWorld.vue";
import { routes } from "./router/index";
import store from "@/store";
import Utilities from "./utils/Utilities";
import DashboardNavbar from "@/views/layout/DashboardNavbar.vue";

@Component({
    components: {
        "fade-transition": FadeTransition,
        "the-top-bar": TheTopBar,
        "hello-world": HelloWorld,
        // "content-footer": ContentFooter,
        "dashboard-navbar": DashboardNavbar,
    },
})
export default class TheApp extends Vue {
    drawer: any = null;

    items = [
        {
            icon: "house-door",
            text: "Home",
            routeName: "Home",
            policy: "dummy",
        },
        {
            icon: "building",
            text: "Clients",
            routeName: "Clients",
            policy: "HasClientViewPermission",
        },
        {
            icon: "list",
            text: "Programmes",
            routeName: "Programmes",
            policy: "HasProgrammeViewPermission",
        },
        {
            icon: "briefcase",
            text: "Events",
            routeName: "Events",
            policy: "HasEventViewPermission",
        },
        {
            icon: "people",
            text: "Team",
            routeName: "Team",
            policy: "HasTrainerViewPermission",
        },
        {
            icon: "chat",
            text: "Messages",
            routeName: "Messages",
            showUserUnreadMessages: true,
            policy: "HasMessageViewPermission",
        },
        {
            icon: "calendar",
            text: "Calendar",
            routeName: "Calendar",
            policy: "HasCalenderViewPermission",
        },
        {
            icon: "link",
            text: "Resources",
            routeName: "Resources",
            policy: "HasResourceViewPermission",
        },
        {
            icon: "gear",
            text: "Settings",
            model: false,
            policy: "HasTenantEditPermission",
            children: [
                {
                    text: "Account",
                    routeName: "Account",
                    policy: "HasTenantEditPermission",
                },
                // {
                //     text: "Subscription",
                //     routeName: "Subscription",
                //     policy: "HasTenantEditPermission",
                // },
                {
                    text: "Subscription",
                    routeName: "StripeSubscription",
                    policy: "HasTenantEditPermission",
                },
                {
                    text: "Usage",
                    routeName: "Usage",
                    policy: "HasTenantEditPermission",
                },
                {
                    text: "Integrations",
                    routeName: "Integrations",
                    policy: "HasTenantEditPermission",
                },
            ],
        },
        // {
        //     class: "info-circle",
        //     text: "Admin",
        //     routeName: "Admin",
        //     policy: "HasGiraffePadAdminPermission",
        //     model: false,
        //     children: [
        //         {
        //             text: "General", routeName: "AdminIndex", policy: "HasGiraffePadAdminPermission",
        //         },
        //         {
        //             text: "Tenants", routeName: "AdminTenants", policy: "HasGiraffePadAdminPermission",
        //         },
        //         {
        //             text: "Learners", routeName: "AdminLearners", policy: "HasGiraffePadAdminPermission",
        //         },
        //         {
        //             text: "Integrations", routeName: "Integrations", policy: "HasGiraffePadAdminPermission", featureFlags: ["ZoomIntegration"],
        //         },
        //     ],
        // },
    ];

    get userItems() {
        // check required roles
        const menuItemsForUser = this.menuItemsForUser(this.items);

        // check policy

        // check featureFlags

        // console.log("userItems", menuItemsForUser);
        return menuItemsForUser;
    }

    get buildVersion() {
        // console.log("process.env", process.env);
        return process.env.VUE_APP_GIRAFFEPAD_BUILD;
    }

    get environmentName() {
        return process.env.VUE_APP_GIRAFFEPAD_ENV;
    }

    onMenuItemClicked(item: any) {
        this.$router.push({ name: item.routeName });
    }

    menuItemsForUser(items: any[]): any[] {
        // console.log("menuItemsForUser() before filtering", items);
        const itemsForUser = items
            .filter((item) => {
                // find route with item's routeName
                // console.log("menuItemsForUser() filtering route: ", item.routeName);
                const itemRoute = routes.find((route) => route.name === item.routeName);
                if (item.children && item.children.length > 0) {
                    // console.log("itemRoute has children: ", itemRoute.name);
                    return true;
                }
                if (!itemRoute) {
                    // console.log("itemRoute not found for route: ", item);
                    return false;
                }
                if (itemRoute.redirect) {
                    // console.log("don't show redirected routes: ", item);
                    return false;
                }
                if (!itemRoute.meta) {
                    // console.log("itemRoute has no metadata: ", itemRoute.name);
                    return true;
                }
                // if (
                //     itemRoute.meta?.requireUsageBasedSubscription? === true &&
                //     Utilities.userTenantHasPerSeatSubscription(store.state.user)
                // ) {
                //     return false;
                // }
                const userIsInRequiredRole =
                    !itemRoute.meta.requiredRoles ||
                    Utilities.userIsInAnyOfRoles(
                        store.state.user,
                        itemRoute.meta.requiredRoles as string[]
                    );

                const userHasRequiredClaims =
                    !itemRoute.meta.requiredClaims ||
                    Utilities.userHasAnyOfClaims(
                        store.state.user,
                        itemRoute.meta.requiredClaims as string[]
                    );

                // console.log("user is in required role for itemRoute: ", itemRoute.name, userIsInRequiredRole);
                return userIsInRequiredRole && userHasRequiredClaims;
            })
            .map((item) => ({
                ...item,
                children: item.children ? this.menuItemsForUser(item.children) : null,
            }));

        // console.log("menuItemsForUser() for user after filtering", itemsForUser);

        // Strip out any routeless items that have no children (i.e. submenus with no allowed items for the user)
        const filteredItems = itemsForUser.filter((item) => {
            const route = routes.find((r) => r.name && r.name === item.routeName);
            // console.log(`Looking for route with name ${item.routeName}, found : `, route);
            const showItemToUser =
                route !== undefined || (item.children && item.children.length > 0);
            // console.log("Show route: ", item.routeName, route, showItemToUser);
            return showItemToUser;
        });

        // console.log("menuItemsForUser() filtered for user", filteredItems);

        return filteredItems;
    }
}
