
import { Component, Prop, Vue } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import store from "@/store/index";
import Utilities from "@/utils/Utilities";

@Component({
    components: {},
})
export default class SignInView extends Vue {
    @Prop({ type: String, required: false, default: "" }) emailProp!: string;

    private validationObserver!: InstanceType<typeof ValidationObserver>;

    email = "";

    password = "";

    error = "";

    signingIn = false;

    mounted() {
        this.validationObserver = this.$refs.form as InstanceType<typeof ValidationObserver>;
        if (this.emailProp.length) {
            this.email = this.emailProp;
            this.$nextTick(() => (this.$refs.password as any).focus());
        }
    }

    async trySignIn() {
        this.signingIn = true;
        const { email } = this;
        const { password } = this;
        this.error = "";
        this.$store
            .dispatch("signIn", { email, password })
            .then(() => {
                this.signingIn = false;
                let redirectPath = "/programmes";
                if (Utilities.userTenantId(store.state.user) != null) {
                    redirectPath = "/home";
                }

                if (this.$route.query.redirect && redirectPath !== "%2F") {
                    redirectPath = String(this.$route.query.redirect!);
                }

                this.$router.push(redirectPath!);
            })
            .catch((err) => {
                console.log("Sign in attempt failed", err);
                this.signingIn = false;
                this.password = "";
                this.error = err;

                if (err === "Email address not confirmed") {
                    this.$router.push({
                        name: "EmailAddressNotConfirmed",
                        params: { emailProp: this.email },
                    });
                }

                // Wait until the models are updated in the UI
                this.$nextTick(() => {
                    this.validationObserver.reset();
                });
            });
    }
}
