
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class VideoResourcePreviewDialog extends Vue {
    @Prop({ required: true, default: "" }) title!: string;

    @Prop({ required: false, default: "" }) url!: string;

    @Prop({ required: false, default: "" }) format!: string;

    created() {
        console.log("VideoResourcePreviewDialog created()");
    }

    onHidden() {
        this.$emit("close");
    }
}
