
import Vue, { defineComponent } from "vue";
import * as azblob from "@azure/storage-blob";
import uuid from "uuid-random";
import Utilities from "@/utils/Utilities";
import LogUtils from "@/utils/LogUtils";

export default defineComponent({
    name: "UploadFileDialog",
    props: {
        title: {
            type: String,
            required: true,
        },
        eventId: {
            type: Number,
            required: false,
        },
        programmeId: {
            type: Number,
            required: false,
        },
        learnerId: {
            type: Number,
            required: false,
        },
        folderId: {
            type: Number,
            required: false,
        },
        isTrainerResource: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            file: null,
            uploadButtonText: "Upload",
            uploadButtonEnabled: true,
            uploading: false,
        };
    },
    methods: {
        onCancel() {
            console.log("onCancel() called");
            this.file = null;
            this.uploadButtonText = "Upload";
        },
        onUploadFileResourceToAzure(bvModalEvt) {
            // Prevent modal from closing
            console.log(bvModalEvt);
            bvModalEvt.preventDefault();

            const vm = this;

            console.log(azblob);

            Vue.prototype.$http
                .get("/v1/resources/blobuploadurl")
                .then(async (res2) => {
                    const { blobUploadUrl } = res2.data;

                    const fileName = vm.file?.name;
                    const fileType = vm.file?.type;

                    // Update button text.
                    vm.uploadButtonText = "Uploading...";
                    vm.uploadButtonEnabled = false;

                    const maxFileSizeMB = 50;

                    // Check the file size.
                    if (vm.file?.size > maxFileSizeMB * 1024 * 1024) {
                        Utilities.gpErrorAlert(
                            this.$bvModal,
                            `The maximum upload file size is ${maxFileSizeMB}MB.`
                        );
                    } else {
                        const blobName = uuid();
                        const blockBlobURL = azblob.BlockBlobURL.fromContainerURL(
                            vm.getContainerUrl(blobUploadUrl),
                            blobName
                        );
                        const blobOptions = { blobHTTPHeaders: { blobContentType: vm.file?.type } };

                        vm.uploading = true;
                        await azblob
                            .uploadBrowserDataToBlockBlob(
                                azblob.Aborter.none,
                                vm.file,
                                blockBlobURL,
                                blobOptions
                            )
                            .then(() => {
                                // Add the file to the request.

                                let postUrl = "";
                                if (vm.eventId) {
                                    if (this.learnerId) {
                                        postUrl = `/v1/events/${vm.eventId}/learners/${vm.learnerId}/azureblobresources`;
                                    } else {
                                        postUrl = `/v1/events/${vm.eventId}/azureblobresources`;
                                    }
                                } else if (this.programmeId) {
                                    if (this.learnerId) {
                                        postUrl = `/v1/programmes/${vm.programmeId}/learners/${vm.learnerId}/azureblobresources`;
                                    } else {
                                        postUrl = `/v1/programmes/${vm.programmeId}/azureblobresources`;
                                    }
                                } else {
                                    postUrl = "/v1/resources/azureblobresources";
                                }

                                postUrl = `${postUrl}?isTrainerResource=${
                                    this.isTrainerResource ? "true" : "false"
                                }`;

                                if (this.folderId) {
                                    postUrl = `${postUrl}&folderId=${this.folderId}`;
                                }

                                Vue.prototype.$http
                                    .post(postUrl, { blobName, fileName, fileType })
                                    .then((res) => {
                                        const resourceAdded = res.data;
                                        vm.$emit("resource-uploaded", resourceAdded);
                                        this.$bvModal.hide("upload-file-dialog");
                                    })
                                    .catch((err) => {
                                        LogUtils.Error(
                                            "Failed to create blob resource",
                                            err,
                                            "UploadFileDialog"
                                        );
                                        Utilities.gpErrorAlert(
                                            this.$bvModal,
                                            "Unable to upload resource at this time"
                                        );
                                        this.$bvModal.hide("upload-file-dialog");
                                    });
                            })
                            .catch((err) => {
                                LogUtils.Error(
                                    "Failed to upload resource blob to Azure",
                                    err,
                                    "UploadFileDialog"
                                );
                                Utilities.gpErrorAlert(
                                    this.$bvModal,
                                    "Unable to upload resource at this time"
                                );
                                this.$bvModal.hide("upload-file-dialog");
                                vm.uploadButtonText = "Upload";
                                vm.uploadButtonEnabled = true;
                                vm.uploading = false;
                                vm.file = null;
                            });
                    }

                    this.$bvModal.hide("upload-file-dialog");
                    vm.uploadButtonText = "Upload";
                    vm.uploadButtonEnabled = true;
                    vm.uploading = false;
                    vm.file = null;
                })
                .catch((err) => {
                    LogUtils.Error(
                        "Unable to upload file resource to Azure - cannot retrieve upload url",
                        err,
                        "UploadFileDialog"
                    );
                    Utilities.gpErrorAlert(this.$bvModal, "Unable to upload resource at this time");
                    this.$bvModal.hide("upload-file-dialog");
                    vm.uploadButtonText = "Upload";
                    vm.uploadButtonEnabled = true;
                    vm.uploading = false;
                    vm.file = null;
                });
        },
        getContainerUrl(blobUploadUrl) {
            return new azblob.ContainerURL(
                blobUploadUrl,
                azblob.StorageURL.newPipeline(new azblob.AnonymousCredential())
            );
        },
    },
});
