import { render, staticRenderFns } from "./ProgrammesView.vue?vue&type=template&id=09b6aa9c&scoped=true"
import script from "./ProgrammesView.vue?vue&type=script&lang=ts"
export * from "./ProgrammesView.vue?vue&type=script&lang=ts"
import style0 from "./ProgrammesView.vue?vue&type=style&index=0&id=09b6aa9c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09b6aa9c",
  null
  
)

export default component.exports