
import { defineComponent } from "vue";
import Vue from "vue";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import store from "@/store/index";
import Utilities from "@/utils/Utilities";
import LogUtils from "@/utils/LogUtils";

export default defineComponent({
    props: {
        displayDate: {
            type: String,
            required: false,
        },
    },
    components: {
        FullCalendar, // make the <FullCalendar> tag available
    },
    data() {
        return {
            calendarPlugins: [dayGridPlugin],
            events: [],
        };
    },
    created() {
        const vm = this;
        Vue.prototype.$http
            .get("/v1/events?utc=true")
            .then((res) => {
                // console.log("Events", res.data);
                vm.events = res.data.map((e) => ({
                    id: e.id,
                    programmeId: e.programmeId,
                    eventType: e.eventType,
                    title: e.eventType === 3 || e.eventType === 4 ? `Due: ${e.title}` : e.title,
                    // description: e.description,
                    start:
                        e.eventType === 3 || e.eventType === 4
                            ? e.endDateAndTime
                            : e.startDateAndTimeUtc,
                    end:
                        e.eventType === 3 || e.eventType === 4
                            ? e.endDateAndTime
                            : e.endDateAndTimeUtc,
                    backgroundColor: this.getEventColour(e),
                    allDay: e.eventType === 3 || e.eventType === 4,
                    attending: e.attending,
                    personallyFacilitating: e.facilitating,
                    tenantFacilitating: Utilities.userTenantId(store.state.user) === e.tenant.id,
                    userIsAnEventTrainer: Utilities.userIsAnEventTrainer(store.state.user, e),
                    url: this.getEventUrl(e),
                }));
                // console.log("Mapped events", vm.events);
            })
            .catch((err) => {
                LogUtils.Error("Could not load events for calendar", err, "CalendarView");
                // vm.error = "Could not load events...";
            });
    },
    computed: {
        eventTimeFormat() {
            return {
                hour: "2-digit",
                minute: "2-digit",
                meridiem: true,
            };
        },
        headerConfig() {
            return {
                left: "prev,next",
                center: "title",
                right: "dayGridWeek,dayGridMonth",
            };
        },
    },
    methods: {
        onEventClick(info) {
            info.jsEvent.preventDefault(); // don't let the browser navigate

            console.log(info);
            const calendarEvent = info.event;

            if (
                calendarEvent.extendedProps.personallyFacilitating &&
                Utilities.userHasActiveTenantSubscription(store.state.user)
            ) {
                if (calendarEvent.extendedProps.eventType === 3) {
                    this.$router.push({
                        name: "Activity",
                        params: {
                            programmeId: calendarEvent.extendedProps.programmeId,
                            activityId: calendarEvent.id,
                        },
                    });
                } else if (calendarEvent.extendedProps.eventType === 4) {
                    this.$router.push({
                        name: "Questionnaire",
                        params: {
                            programmeId: calendarEvent.extendedProps.programmeId,
                            questionnaireId: calendarEvent.id,
                        },
                    });
                } else {
                    this.$router.push({ name: "Event", params: { eventId: calendarEvent.id } });
                }
            } else if (calendarEvent.extendedProps.attending) {
                if (calendarEvent.extendedProps.eventType === 3) {
                    this.$router.push({
                        name: "ActivityInfo",
                        params: {
                            programmeId: calendarEvent.extendedProps.programmeId,
                            activityId: calendarEvent.id,
                        },
                    });
                } else if (calendarEvent.extendedProps.eventType === 4) {
                    this.$router.push({
                        name: "QuestionnaireInfo",
                        params: {
                            programmeId: calendarEvent.extendedProps.programmeId,
                            questionnaireId: calendarEvent.id,
                        },
                    });
                } else {
                    this.$router.push({ name: "EventInfo", params: { eventId: calendarEvent.id } });
                }
            }
        },
        getEventUrl(event) {
            // console.log(event);
            let url = "";
            if (event.facilitating) {
                if (Utilities.userHasActiveTenantSubscription(store.state.user)) {
                    if (event.eventType === 3) {
                        url = `/programmes/${event.programmeId}/activities/${event.id}`;
                    } else if (event.eventType === 4) {
                        url = `/programmes/${event.programmeId}/questionnaires/${event.id}`;
                    } else {
                        url = `/events/${event.id}`;
                    }
                }
            }
            if (event.attending) {
                if (event.eventType === 3) {
                    url = `/programmes/${event.programmeId}/activities/${event.id}/info`;
                } else if (event.eventType === 4) {
                    url = `/programmes/${event.programmeId}/questionnaires/${event.id}/info`;
                } else {
                    url = `/events/${event.id}/info`;
                }
            }
            // console.log("getEventUrl", event, url);
            return url;
        },
        getEventColour(event) {
            if (event.eventType === 3 || event.eventType === 4) {
                return "#e15e00";
            }
            if (event.facilitating) {
                return "#0000aa";
            }
            if (event.attending) {
                return "#00aa00";
            }
            return "#8888aa"; // tenant is facilitating
        },
    },
});
