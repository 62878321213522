
import { defineComponent } from "vue";
import Utilities from "@/utils/Utilities";
import UserAvatar from "@/components/UserAvatar.vue";

export default defineComponent({
    components: {
        "user-avatar": UserAvatar,
    },
    name: "LearnerSelectionDialog",
    props: {
        title: {
            type: String,
            required: true,
        },
        buttonText: {
            type: String,
            required: true,
        },
        learners: {
            required: true,
            default: [],
        },
        id: {
            type: String,
            required: false,
            default: "learner-selection-dialog",
        },
    },
    data() {
        return {
            selectedLearners: [],
            isSelectAll: false,
        };
    },
    computed: {
        registeredLearners() {
            console.log("learners", this.learners);
            return Utilities.sortbyFirstNameLastName(
                this.learners.filter((person) => person.hasCompletedRegistration)
            );
        },
        unregisteredLearners() {
            return Utilities.sortbyFirstNameLastName(
                this.learners.filter((person) => !person.hasCompletedRegistration)
            );
        },
    },
    methods: {
        onCancel() {
            this.selectedLearners = [];
            this.isSelectAll = false;
            this.$bvModal.hide(this.id);
        },
        myIncludes(container, value) {
            let returnValue = false;
            const pos = container.indexOf(value);
            if (pos >= 0) {
                returnValue = true;
            }
            return returnValue;
        },
        selectAll() {
            this.isSelectAll = !this.isSelectAll;
            this.selectedLearners = [];
            if (this.isSelectAll) {
                // Check all
                console.log("Learners ", this.learners);

                for (let i = 0; i < this.learners.length; i) {
                    console.log("Selected learner ", this.learners[i]);
                    this.selectedLearners.push(this.learners[i].id);
                    i += 1;
                }
            }
        },
        onSelected() {
            // Prevent modal from closing
            // console.log(bvModalEvt);
            // bvModalEvt.preventDefault();

            console.log("Called onSelected()");
            const vm = this;
            vm.$emit(
                "learners-selected",
                vm.learners.filter((learner) => vm.myIncludes(vm.selectedLearners, learner.id)),
                this.id
            );
            this.selectedLearners = [];
            this.isSelectAll = false;
        },
        updateSelectAll() {
            this.$nextTick(() => {
                if (this.selectedLearners.length === this.learners.length) {
                    this.isSelectAll = true;
                } else {
                    this.isSelectAll = false;
                }
            });
        },
        fullName(firstName, lastName) {
            return `${firstName} ${lastName}`;
        },
    },
});
