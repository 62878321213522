import { render, staticRenderFns } from "./SignUpTenantView.vue?vue&type=template&id=fcc2bb4e&scoped=true"
import script from "./SignUpTenantView.vue?vue&type=script&lang=ts"
export * from "./SignUpTenantView.vue?vue&type=script&lang=ts"
import style0 from "./SignUpTenantView.vue?vue&type=style&index=0&id=fcc2bb4e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fcc2bb4e",
  null
  
)

export default component.exports