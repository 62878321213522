
import { Component, Prop, Vue } from "vue-property-decorator";
import Utilities from "@/utils/Utilities";
import PdfResourcePreviewDialog from "@/components/resources/PdfResourcePreviewDialog.vue";
import ImageResourcePreviewDialog from "@/components/resources/ImageResourcePreviewDialog.vue";
import VideoResourcePreviewDialog from "@/components/resources/VideoResourcePreviewDialog.vue";
import ExternalVideoResourcePreviewDialog from "@/components/resources/ExternalVideoResourcePreviewDialog.vue";

@Component({
    components: {
        PdfResourcePreviewDialog,
        ImageResourcePreviewDialog,
        VideoResourcePreviewDialog,
        ExternalVideoResourcePreviewDialog,
    },
})
export default class ResourcePreviewer extends Vue {
    @Prop({ required: true }) resource!: any;

    get isPDF() {
        return Utilities.resourceIsPDF(this.resource);
    }

    get isImage() {
        return Utilities.resourceIsImage(this.resource);
    }

    get isUploadedVideo() {
        return Utilities.resourceIsUploadedVideo(this.resource);
    }

    get isExternalVideo() {
        return Utilities.resourceIsExternalVideo(this.resource);
    }

    created() {
        console.log("ResourcePreviewer created.", this.resource);
    }

    onClosed() {
        this.$emit("close");
    }
}
