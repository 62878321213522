
import { Component, Prop, Vue } from "vue-property-decorator";
import store from "@/store/index";
import Utilities from "@/utils/Utilities";

@Component({
    components: {},
})
export default class SubscriptionRequired extends Vue {
    @Prop({ required: true, default: "" }) actionText!: string;

    activeTenantSubscription: boolean = Utilities.userHasActiveTenantSubscription(store.state.user);

    userIsTenantAdmin: boolean = Utilities.userIsTenantAdmin(store.state.user);

    userIsMemberOfTenant: boolean = Utilities.userTenantId(store.state.user) !== null;
}
