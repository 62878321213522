import "core-js/stable";
import "mutationobserver-shim";
import Vue from "vue";
import axios from "axios";
import { Loader, LoaderOptions } from "google-maps";
import { SpinnerPlugin } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VueMq from "vue-mq";
import { FileManagerComponent, FileManagerPlugin } from "@syncfusion/ej2-vue-filemanager";
import DashboardPlugin from "./plugins/dashboard-plugin";
import TheApp from "./TheApp.vue";
import router from "./router";
import store from "./store";
import LogUtils from "@/utils/LogUtils";
import "./utils/ValidationRules";
import "@/assets/sass/giraffepad.scss";
import { registerLicense } from "@syncfusion/ej2-base";
import { RaygunOptions, RaygunStatic } from "raygun4js";

Vue.component(FileManagerPlugin.name, FileManagerComponent);

Vue.config.productionTip = false;

console.log(process.env);
declare global {
    interface Window {
        Raygun: RaygunStatic;
    }
}
// console.log(window.Raygun);
// eslint-disable-next-line no-debugger
// debugger;
// window.Raygun.init(process.env.VUE_APP_RAYGUN_API_KEY_TOKEN, {
//     // Add some or all of the options below
//     ignoreAjaxAbort: true,
//     ignoreAjaxError: true,
//     debugMode: process.env.VUE_APP_GIRAFFEPAD_ENV === "development",
//     ignore3rdPartyErrors: true,
//     captureUnhandledRejections: true,
//     setCookieAsSecure: true,
//     disableAnonymousUserTracking: false,
// } as RaygunOptions);
// // window.Raygun("enableCrashReporting", true);
// window.Raygun.setVersion(process.env.VUE_APP_GIRAFFEPAD_BUILD);

function ignoreThisError(err: Error) {
    // Code here to determine whether or not to send the payload
    // to the error API
    // return true to ignore the payload

    console.error(err);

    if (process.env.VUE_APP_GIRAFFEPAD_ENV === "development") {
        // eslint-disable-next-line
        alert(`ERROR: ${err?.message}`);
    }

    // Some errors that we can't catch elsewhere but don't want to log
    if (err?.message === "Network Error") return true;

    // Errors thrown by vue-pdf package
    if (err?.message.indexOf("Rendering cancelled, page") !== -1) return true;
    if (err?.message?.indexOf("(evaluating 'u.cancel().catch')") !== -1) return true;

    if (err?.stack?.indexOf("renderPage") !== -1) {
        if (err?.message?.indexOf("undefined") !== -1) return true;
    }

    // Errors thrown by ej2-filemanager package
    if (err?.message === "Cannot read properties of undefined (reading 'getSelectedRecords')")
        return true;

    return false;
}

// Vue.prototype.$rollbar = new Rollbar({
//     accessToken: "0dae4c2655284fdca4d13e467b05a974",
//     captureUncaught: true,
//     captureUnhandledRejections: true,
//     // checkIgnore: ignoreThisError,
//     checkIgnore: (isUncaught, args, payload) => ignoreThisError(isUncaught, args, payload),
//     enabled: true,
//     environment: process.env.VUE_APP_GIRAFFEPAD_ENV,
//     payload: {
//         client: {
//             javascript: {
//                 code_version: process.env.VUE_APP_GIRAFFEPAD_BUILD,
//                 source_map_enabled: true,
//                 guess_uncaught_frames: true,
//             },
//         },
//     },
// });

function isTokenExpiredError(error: any): boolean {
    // console.log("isTokenExpiredError() checking: ", error);
    // Your own logic to determine if the error is due to JWT token expired returns a boolean value
    let invalidToken = false;
    let authenticateResponseHeader = "";
    if (error.response && error.response.headers) {
        // console.log("Response headers: ", error.response.headers);
        authenticateResponseHeader = error.response.headers["www-authenticate"];
        invalidToken =
            authenticateResponseHeader != null &&
            authenticateResponseHeader.length > 0 &&
            authenticateResponseHeader.includes("invalid_token");
    }
    // console.log("Invalid token?: ", invalidToken);
    return invalidToken;
}

let isAlreadyFetchingAccessToken = false;

// This is the list of waiting requests that will retry after the JWT refresh complete
let subscribers: any[] = [];

function onAccessTokenFetched(accessToken: string) {
    // When the refresh is successful, we start retrying the requests one by one and empty the queue
    subscribers = subscribers.filter((callback) => callback(accessToken));
    subscribers = [];
}

function addSubscriber(callback: any) {
    subscribers.push(callback);
}

function createRetryPromise(config: any): Promise<unknown> {
    console.log("Creating promise for: ", config.method, config.url);
    return new Promise((resolve) => {
        /* We need to add the request retry to the queue
        since there another request that already attempt to
        refresh the token */
        // console.log("Adding subscriber to retry original request: ", errorResponse.config);
        addSubscriber((accessToken: any) => {
            config.headers = {
                "Content-Type": "application/json",
                authorization: `bearer ${accessToken}`,
            };
            console.log("Retrying call with new access token: ", config.method, config.url);
            resolve(axios(config));
        });
    });
}

async function resetTokenAndReattemptRequest(error: any) {
    // console.log("resetTokenAndReattemptRequest() called with error: ", error);
    try {
        const { response: errorResponse } = error;

        if (!store.state.user.refreshToken) {
            // We can't refresh, throw the error anyway
            return Promise.reject(error);
        }

        /* Proceed to the token refresh procedure
        We create a new Promise that will retry the request,
        clone all the request configuration from the failed
        request in the error object. */
        const retryOriginalRequest = createRetryPromise(errorResponse.config);

        if (!isAlreadyFetchingAccessToken) {
            isAlreadyFetchingAccessToken = true;

            const tokenRefreshRequest = {
                token: store.state.user.token,
                refreshToken: store.state.user.refreshToken,
            };

            console.log("Refreshing access token...");
            axios
                .post(
                    `${process.env.VUE_APP_API_BASE_URL}/v1/identity/refresh`,
                    tokenRefreshRequest,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                )
                .then((response: any) => {
                    console.log("Received new access token...");
                    const newUser = response.data;
                    store.dispatch("updateUser", newUser).then(() => {
                        isAlreadyFetchingAccessToken = false;
                        onAccessTokenFetched(newUser.token);
                    });
                })
                .catch((err: any) => {
                    console.log("Failed to refresh access token", err);
                    store.dispatch("signOut");
                    isAlreadyFetchingAccessToken = false;
                    subscribers = [];
                    router.push({ name: "SignIn" });
                    // return Promise.reject(err);
                    return Promise.resolve(); // No need to throw an error
                });
        }

        return retryOriginalRequest;
    } catch (err) {
        return Promise.reject(err);
    }
}

// Default Axios config options
const defaultOptions = {
    baseURL: process.env.VUE_APP_API_BASE_URL,
    headers: {
        "Content-Type": "application/json",
        authorization: `bearer ${store.state.user.token}`,
    },
};

// Create instance
Vue.prototype.$http = axios.create(defaultOptions);
// Vue.prototype.$http.defaults.headers['Content-Type'] = 'application/json'

// Add a request interceptor to handle calls made while token being refreshed
console.log("Adding http request interceptor...");
Vue.prototype.$http.interceptors.request.use((config: any) => {
    // Do something before request is sent
    if (isAlreadyFetchingAccessToken) {
        console.log(
            "Intercepted request while already fetching an access token so returning promise",
            config
        );
        return createRetryPromise(config);
    }
    return config;
});

// Set up an interceptor to handle refreshing of expired access tokens
console.log("Adding http response interceptor...");
Vue.prototype.$http.interceptors.response.use(
    // If the request succeeds, we don't have to do anything and just return the response
    (response: any) => response,
    (error: any) => {
        if (isTokenExpiredError(error)) {
            // if (isTokenAlreadyRefreshed(error)) {
            //     return reAttemptRequestWithCurrentToken(error);
            // }
            return resetTokenAndReattemptRequest(error);
        }

        // If the error is due to other reasons, we just throw it back to axios
        return Promise.reject(error);
    }
);

Vue.config.errorHandler = (err, vm, info) => {
    if (!ignoreThisError(err)) {
        LogUtils.Error(info, err, "main");
    }
};

Vue.use(DashboardPlugin);
Vue.use(SpinnerPlugin);

// This plugin relies on the matchMedia API to detect screen size changes. So, for older browsers and IE, you should polyfill this out: Paul Irish's matchMedia polyfill
Vue.use(VueMq, {
    breakpoints: {
        // default breakpoints - customize this
        mobile: 768,
        tablet: 992,
        desktop: Infinity,
    },
});

// Registering Syncfusion license key
registerLicense(
    "ORg4AjUWIQA/Gnt2VVhjQlFac19JXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRd0VhWH1fcnFQR2BcVkc="
);

// Vue.use(VueYoutube);

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

Vue.filter("capitalise", (value: string) => {
    if (!value) return "";
    const newValue = value.toString();
    return newValue.charAt(0).toUpperCase() + newValue.slice(1);
});

const options: LoaderOptions = { libraries: ["places"] };
const loader = new Loader(process.env.VUE_APP_GOOGLE_MAPS_API_KEY, options);
// eslint-disable-next-line @typescript-eslint/no-empty-function
loader.load();

// Get the current user from local storage and put in the store
store.dispatch("loadUserFromLocalStorage").finally(() => {
    new Vue({
        router,
        store,
        render: (h) => h(TheApp),
    }).$mount("#app");
});
