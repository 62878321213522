<template>
    <div v-if="visible" class="control-section gp-resources">
        <ejs-dialog
            target="#the-app"
            class="e-fixed"
            ref="resourceSelectionDialog"
            id="resource-selection-dialog"
            :visible="false"
            :header="dialogHeader"
            :animationSettings="animationSettings"
            :showCloseIcon="false"
            :open="dialogOpen"
            :close="dialogClose"
            :width="dialogWidth"
            :position="dialogposition"
            :buttons="dialogButtons"
        >
            <ejs-filemanager
                ref="filemanagerObj"
                id="filemanager"
                height="400px"
                :beforeSend="beforeSend"
                :beforeImageLoad="beforeImageLoad"
                :beforePopupOpen="beforePopupOpen"
                :ajaxSettings="ajaxSettings"
                :allowMultiSelection="allowMultiSelection"
                :allowDragAndDrop="false"
                :detailsViewSettings="detailsViewSettings"
                :navigationPaneSettings="navigationPaneSettings"
                :fileOpen="onFileOpen"
                :fileSelect="onFileSelect"
                :toolbarSettings="toolbarSettings"
                view="Details"
            ></ejs-filemanager>
        </ejs-dialog>
    </div>
</template>
<script>
import Vue from "vue";
import { DialogPlugin } from "@syncfusion/ej2-vue-popups";
import { NavigationPane, Toolbar, DetailsView } from "@syncfusion/ej2-vue-filemanager";
import store from "@/store/index";
import Utilities from "@/utils/Utilities";

Vue.use(DialogPlugin);

export default {
    name: "FolderResourcesSelectionDialog",
    props: {
        folderSelect: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            dialogposition: { X: "center", Y: "top" },
            animationSettings: { effect: "None" },
            dialogWidth: "850px",
            dialogButtons: [
                {
                    click: this.dlgCancelClicked,
                    buttonModel: {
                        content: "Cancel",
                        cssClass: "btn-outline-danger",
                    },
                },
                {
                    click: this.dlgOkClicked,
                    buttonModel: {
                        content: "OK",
                        isPrimary: true,
                        cssClass: "btn-primary",
                    },
                },
            ],
            detailsViewSettings: {
                columns: [
                    {
                        field: "name",
                        headerText: "Resource Name",
                        minWidth: 120,
                        width: "auto",
                        customAttributes: { class: "e-fe-grid-name" },
                        template: "${name}", // eslint-disable-line no-template-curly-in-string
                    },
                ],
            },
            navigationPaneSettings: { maxWidth: "850px", minWidth: "140px", visible: true },
            toolbarSettings: { items: ["View"], visible: true },
            tokenRefreshIntervalId: null,
            previewProps: {
                title: "",
                url: "",
                format: "",
                loomId: "",
                youTubeId: "",
                vimeoId: "",
            },
            currentFolderId: null,
            visible: false,
        };
    },
    provide: {
        filemanager: [DetailsView, NavigationPane, Toolbar],
    },
    mounted() {
        console.log("FolderResourcesSelectionDialog mounted()");
        this.tokenRefreshIntervalId = setInterval(() => {
            // Make sure that the user's bearer token hasn't expired
            Vue.prototype.$http.get("/v1/resources/operations/ensurevalidbearertoken");
        }, 100000);
        console.log(
            "FolderResourcesSelectionDialog setInterval() returned ",
            this.tokenRefreshIntervalId
        );

        console.log("$mq", this.$mq);
        this.navigationPaneSettings.visible = this.$mq !== "mobile";

        console.log("folderSelect", this.folderSelect);
    },
    destroyed() {
        clearInterval(this.tokenRefreshIntervalId);
        console.log(
            "FolderResourcesSelectionDialog clearInterval() called with ",
            this.tokenRefreshIntervalId
        );
    },
    computed: {
        dialogHeader() {
            return this.folderSelect ? "Select destination folder" : "Select Resource(s) to Add";
        },
        ajaxSettings() {
            const url = `${process.env.VUE_APP_API_BASE_URL}/v1/resources/operations/action${
                this.folderSelect ? "?foldersOnly=true" : ""
            }`;
            console.log("ajaxSettings", url, this.folderSelect);
            return {
                url,
            };
        },
        allowMultiSelection() {
            return !this.folderSelect;
        },
    },
    methods: {
        showDialog() {
            // this.$refs.filemanagerObj.ej2Instances.path = "/";
            // this.$refs.filemanagerObj.ej2Instances.refreshFiles();
            console.log("showDialog()", this.$refs);
            this.visible = true;
            this.$nextTick(() => {
                console.log("nextTick()", this.$refs);
                this.$refs.resourceSelectionDialog.show();
            });
        },
        dlgOkClicked() {
            console.log("dlgOkClicked", this.$refs.filemanagerObj.ej2Instances.getSelectedFiles());
            if (this.folderSelect) {
                if (this.currentFolderId !== undefined) {
                    this.$emit("folder-selected", this.currentFolderId);
                }
            } else {
                const selectedResources = this.$refs.filemanagerObj.ej2Instances
                    .getSelectedFiles()
                    .filter((f) => f.isFile)
                    .map((f) => parseInt(f.id, 10));
                this.$emit("resources-selected", selectedResources);
            }
            this.$refs.resourceSelectionDialog.hide();
            this.visible = false;
        },
        dlgCancelClicked() {
            console.log("dlgCancelClicked");
            this.$refs.resourceSelectionDialog.hide();
            this.visible = false;
        },
        beforeImageLoad(args) {
            console.log("beforeImageLoad", args);
            args.imageUrl = args.fileDetails[0].url;
        },
        beforePopupOpen(args) {
            console.log("beforePopupOpen", args);
            args.cancel = true;
        },
        beforeSend(args) {
            // Set authentication header on the request
            args.ajaxSettings.beforeSend = (xhr) => {
                xhr.httpRequest.setRequestHeader(
                    "authorization",
                    Utilities.userBearerTokenHeader(store.state.user)
                );
            };
        },
        onFileOpen(args) {
            console.log(`${args.fileDetails.name} is opened`, args);
            if (!args.fileDetails.isFile) {
                this.currentFolderId = args.fileDetails.id
                    ? parseInt(args.fileDetails.id, 10)
                    : null;
                console.log("currentFolderId", this.currentFolderId);
            }
        },
        onFileSelect(args) {
            console.log(`${args.fileDetails.name} action is`, args.action, args);
            if (!args.fileDetails.isFile) {
                if (args.action === "select") {
                    this.currentFolderId = args.fileDetails.id
                        ? parseInt(args.fileDetails.id, 10)
                        : null;
                } else {
                    this.currentFolderId = undefined;
                }
                console.log("currentFolderId", this.currentFolderId);
            }
        },
        resetPreviewProps() {
            this.previewProps.title = "";
            this.previewProps.url = "";
            this.previewProps.loomId = "";
            this.previewProps.youTubeId = "";
            this.previewProps.vimeoId = "";
        },
        // Uploader will be hidden, if Dialog is opened
        dialogOpen() {
            const fileManagerObj = this.$refs.filemanagerObj.ej2Instances;
            fileManagerObj.refreshLayout();
        },
        // Uploader will be shown, if Dialog is closed
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        dialogClose() {},
    },
};
</script>

<style lang="scss">
#resource-selection-dialog .e-dlg-content {
    padding: 0;
}
#resource-selection-dialog .e-empty {
    display: none;
}
#resource-selection-dialog .e-search-wrap {
    display: none;
}
.e-footer-content .e-btn.e-flat.e-primary {
    background-color: #2dce89;
    border-color: #2dce89;
    color: #fff;
}
.e-dialog .e-dlg-header-content {
    background-color: #91cfe0;
}
</style>

<style lang="scss" scoped>
#resource-selection-dialog.e-fixed {
    position: fixed;
    margin-top: 100px;
}
</style>
