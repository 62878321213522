
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class ImageResourcePreviewDialog extends Vue {
    @Prop({ required: true, default: "" }) fileName!: string;

    @Prop({ required: true, default: "" }) url!: string;

    onHidden() {
        this.$emit("close");
    }
}
