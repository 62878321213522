
import { Component, Vue } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import axios from "axios";
import LogUtils from "@/utils/LogUtils";

@Component({
    components: {},
})
export default class ResetPasswordView extends Vue {
    private validationObserver!: InstanceType<typeof ValidationObserver>;

    formData: any = {
        emailVerificationCode: "",
        password: "",
    };

    submitting = false;

    error = false;

    userId = "";

    confirmPassword = "";

    created() {
        this.userId = this.$route.query.userId as string;
        this.formData.emailVerificationCode = this.$route.query.code as string;
    }

    async trySubmit() {
        this.submitting = true;
        axios
            .post(
                `${process.env.VUE_APP_API_BASE_URL}/v1/users/${this.userId}/resetpassword`,
                this.formData
            )
            .then(() => {
                this.submitting = false;
                this.$router.push({ name: "SignIn" });
            })
            .catch((err: any) => {
                LogUtils.Error("Failure calling resetpassword API", err, "ResetPasswordView");
                this.submitting = false;
                this.error = true;
                this.formData.password = "";
                this.confirmPassword = "";
            });
    }
}
