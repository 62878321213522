import Vue from "vue";

export default class IpStackService {
    static readonly ipStackAccessKey: string = "cc59ca4e657fa177e3d8ce07612f05a8";

    public static async getUserTimeZone(): Promise<string> {
        const response = await Vue.prototype.$http.get(
            `https://api.ipstack.com/check?access_key=${IpStackService.ipStackAccessKey}&fields=time_zone.id`
        );
        return response.data.time_zone.id;
    }
}
