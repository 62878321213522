
import { defineComponent } from "vue";
import moment from "moment";

export default defineComponent({
    name: "EventbriteEventsDialog",
    data() {
        return {
            selectedEventId: null,
        };
    },
    props: {
        eventbriteEvents: {
            required: true,
        },
    },
    computed: {
        eventItemsNotConnected() {
            return this.eventbriteEvents
                .filter((e) => e.connectedProgramme === null)
                .map((e) => ({
                    value: e.eventId,
                    text: this.getEventDescription(e),
                }));
        },
        eventItemsConnected() {
            return this.eventbriteEvents
                .filter((e) => e.connectedProgramme !== null)
                .map((e) => ({
                    value: e.eventId,
                    text: this.getEventDescription(e),
                }));
        },
    },
    methods: {
        onCancel() {
            this.selectedEventId = null;
            this.$bvModal.hide("eventbrite-events-dialog");
        },
        onOk() {
            const ev = this.eventbriteEvents.find((e) => e.eventId === this.selectedEventId);
            this.$emit("event-selected", ev.organizationId, ev.eventId);
            this.selectedEventId = null;
        },
        getEventDescription(event) {
            if (event.connectedProgramme === null) {
                const createdDateText = moment(event.created).format("DD/MM/YYYY");
                return `${event.eventName} (created ${createdDateText})`;
            }

            return `${event.eventName} (connected to programme ${event.connectedProgramme})`;
        },
    },
});
