
import { defineComponent } from "vue";
import Vue from "vue";
import Draggable from "vuedraggable";
import ResourceComponent from "@/components/Resource.vue";
import Conversation from "@/components/ConversationView.vue";
import ResourcesAddItemBar from "@/components/ResourcesAddItemBar.vue";
import LearnerSelectionDialog from "@/components/LearnerSelectionDialog.vue";
import TrainerSelectionDialog from "@/components/TrainerSelectionDialog.vue";
import UploadLinkDialog from "@/components/UploadLinkDialog.vue";
import UploadFileDialog from "@/components/UploadFileDialog.vue";
import EventDetailsPanel from "@/components/events/EventDetailsPanel.vue";
import CloneEventDialog from "@/components/events/CloneEventDialog.vue";
import PersonList from "@/components/PersonList.vue";
import store from "@/store/index";
import Utilities from "@/utils/Utilities";
import LogUtils from "@/utils/LogUtils";
import ChannelUnreadMessagesBadge from "@/components/ChannelUnreadMessagesBadge.vue";
import FolderResourcesSelectionDialog from "@/components/FolderResourcesSelectionDialog.vue";
import { Resource } from "@/contracts/v1/responses/Resource";
import { Objective } from "@/contracts/v1/responses/Objective";
import { UserTimelineItemPermissions } from "@/contracts/v1/responses/UserTimelineItemPermissions";
import { Trainer } from "@/contracts/v1/responses/Trainer";
import { Event } from "@/contracts/v1/responses/Event";

export default defineComponent({
    name: "EventView",
    components: {
        Conversation,
        draggable: Draggable,
        "resource-component": ResourceComponent,
        "upload-link-dialog": UploadLinkDialog,
        "upload-file-dialog": UploadFileDialog,
        "learner-selection-dialog": LearnerSelectionDialog,
        "trainer-selection-dialog": TrainerSelectionDialog,
        "person-list": PersonList,
        "channel-unread-messages-badge": ChannelUnreadMessagesBadge,
        "folder-resources-selection-dialog": FolderResourcesSelectionDialog,
        "event-details-panel": EventDetailsPanel,
        "clone-event-dialog": CloneEventDialog,
        ResourcesAddItemBar,
    },
    data() {
        return {
            currentEventId: null,
            event: {} as Event,
            archived: false,
            objectives: [] as Objective[],
            eventResources: [] as Resource[],
            error: null,
            loading: true,
            chatClient: null,
            addingObjective: false,
            newObjective: "",
            showInviteLearnerUI: false,
            invitingLearner: false,
            newLearnerText: "",
            chatChannelId: null,
            timelineItemPermissions: {} as UserTimelineItemPermissions,
            tenantTrainers: [] as Trainer[],
            userIsTrainer: Utilities.userIsTrainer(store.state.user),
            activeTenantSubscription: Utilities.userHasActiveTenantSubscription(store.state.user),
            programmeLearners: [],
            programmeTrainers: [] as Trainer[],
            programmes: [],
            zoomMeetingStartUrl: null,
            userStartDateTimeZone: null,
        };
    },
    props: {
        eventId: { type: Number, required: true },
        fromTab: { type: Number, required: false },
    },
    // watch: {
    //     "$route.path": function () {
    //         console.log("routeChanged", id);
    //         this.onCreated();
    //     },
    // },
    created() {
        this.$watch(
            "$route.params.eventId",
            (eventId) => {
                console.log("routeChanged to cloned event ", eventId);
                this.loadEvent(eventId);
            },
            { immediate: true }
        );

        // this.loadEvent(this.eventId);
    },
    mounted() {
        this.$nextTick(() => {
            // Enable bootstrap tooltips
            // $("[data-toggle=\"tooltip\"]").tooltip();
        });
    },
    computed: {
        objectiveDragOptions() {
            return {
                // animation: 200,
                // group: "objectives",
                // disabled: false,
                // dragClass: "dragging",
                handle: ".drag-handle",
            };
        },
        resourceDragOptions() {
            return {
                // animation: 200,
                // group: "resources",
                // disabled: false,
                // dragClass: "dragging",
                handle: ".drag-handle",
            };
        },
        learnerResources() {
            return this.event.resources.filter(
                (resource) => resource.learner != null && !resource.isPrivate
            );
        },
        notInvitedProgrammeLearners() {
            const vm = this;
            const invitedLearnerIds = this.event.learners.map(
                (invitedLearner) => invitedLearner.id
            );
            console.log("Invited learners:", invitedLearnerIds);
            return this.programmeLearners.filter(
                (programmeLearner) => !vm.myIncludes(invitedLearnerIds, programmeLearner.id)
            );
        },
        notAddedTrainers() {
            const vm = this;
            const addedTrainerIds = vm.event.trainers.map((eventTrainer) => eventTrainer.id);
            if (vm.event.programme) {
                return this.programmeTrainers.filter(
                    (programmeTrainer) =>
                        !vm.myIncludes(addedTrainerIds, programmeTrainer.id) &&
                        programmeTrainer.id !== vm.event.trainer.id
                );
            }
            return this.tenantTrainers.filter(
                (tenantTrainer) =>
                    !vm.myIncludes(addedTrainerIds, tenantTrainer.id) &&
                    tenantTrainer.id !== vm.event.trainer.id
            );
        },
        trainersAvailableToAdd() {
            const vm = this;
            if (vm.event.programme) {
                return vm.event.trainers.length < vm.programmeTrainers.length - 1;
            }
            return vm.event.trainers.length < vm.tenantTrainers.length - 1;
        },
        past() {
            return Utilities.isInThePast(this.event.startDateAndTime);
        },
        registeredLearners() {
            const vm = this;
            return Utilities.sortbyFirstNameLastName(
                this.event.learners
                    .filter((person) => person.hasCompletedRegistration)
                    .map((person) => {
                        const status = Utilities.getStatusNew(
                            vm.event.eventType,
                            person.completed,
                            vm.event.recordAttendance,
                            this.past,
                            person.attendanceIntended
                        );
                        return {
                            ...person,
                            statusVariant: status.variant,
                            statusIcon: status.icon,
                            statusTooltip: status.tooltip,
                        };
                    })
            );
        },
        unregisteredLearners() {
            return Utilities.sortbyEmail(
                this.event.learners.filter((person) => !person.hasCompletedRegistration)
            );
        },
        facilitatedProgrammes() {
            return this.programmes.filter((p) => p.facilitating === true);
        },
        programmeId() {
            return this.event.programme ? this.event.programme.id : null;
        },
    },
    methods: {
        loadEvent(eventId) {
            const vm = this;
            vm.currentEventId = eventId;
            vm.loading = true;
            console.log("Getting event...");
            Vue.prototype.$http
                .get(`/v1/events/${vm.currentEventId}?timeZone=${this.$store.state.user.timeZone}`)
                .then(async (result) => {
                    console.log("Event", result);
                    vm.timelineItemPermissions = JSON.parse(
                        result.headers["x-gp-permissions"]
                    ).timelineItem;
                    console.log("timelineItemPermissions", vm.timelineItemPermissions);
                    vm.event = result.data;
                    vm.chatChannelId = vm.event.chatChannelId;
                    vm.error = null;
                    vm.archived = vm.event.isArchived;

                    // console.log('eventObjectives', vm.event.eventObjectives);
                    vm.objectives = vm.event.eventObjectives
                        .map((obj) => ({
                            id: obj.id,
                            objective: obj.objective,
                            editing: false,
                            newObjective: "",
                            order: obj.order,
                        }))
                        .sort((a, b) => (a.order < b.order ? -1 : 1));
                    console.log("Objectives", vm.objectives);

                    vm.eventResources = vm.event.resources
                        .filter((resource) => resource.learner == null)
                        .sort((a, b) => (a.order < b.order ? -1 : 1));
                    console.log("eventResources", vm.eventResources);

                    if (vm.event.programme) {
                        console.log("Getting programme...");
                        Vue.prototype.$http
                            .get(`/v1/programmes/${vm.event.programme.id}`)
                            .then((res) => {
                                console.log("Programme", res);
                                vm.programmeLearners = res.data.learners;
                                vm.programmeTrainers = res.data.trainers;
                                vm.programmeTrainers.push(res.data.trainer);
                                console.log("Programme trainers", vm.programmeTrainers);
                                console.log("Programme learners", vm.programmeLearners);
                            })
                            .catch((err) => {
                                LogUtils.Error("Could not load programme", err, "EventLView");
                            });
                    }

                    // console.log("Getting start date and time in local time zone");
                    // await TimeZoneUtils.ConvertDateTimeToTimeZone(this.event.timeZone, this.event.startDateAndTime, this.$store.state.user.timeZone)
                    //     .then((res) => {
                    //         vm.userStartDateTimeZone = res;
                    //         console.log("start date and timne in local time zone", vm.userStartDateTimeZone);
                    //     })
                    //     .catch((err) => {
                    //         LogUtils.Error("Could not load start date and timne in local time zone", err);
                    //     });

                    console.log("Getting trainers...");
                    Vue.prototype.$http
                        .get("/v1/trainers")
                        .then((res) => {
                            vm.tenantTrainers = res.data;
                            console.log("Tenant trainers", vm.tenantTrainers);
                        })
                        .catch((err) => {
                            LogUtils.Error("Could not load trainers", err, "EventLView");
                        });

                    if (
                        vm.event.eventType === 2 &&
                        Utilities.userIsEventLeadTrainer(store.state.user, vm.event)
                    ) {
                        // Don't display join url while getting start url
                        vm.event.zoomMeetingJoinUrl = null;

                        console.log("Getting zoommeeting...");
                        Vue.prototype.$http
                            .get(`/v1/events/${vm.currentEventId}/zoommeeting`)
                            .then((res) => {
                                vm.zoomMeetingStartUrl = res.data.zoomMeetingStartUrl;
                                console.log("Zoom meeting start url", vm.zoomMeetingStartUrl);
                            })
                            .catch((err) => {
                                LogUtils.Error(
                                    "Could not get Zoom meeting start url",
                                    err,
                                    "EventLView"
                                );
                            });
                    }

                    console.log("Getting programmes...");
                    await Vue.prototype.$http
                        .get("/v1/programmes")
                        .then((res) => {
                            vm.programmes = res.data;
                            console.log("Programmes", vm.programmes);
                        })
                        .catch((err) => {
                            LogUtils.Error("Could not load programmes", err, "EventLView");
                        });

                    vm.loading = false;
                })
                .catch((err) => {
                    LogUtils.Error("Could not load event details", err, "EventLView");
                    vm.error = "Could not load event details...";
                });
        },
        showResourcesDialog() {
            console.log("refs", this.$refs);
            this.$refs.resources_selection_dialog.showDialog();
        },
        myIncludes(container, value) {
            let returnValue = false;
            const pos = container.indexOf(value);
            if (pos >= 0) {
                returnValue = true;
            }
            return returnValue;
        },
        allProgrammeLearnersInvitedAlert() {
            Utilities.gpErrorAlert(
                this.$bvModal,
                "All learners taking part in this event's programme are already invited to this event.",
                "All learners already invited"
            );
        },
        addObjective() {
            this.addingObjective = true;
            this.$nextTick(() => {
                // console.log('add-objective', this.$refs['add-objective'])
                this.$refs["add-objective"].focus();
            });
        },
        objectiveClicked(objective) {
            if (this.timelineItemPermissions.canEdit) {
                objective.newObjective = objective.objective;
                objective.editing = true;
                this.$nextTick(() => {
                    this.$refs[`objective-${objective.id}`][0].focus();
                });
            }
        },
        objectiveEdited(objective) {
            // console.log(objective);
            if (
                this.timelineItemPermissions.canEdit &&
                objective.objective !== objective.newObjective
            ) {
                // console.log(objective);
                Vue.prototype.$http
                    .put(`/v1/events/${this.event.id}/objectives/${objective.id}`, {
                        id: objective.id,
                        eventId: this.currentEventId,
                        objective: objective.newObjective,
                    })
                    .then(() => {
                        objective.objective = objective.newObjective;
                        objective.editing = false;
                    })
                    .catch((err) => {
                        LogUtils.Error("Unable to edit outcome", err, "EventLView");
                        Utilities.gpErrorAlert(
                            this.$bvModal,
                            "An error occurred while updating the outcome."
                        );
                        objective.editing = false;
                    });
            } else {
                objective.editing = false;
            }
        },
        saveNewObjective() {
            const vm = this;
            Vue.prototype.$http
                .post(`/v1/events/${this.event.id}/objectives`, { objective: vm.newObjective })
                .then((res) => {
                    // console.log('saveNewObjective returned: ', res.data);
                    vm.addingObjective = false;
                    vm.newObjective = "";
                    vm.objectives.push({
                        id: res.data.id,
                        objective: res.data.objective,
                        editing: false,
                        newObjective: "",
                    });
                })
                .catch((err) => {
                    LogUtils.Error("Unable to add outcome", err, "EventLView");
                    Utilities.gpErrorAlert(
                        vm.$bvModal,
                        "An error occurred while adding the objective."
                    );
                });
        },
        saveNewLearner() {
            const vm = this;
            vm.invitingLearner = true;
            Vue.prototype.$http
                .post(`/v1/events/${this.event.id}/learners`, { learnerEmails: vm.newLearnerText })
                .then((res) => {
                    console.log("saveNewLearner returned: ", res.data);
                    vm.showInviteLearnerUI = false;
                    vm.newLearnerText = "";
                    res.data.forEach((learner) => {
                        vm.event.learners.push(learner);
                    });
                    vm.invitingLearner = false;
                })
                .catch((err) => {
                    LogUtils.Error("Unable to add learner", err, "EventLView");
                    Utilities.gpErrorAlert(
                        this.$bvModal,
                        "An error occurred while adding the learner."
                    );
                    vm.invitingLearner = false;
                });
        },
        resendInvitation(person) {
            Vue.prototype.$http
                .post(`/v1/events/${this.event.id}/learners`, { learnerEmails: person.email })
                .then((res) => {
                    Utilities.gpSuccessAlert(this.$bvModal, "Learner invitation resent.");
                    console.log("resendInvitation returned: ", res.data);
                })
                .catch((err) => {
                    LogUtils.Error("Unable to re-invite learner", err, "EventLView");
                    Utilities.gpErrorAlert(
                        this.$bvModal,
                        "An error occurred while re-inviting the learner."
                    );
                });
        },
        deleteObjective(objective) {
            const vm = this;
            Vue.prototype.$http
                .delete(`/v1/events/${this.event.id}/objectives/${objective.id}`)
                .then(() => {
                    vm.objectives.splice(vm.objectives.indexOf(objective), 1);
                })
                .catch((err) => {
                    LogUtils.Error("Unable to delete outcome", err, "EventLView");
                    Utilities.gpErrorAlert(
                        this.$bvModal,
                        "An error occurred while deleting the outcome."
                    );
                });
        },
        cloneEvent(destinationProgrammeId) {
            const vm = this;
            const cloneButton = document.querySelector("#clone-event") as HTMLButtonElement;
            cloneButton.disabled = true;
            Vue.prototype.$http
                .post(`/v1/events/${vm.event.id}/clone`, {
                    destinationProgrammeId,
                })
                .then((response) => {
                    this.$bvModal.hide("clone-event-dialog");
                    const newEvent = response.data;
                    console.log(newEvent);
                    cloneButton.disabled = false;
                    Utilities.gpSuccessAlert(
                        vm.$bvModal,
                        "The event has been cloned.",
                        "Event cloned",
                        "Go to my cloned event"
                    ).then(() => {
                        this.chatChannelId = null; // Allow cleanup of chat channel
                        this.$nextTick(() => {
                            vm.$router.push({ name: "Event", params: { eventId: newEvent.id } });
                        });
                    });
                })
                .catch((err) => {
                    this.$bvModal.hide("clone-event-dialog");
                    if (err.response?.status === 401) {
                        Utilities.gpErrorAlert(
                            vm.$bvModal,
                            "You do not have permission to do this.",
                            "Clone event failed"
                        );
                        LogUtils.Info("Permission denied when cloning event", err);
                    } else {
                        Utilities.gpErrorAlert(
                            vm.$bvModal,
                            "An error occurred while cloning this event.",
                            "Clone event failed"
                        );
                        LogUtils.Error("Unable to clone event", err, "EventLView");
                    }
                    cloneButton.disabled = false;
                });
        },
        deleteEvent() {
            const vm = this;
            const deleteButton = document.querySelector("#delete-event") as HTMLButtonElement;

            Utilities.gpModalConfirm(
                this.$bvModal,
                "Delete Event",
                "Please confirm that you wish to delete this event. This action cannot be reversed - all data relating to this event will be permanently deleted!",
                "Delete",
                "Cancel",
                true
            ).then((confirmed) => {
                if (confirmed) {
                    deleteButton.disabled = true;
                    Vue.prototype.$http
                        .delete(`/v1/events/${vm.event.id}`)
                        .then(() => {
                            // console.log(w);
                            Utilities.gpSuccessAlert(this.$bvModal, "Event deleted!").then(() => {
                                if (vm.event.programme) {
                                    this.$router.push({
                                        name: "Programme",
                                        params: { programmeId: vm.event.programme.id },
                                    });
                                } else {
                                    this.$router.push({ name: "Events" });
                                }
                            });
                        })
                        .catch((err) => {
                            LogUtils.Error("Unable to delete event", err, "EventLView");
                            deleteButton.disabled = false;
                            if (err !== undefined) {
                                Utilities.gpErrorAlert(
                                    this.$bvModal,
                                    "An error occurred while deleting the event.",
                                    "Delete failed"
                                );
                            }
                        });
                }
            });
        },
        archiveEvent() {
            const vm = this;
            const archiveButton = document.querySelector("#archive-event") as HTMLButtonElement;

            Utilities.gpModalConfirm(
                this.$bvModal,
                "Archive Event",
                "Please confirm that you wish to archive this event. This action is reversible and no data will be deleted.",
                "Archive",
                "Cancel"
            ).then((confirmed) => {
                if (confirmed) {
                    archiveButton.disabled = true;
                    Vue.prototype.$http
                        .post(`/v1/events/${vm.event.id}/archive`, { setArchived: true })
                        .then(() => {
                            // console.log(w);
                            vm.archived = true;
                            archiveButton.disabled = false;
                        })
                        .catch((err) => {
                            LogUtils.Error("Unable to archive event", err, "EventLView");
                            archiveButton.disabled = false;
                            if (err !== undefined) {
                                Utilities.gpErrorAlert(
                                    this.$bvModal,
                                    "An error occurred while archiving the event.",
                                    "Archive failed"
                                );
                            }
                        });
                }
            });
        },
        restoreEvent() {
            const vm = this;
            const restoreButton = document.querySelector("#restore-event") as HTMLButtonElement;

            Utilities.gpModalConfirm(
                this.$bvModal,
                "Restore Event",
                "Please confirm that you wish to restore this archived event.",
                "Restore",
                "Cancel"
            ).then((confirmed) => {
                if (confirmed) {
                    restoreButton.disabled = true;
                    Vue.prototype.$http
                        .post(`/v1/events/${vm.event.id}/archive`, { setArchived: false })
                        .then(() => {
                            // console.log(w);
                            vm.archived = false;
                            restoreButton.disabled = false;
                        })
                        .catch((err) => {
                            LogUtils.Error("Unable to restore archived event", err, "EventLView");
                            restoreButton.disabled = false;
                            if (err !== undefined) {
                                Utilities.gpErrorAlert(
                                    this.$bvModal,
                                    "An error occurred while retrieving archived event.",
                                    "Restore failed"
                                );
                            }
                        });
                }
            });
        },
        fullName(firstName, lastName) {
            return `${firstName} ${lastName}`;
        },
        avatarUrl(userId) {
            return Utilities.avatarUrl(userId);
        },
        formattedDateTimeSpan(dateFormat, date1, date2) {
            return Utilities.formattedDateTimeSpan(dateFormat, date1, date2);
        },
        resourceUploaded(resource) {
            this.eventResources.push(resource);
        },
        learnersSelected(learners) {
            console.log("learnersSelected", learners);
            const vm = this;
            learners.forEach((learner) => {
                Vue.prototype.$http
                    .post(`/v1/events/${vm.event.id}/learners`, { learnerEmails: learner.email })
                    .then((res) => {
                        res.data.forEach((addedLearner) => {
                            vm.event.learners.push(addedLearner);
                        });
                    })
                    .catch((err) => {
                        LogUtils.Error("Unable to add learner", err, "EventLView");
                    });
            });
        },
        deleteEventResource(resource) {
            const vm = this;
            Vue.prototype.$http
                .delete(`/v1/events/${this.event.id}/resources/${resource.id}`)
                .then(() => {
                    vm.eventResources.splice(vm.eventResources.indexOf(resource), 1);
                })
                .catch((err) => {
                    LogUtils.Error("Unable to remove resource", err, "EventLView");
                    Utilities.gpErrorAlert(
                        this.$bvModal,
                        "An error occurred while removing the resource from the event."
                    );
                });
        },
        isPDF(resource) {
            return resource.fileType === "application/pdf";
        },
        isImage(resource) {
            return resource.fileType && resource.fileType.startsWith("image/");
        },
        onAllowResourceDownloads(resource) {
            Vue.prototype.$http
                .post(`/v1/events/${this.event.id}/resources/${resource.id}/allowdownloads`)
                .then(() => {
                    resource.allowDownloads = true;
                })
                .catch((err) => {
                    LogUtils.Error("Unable to allow resource downloads", err, "EventLView");
                    Utilities.gpErrorAlert(
                        this.$bvModal,
                        "An error occurred while allowing resource downloads."
                    );
                });
        },
        onBlockResourceDownloads(resource) {
            Vue.prototype.$http
                .delete(`/v1/events/${this.event.id}/resources/${resource.id}/allowdownloads`)
                .then(() => {
                    resource.allowDownloads = false;
                })
                .catch((err) => {
                    LogUtils.Error("Unable to block resource downloads", err, "EventLView");
                    Utilities.gpErrorAlert(
                        this.$bvModal,
                        "An error occurred while blocking resource downloads."
                    );
                });
        },
        makeLearnerResourcePrivate(resource) {
            const patchDoc = [{ op: "replace", path: "/isPrivate", value: true }];
            Vue.prototype.$http
                .patch(
                    `/v1/events/${this.event.id}/learners/${resource.learner.id}/resources/${resource.id}`,
                    patchDoc
                )
                .then(() => {
                    resource.isPrivate = true;
                })
                .catch((err) => {
                    LogUtils.Error(
                        "Unable to change programme learner resource privacy",
                        err,
                        "EventLView"
                    );
                    Utilities.gpErrorAlert(
                        this.$bvModal,
                        "An error occurred while updating the resource's privacy."
                    );
                });
        },
        inviteLearner() {
            this.showInviteLearnerUI = true;
            this.$nextTick(() => {
                this.$refs["new-learner-text"].focus();
            });
        },
        deleteLearner(learner) {
            const vm = this;
            const learnerName = learner.hasCompletedRegistration
                ? `${learner.firstName} ${learner.lastName}`
                : learner.email;
            Utilities.gpModalConfirm(
                this.$bvModal,
                "Uninvite learner",
                `Please confirm that you wish to uninvite ${learnerName} from this event.`,
                "Uninvite",
                "Cancel",
                true
            ).then((confirmed) => {
                if (confirmed) {
                    Vue.prototype.$http
                        .delete(`/v1/events/${vm.event.id}/learners/${learner.id}`)
                        .then(() => {
                            const index = vm.event.learners.findIndex((l) => l.id === learner.id);
                            if (index !== -1) {
                                vm.event.learners.splice(index, 1);
                            }
                        })
                        .catch((err) => {
                            LogUtils.Error("Unable to uninvite learner", err, "EventLView");
                            Utilities.gpErrorAlert(
                                this.$bvModal,
                                "An error occurred while uninviting the learner."
                            );
                        });
                }
            });
        },
        trainersSelected(trainers) {
            console.log("trainersSelected", trainers);
            const vm = this;
            trainers.forEach((trainer) => {
                Vue.prototype.$http
                    .post(`/v1/events/${vm.event.id}/trainers`, { trainerId: trainer.id })
                    .then(() => {
                        vm.event.trainers.push(trainer);
                    })
                    .catch((err) => {
                        LogUtils.Error("Unable to add trainer", err, "EventLView");
                        if (err !== undefined) {
                            Utilities.gpErrorAlert(
                                this.$bvModal,
                                "An error occurred while adding the trainer(s).",
                                "Add trainer(s) failed"
                            );
                        }
                    });
            });
        },
        removeTrainer(trainer) {
            const vm = this;

            Utilities.gpModalConfirm(
                this.$bvModal,
                "Remove trainer",
                `Please confirm that you wish to remove ${trainer.firstName} ${trainer.lastName} from this event.`,
                "Remove",
                "Cancel",
                true
            ).then((confirmed) => {
                if (confirmed) {
                    console.log(trainer);
                    // deleteButton.disabled = true;
                    Vue.prototype.$http
                        .delete(`/v1/events/${vm.event.id}/trainers/${trainer.id}`)
                        .then(() => {
                            if (this.$store.state.user.userId === trainer.userId) {
                                this.$router.push({ name: "Events" });
                            } else {
                                vm.event.trainers.splice(vm.event.trainers.indexOf(trainer), 1);
                            }
                        })
                        .catch((err) => {
                            LogUtils.Error("Unable to remove trainer", err, "EventLView");
                            if (err !== undefined) {
                                Utilities.gpErrorAlert(
                                    this.$bvModal,
                                    "An error occurred while removing the trainer.",
                                    "Remove failed"
                                );
                            }
                        });
                }
            });
        },
        onResourcesSelected(resourceIds) {
            console.log("resourcesSelected", resourceIds);
            const vm = this;
            resourceIds.forEach((resourceId) => {
                Vue.prototype.$http
                    .post(`/v1/events/${vm.event.id}/resources`, { resourceId })
                    .then((res) => {
                        this.eventResources.push(res.data);
                    })
                    .catch((err) => {
                        LogUtils.Error("Unable to add resources", err, "EventLView");
                        if (err !== undefined) {
                            Utilities.gpErrorAlert(
                                this.$bvModal,
                                "An error occurred while adding the resource(s).",
                                "Add resource(s) failed"
                            );
                        }
                    });
            });
        },
        tenantLogoUrl(tenantId) {
            return Utilities.tenantLogoUrl(tenantId);
        },
        showUploadFileDialog() {
            console.log("1");
        },
        showUploadLinkDialog() {
            console.log("2");
        },
        showResourcesSelectionDialog() {
            console.log("3");
        },
        onObjectiveOrderChanged(evt) {
            console.log(evt);
            if (evt.moved) {
                const orderedIds = this.objectives.map((obj) => obj.id);
                Vue.prototype.$http
                    .put(`/v1/events/${this.event.id}/objectives/reorder`, orderedIds)
                    // .then(() => {
                    //     console.log("Suuccessfully reordered ids", orderedIds);
                    // })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        onEventResourceOrderChanged(evt) {
            console.log(evt);
            if (evt.moved) {
                const orderedIds = this.eventResources.map((obj) => obj.id);
                console.log("Resource ids", orderedIds);
                Vue.prototype.$http
                    .put(`/v1/events/${this.event.id}/resources/reorder`, orderedIds)
                    // .then(() => {
                    //     console.log("Suuccessfully reordered ids", orderedIds);
                    // })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        insertEmailSoftBreaks(email) {
            return Utilities.insertEmailSoftBreaks(email);
        },
    },
});
