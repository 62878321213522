
import { defineComponent } from "vue";
export default defineComponent({
    name: "time-line-item",
    props: {
        inverted: Boolean,
        title: String,
        badgeType: {
            type: String,
            default: "success",
        },
        badgeIcon: {
            type: String,
            default: "",
        },
    },
});
