
import { defineComponent } from "vue";
export default defineComponent({
    name: "time-line",
    props: {
        type: {
            type: String,
            default: "",
        },
    },
});
