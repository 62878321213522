
import { Component, Vue } from "vue-property-decorator";
import { Conversation } from "@twilio/conversations/lib/conversation";
import { Participant } from "@twilio/conversations/lib/participant";
import moment from "moment";
import ConversationView from "@/components/ConversationView.vue";
import TitleBar from "@/components/TitleBar.vue";
import UserAvatar from "@/components/UserAvatar.vue";
import EventBus from "@/eventBus";
import store from "@/store/index";
import Utilities from "@/utils/Utilities";
import SubscriptionRequired from "@/components/SubscriptionRequired.vue";
import ChannelUnreadMessagesBadge from "@/components/ChannelUnreadMessagesBadge.vue";

@Component({
    components: {
        conversation: ConversationView,
        "title-bar": TitleBar,
        "user-avatar": UserAvatar,
        "subscription-required": SubscriptionRequired,
        "channel-unread-messages-badge": ChannelUnreadMessagesBadge,
    },
})
export default class MessagesView extends Vue {
    localChannels: Conversation[] = [];

    privateChannelOtherPartyUserIds: Map<string, string> = new Map<string, string>();

    currentChannel: Conversation | null = null;

    channelListLoading = true;

    activeTenantSubscription: boolean = Utilities.userHasActiveTenantSubscription(store.state.user);

    userIsMemberOfTenant: boolean = Utilities.userTenantId(store.state.user) !== null;

    channelsVisible = true;

    async created() {
        console.log("[MessagesView] Called created()");

        if (globalThis.chatService.isConnected()) {
            await this.refreshLocalChannels();
        } else if (!globalThis.chatService.isConnecting()) {
            globalThis.chatService.initialise();
        }
    }

    async mounted() {
        console.log("[MessagesView] Called mounted()");

        EventBus.$on("CHAT_CHANNELS_LOADED", this.onChatChannelsLoaded);
    }

    beforeDestroy() {
        console.log("[MessagesView] Removing event handlers");
        EventBus.$off("CHAT_CHANNELS_LOADED", this.onChatChannelsLoaded);
    }

    async onChatChannelsLoaded() {
        console.log(
            "<--- CHAT_CHANNELS_LOADED [MessagesView] calling ChatService.getUserUnreadMessageCount()"
        );
        await this.refreshLocalChannels();
    }

    async refreshLocalChannels() {
        console.log("[MessagesView]  Called refreshLocalChannels()");
        this.channelListLoading = true;

        const rawChannels: Conversation[] = await globalThis.chatService.getChannels();

        this.privateChannelOtherPartyUserIds = new Map<string, string>();
        const promises: Promise<Participant | undefined>[] = [];
        rawChannels.forEach((channel) => {
            if (!this.isGroupChannel(channel)) {
                promises.push(this.getOtherParty(channel));
            }
        });
        const otherParties = await Promise.all(promises);
        otherParties.forEach((otherParty) => {
            if (otherParty) {
                this.privateChannelOtherPartyUserIds.set(
                    otherParty.conversation.sid,
                    otherParty.identity
                );
            }
        });

        this.localChannels = rawChannels;
    }

    get orderedChannels(): Conversation[] {
        // console.log("orderedChannels() called");
        const ordered = this.localChannels.sort((a: Conversation, b: Conversation) =>
            this.compareChannelOrder(a, b)
        );
        // console.log("orderedChannels() completed");
        this.channelListLoading = false;
        return ordered;
    }

    get currentChannelSid(): string | null {
        return this.currentChannel === null ? "" : this.currentChannel.sid;
    }

    get headerText(): string {
        return this.currentChannel === null
            ? "Please select a channel"
            : this.channelDisplayName(this.currentChannel);
    }

    compareChannelOrder(a: Conversation, b: Conversation): number {
        if (!a.lastMessage && !b.lastMessage) {
            return a.sid < b.sid ? 1 : -1;
        }

        if (!a.lastMessage) {
            return 1;
        }

        if (!b.lastMessage) {
            return -1;
        }

        return a.lastMessage.dateCreated! < b.lastMessage.dateCreated! ? 1 : -1;
    }

    selectChannel(channel: Conversation) {
        this.currentChannel = channel;
        this.channelsVisible = false;
        console.log("channelsVisible", this.channelsVisible);
    }

    isProgrammeChannel(channel: Conversation): boolean {
        return channel.friendlyName.indexOf("[PROGRAMME]") !== -1;
    }

    isGroupChannel(channel: Conversation): boolean {
        const isGroup =
            channel &&
            (channel.friendlyName.indexOf("(all attendees)") !== -1 ||
                channel.friendlyName.indexOf("[TEAM]") !== -1);
        return isGroup;
    }

    isSinglePrivateChatChannel(channel: Conversation): boolean {
        const isSinglePrivateChat =
            channel &&
            channel.friendlyName.indexOf("(") === -1 &&
            channel.friendlyName.indexOf("[TEAM]") === -1;
        return isSinglePrivateChat;
    }

    getChannelOtherPartyResolved(channel: Conversation): boolean {
        return this.privateChannelOtherPartyUserIds.has(channel.sid);
    }

    getChannelOtherPartyUserId(channel: Conversation): string {
        const userId = this.privateChannelOtherPartyUserIds.get(channel.sid);
        return userId || "";
    }

    getChannelOtherPartyName(channel: Conversation): string {
        const userFullName = `${this.$store.state.user.firstName} ${this.$store.state.user.lastName}`;
        if (channel.friendlyName.indexOf(userFullName) === -1) {
            return channel.friendlyName;
        }
        let displayName = channel.friendlyName.replace(userFullName, "");
        displayName = displayName.replace(" / ", "");
        return displayName;
    }

    async getOtherParty(channel: Conversation): Promise<Participant | undefined> {
        const members = await channel.getParticipants();
        const member = members.find((m) => m.identity !== this.$store.state.user.userId);
        return member;
    }

    channelDisplayName(channel: Conversation): string {
        if (this.isSinglePrivateChatChannel(channel)) {
            if (this.getChannelOtherPartyResolved(channel)) {
                return this.getChannelOtherPartyName(channel);
            }

            return "";
        }

        let displayName = channel.friendlyName.replace(" (all attendees)", "");
        displayName = displayName.replace("[PROGRAMME] ", "");
        return displayName;
    }

    channelLastMessageTime(channel: Conversation): string {
        const relativeDateTimeString = moment(channel.lastMessage.dateCreated!)
            .subtract(20, "s")
            .fromNow(); // this.props.message.source.timestamp;
        return relativeDateTimeString;
    }

    onGoBack() {
        this.currentChannel = null;
        this.channelsVisible = true;
    }
}
