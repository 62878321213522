
import { Component, Prop, Vue } from "vue-property-decorator";
import Draggable from "vuedraggable";
import TimeLine from "@/components/Timeline/TimeLine.vue";
import Utilities from "@/utils/Utilities";
import ISection from "@/components/Timeline/ISection";
import EvtSectionTitleChanged from "@/components/Timeline/EvtSectionTitleChanged";
import EvtSectionMoved from "@/components/Timeline/EvtSectionMoved";
import EvtItemMoved from "@/components/Timeline/EvtItemMoved";
import TimeLineSectionHeader from "@/components/Timeline/TimeLineSectionHeader.vue";
import TimeLineItemEvent from "@/components/Timeline/TimeLineItemEvent.vue";
import TimeLineItemActivity from "@/components/Timeline/TimeLineItemActivity.vue";
import TimeLineItemQuestionnaire from "@/components/Timeline/TimeLineItemQuestionnaire.vue";
import TimeLineItemVideoActivity from "@/components/Timeline/TimeLineItemVideoActivity.vue";
import TimeLineAddItemBar from "@/components/Timeline/TimeLineAddItemBar.vue";
import { ProgrammeSection } from "@/contracts/v1/responses/ProgrammeSection";
import { Activity } from "@/contracts/v1/responses/Activity";

interface ActivityExtended extends Activity {
    completed: boolean | undefined;
}

@Component({
    components: {
        Draggable,
        TimeLine,
        TimeLineSectionHeader,
        TimeLineItemEvent,
        TimeLineItemActivity,
        TimeLineItemQuestionnaire,
        TimeLineItemVideoActivity,
        TimeLineAddItemBar,
    },
})
export default class ProgrammeTimeLine extends Vue {
    @Prop({ type: Number, required: true }) programmeId!: number;

    @Prop() sections!: ProgrammeSection[];

    @Prop({ type: Boolean, required: true }) userIsAdmin!: boolean;

    @Prop({ type: Boolean, required: true }) userIsLearner!: boolean;

    @Prop({ type: Number, required: false }) learnerId!: number;

    @Prop({ type: Boolean, default: false }) showStatus!: boolean;

    @Prop({ type: Boolean, default: false }) showArchived!: boolean;

    @Prop({ type: Boolean, default: false }) canEditTimeline!: boolean;

    @Prop({ type: Boolean, default: false }) canCreateItem!: boolean;

    @Prop({ type: Boolean, default: false }) hasProSubscription!: boolean;

    @Prop({ type: Number, required: false }) fromTab!: number;

    formattedDateTimeSpan(dateFormat, date1, date2) {
        return Utilities.formattedDateTimeSpan(dateFormat, date1, date2);
    }

    sectionDragging = false;

    itemDragging = false;

    get canDrag() {
        return this.canEditTimeline && !this.userIsLearner;
    }

    get sectionDragOptions() {
        return {
            disabled: !this.canDrag,
            handle: ".drag-handle",
        };
    }

    get itemDragOptions() {
        return {
            disabled: !this.canDrag,
            handle: ".drag-handle",
        };
    }

    sectionHeaderVisible(section: ProgrammeSection) {
        return section.id != null && (this.canEditTimeline || this.sectionHasVisibleItems(section));
    }

    sectionHasOverdueItems(section: ProgrammeSection) {
        let hasOverdueItems = false;
        if (this.learnerId || this.userIsLearner) {
            const overdueItems = section.items.filter((item) => {
                if (!this.showArchived && item.isArchived) return false;
                if (item.eventType === 0 || item.eventType === 1 || item.eventType === 2)
                    return false;
                return (
                    !((item as unknown as ActivityExtended).completed === true) &&
                    item.endDateAndTime &&
                    Utilities.isInThePast(item.endDateAndTime)
                );
            });
            hasOverdueItems = overdueItems.length > 0;
        }
        return hasOverdueItems;
    }

    sectionVisible(section: ProgrammeSection) {
        // return true;
        return this.sectionHasVisibleItems(section) || (this.canEditTimeline && section.id != null);
    }

    sectionHasVisibleItems(section: ProgrammeSection) {
        // console.log("sectionHasVisibleItems", section);
        return section.items.filter((item) => this.showArchived || !item.isArchived).length > 0;
    }

    onSectionDragStart() {
        console.log("Section drag started");
        this.sectionDragging = true;
    }

    onSectionDragEnd() {
        console.log("Section drag ended");
        this.sectionDragging = false;
    }

    onItemDragStart() {
        console.log("Section drag started");
        this.itemDragging = true;
    }

    onItemDragEnd(evt) {
        console.log("Item drag ended", evt);
        console.log("Dragged item", evt.item.dataset.itemId);
        console.log("Dragged from section", evt.from.dataset.sectionId);
        console.log("Dragged to section", evt.to.dataset.sectionId);
        console.log("Dragged to index", evt.newDraggableIndex);
        this.$emit(
            "item-moved",
            new EvtItemMoved(
                parseInt(evt.item.dataset.itemId, 10),
                evt.from.dataset.sectionId === undefined
                    ? null
                    : parseInt(evt.from.dataset.sectionId, 10),
                evt.to.dataset.sectionId === undefined
                    ? null
                    : parseInt(evt.to.dataset.sectionId, 10),
                evt.newDraggableIndex
            )
        );
        this.itemDragging = false;
    }

    onSectionTitleChanged(evt: EvtSectionTitleChanged) {
        console.log("ProgrammeTimeLine.onSectionTitleChanged()", evt);
        this.$emit("section-title-changed", evt);
    }

    onSectionInviteLearners(sectionId: number) {
        this.$emit("section-invite-learners", sectionId!);
    }

    onSectionAutoInviteNewLearners(sectionId: number) {
        this.$emit("section-auto-invite-new-learners", sectionId);
    }

    onSectionDeleteClicked(sectionId: number) {
        this.$emit("section-delete", sectionId);
    }

    onToggleSection(sectionId: number) {
        this.$emit("toggle-section", sectionId);
    }

    expandSection(sectionId: number) {
        this.$emit("expand-section", sectionId);
    }

    onSectionMoved(evt: any) {
        if (evt.moved) {
            console.log("ProgrammeTimeLine.onSectionMoved()", evt, this.sections);
            this.$emit(
                "section-moved",
                new EvtSectionMoved(evt.moved.element.id, evt.moved.oldIndex, evt.moved.newIndex)
            );
        }
    }

    isOutOfDateOrder(section: ISection, item: any) {
        if (this.userIsLearner || this.learnerId) return false;
        const ooo = Utilities.isOutOfDateOrder(section.items, item.id);
        // console.log("outOfDateOrder", section.title, item.title, ooo);
        return ooo;
    }
}
