import moment from "moment";
import Vue from "vue";
import { IDateTimeZone } from "./IDateTimeZone";
import { IListOption } from "./IListOption";
import { ITimeZone } from "./ITimeZone";

export default class TimeZoneUtils {
    public static GetTimeZones(dateTime: string | null, short = true): Promise<IListOption[]> {
        let dateTimeVal = dateTime;
        if (!dateTime) {
            dateTimeVal = moment().toISOString(true);
        }
        return Vue.prototype.$http
            .get(`/v1/timezones/${dateTimeVal}`)
            .then((result: any) =>
                Promise.resolve(
                    result.data.map(
                        (tz: ITimeZone) =>
                            ({
                                value: tz.code,
                                text: TimeZoneUtils.GetSelectionText(tz, short),
                            } as IListOption)
                    )
                )
            )
            .catch(() => Promise.reject());
    }

    public static GetSelectionText(tz: ITimeZone, short = true) {
        // console.log("GetSelectionText", tz);
        return short
            ? `${tz.utcOffset} ${tz.description}`
            : `(${tz.utcOffset}) ${tz.code} (${tz.description})`;
    }

    public static GetTimeZoneDescriptionForDateTime(
        timeZone: string,
        dateTime: string,
        short = true
    ): Promise<string> {
        let dateTimeVal = dateTime;
        if (!dateTime) {
            dateTimeVal = moment().toISOString(true);
        }
        return Vue.prototype.$http
            .get(`/v1/timezone/${dateTimeVal}?timeZone=${timeZone}`) // eslint-disable-line camelcase
            .then((result: any) =>
                Promise.resolve(TimeZoneUtils.GetSelectionText(result.data as ITimeZone, short))
            )
            .catch(() => Promise.resolve(timeZone));
    }

    public static ConvertDateTimeToTimeZone(
        timeZone: string,
        dateTime: string,
        toTimeZone: string
    ): Promise<IDateTimeZone> {
        console.log("Getting start date and time in local time zone");
        return Vue.prototype.$http
            .get(`/v1/timezones/${dateTime}/convert?timeZone=${timeZone}&toTimeZone=${toTimeZone}`) // eslint-disable-line camelcase
            .then((result: any) => Promise.resolve(result.data as IDateTimeZone));
    }
}
