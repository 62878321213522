<template>
    <div v-cloak :class="$mq">
        <base-header>
            <b-row align-v="center" class="py-2">
                <b-col>
                    <h1 class="d-inline-block mb-0">Resources</h1>
                </b-col>
            </b-row>
        </base-header>
        <b-container v-if="loading" fluid class="mt--5 text-center">
            <b-spinner class="mt-2" label="Loading..."></b-spinner>
        </b-container>
        <div v-if="!loading">
            <div v-if="!activeTenantSubscription">
                <subscription-required action-text="access your resources" />
            </div>

            <div
                v-else-if="fileManagerVisible"
                id="resources-view"
                class="gp-resources"
                :class="$mq"
            >
                <ejs-filemanager
                    id="file-manager"
                    ref="file_manager_instance"
                    :view="view"
                    height="100%"
                    :ajaxSettings="fileManagerEndpoints"
                    :beforeSend="beforeSend"
                    :beforeDownload="beforeDownload"
                    :beforeImageLoad="beforeImageLoad"
                    :contextMenuSettings="contextMenuSettings"
                    :toolbarSettings="toolbarSettings"
                    :navigationPaneSettings="navigationPaneSettings"
                    :detailsViewSettings="detailsViewSettings"
                    :showFileExtension="showFileExtension"
                    :showHiddenItems="showHiddenItems"
                    :fileLoad="onBeforeResourceLoad"
                    :fileOpen="onBeforeResourceOpen"
                    :showThumbnail="showThumbnail"
                    :uploadSettings="uploadSettings"
                    :allowMultiSelection="true"
                    :allowDragAndDrop="false"
                    :toolbarClick="toolbarClick"
                    :toolbarCreate="toolbarCreate"
                    :menuClick="menuClick"
                    :menuOpen="menuOpen"
                    :fileDropped="onFileDropped"
                    :popupOpen="onPopupOpen"
                    :success="onSuccess"
                ></ejs-filemanager>
            </div>
        </div>
        <upload-file-dialog
            title="Resources"
            @resource-uploaded="resourceUploaded"
            :folder-id="currentFolderId"
        ></upload-file-dialog>
        <upload-link-dialog
            title="Resources"
            @resource-uploaded="resourceUploaded"
            :folder-id="currentFolderId"
        ></upload-link-dialog>
        <resource-previewer
            v-if="previewResource != null"
            :resource="previewResource"
            @close="onPreviewClosed"
        ></resource-previewer>

        <!-- <external-video-resource-preview-dialog
            :title="previewProps.title"
            :url="previewProps.url"
            :loomId="previewProps.loomId"
            :youTubeId="previewProps.youTubeId"
            :vimeoId="previewProps.vimeoId"
        ></external-video-resource-preview-dialog> -->

        <folder-selection-dialog
            ref="folder_selection_dialog"
            folderSelect
            @folder-selected="onFolderSelected"
        ></folder-selection-dialog>
    </div>
</template>

<script>
import Vue from "vue";
import {
    FileManagerPlugin,
    DetailsView,
    NavigationPane,
    Toolbar,
} from "@syncfusion/ej2-vue-filemanager";
import SubscriptionRequired from "../../components/SubscriptionRequired.vue";
import UploadLinkDialog from "@/components/UploadLinkDialog.vue";
import UploadFileDialog from "@/components/UploadFileDialog.vue";
import ResourcePreviewer from "@/components/resources/ResourcePreviewer.vue";
import ExternalVideoResourcePreviewDialog from "@/components/resources/ExternalVideoResourcePreviewDialog.vue";
import FolderResourcesSelectionDialog from "@/components/FolderResourcesSelectionDialog.vue";
import store from "@/store/index";
import Utilities from "@/utils/Utilities";
import LogUtils from "@/utils/LogUtils";

export default {
    components: {
        "subscription-required": SubscriptionRequired,
        "upload-link-dialog": UploadLinkDialog,
        "upload-file-dialog": UploadFileDialog,
        "resource-previewer": ResourcePreviewer,
        // "external-video-resource-preview-dialog": ExternalVideoResourcePreviewDialog,
        "folder-selection-dialog": FolderResourcesSelectionDialog,
    },
    data() {
        return {
            fileManagerEndpoints: {
                url: `${process.env.VUE_APP_API_BASE_URL}/v1/resources/operations/action`,
                uploadUrl: `${process.env.VUE_APP_API_BASE_URL}/v1/resources/operations/upload`,
            },
            view: "Details",
            // Navigation Pane settings customization
            navigationPaneSettings: { maxWidth: "850px", minWidth: "140px", visible: true },
            // Context Menu settings customization
            // Details View settings customization
            detailsViewSettings: {
                columns: [
                    {
                        field: "name",
                        headerText: "Resource Name",
                        minWidth: 120,
                        width: "auto",
                        customAttributes: { class: "e-fe-grid-name" },
                        template: "${name}", // eslint-disable-line no-template-curly-in-string
                    },
                    // {
                    //     field: "size", headerText: "File Size", minWidth: 50, width: "110", template: "${size}", // eslint-disable-line no-template-curly-in-string
                    // },
                    // {
                    //     field: "_fm_modified", headerText: "Date Modified", minWidth: 50, width: "190",
                    // },
                ],
            },
            // Hides the file extension in File Manager
            showFileExtension: false,
            // The default value set for showHiddenItems is false
            showHiddenItems: false,
            // Hides the thumbnail images in File Manager's large icons view
            showThumbnail: true,
            // Upload settings customization
            uploadSettings: {
                maxFileSize: 50 * 1024 * 1024,
                autoClose: true,
                autoUpload: true,
            },
            currentFolderId: null,
            itemsToMove: null,
            activeTenantSubscription: Utilities.userHasActiveTenantSubscription(store.state.user),
            currentUserId: null,
            error: null,
            loading: false,
            tokenRefreshIntervalId: null,
            fileManagerVisible: false,
            previewResource: null,
            resourcePermissions: null,
        };
    },
    errorCaptured: (err) => {
        console.log("Caught error", err.message);
        return false;
    },
    provide: {
        filemanager: [DetailsView, NavigationPane, Toolbar],
    },
    mounted() {
        this.ensureValidBearertoken(); // Rely on FolderResourcesSelectionDialog to keep token refreshed
    },
    computed: {
        // Toolbar settings customization
        toolbarSettings() {
            const items = [];
            if (this.resourcePermissions.canCreateFolder) {
                items.push("NewFolder");
            }
            items.push("View");
            items.push("Rename");
            items.push("UploadFile");
            items.push("UploadLink");
            console.log("toolbarSettings.items", items);

            return {
                items,
                visible: true,
            };
        },
        contextMenuSettings() {
            const file = ["Open", "|"];
            if (this.resourcePermissions.canMove) {
                file.push("Move");
            }
            if (this.resourcePermissions.canRename) {
                file.push("Rename");
            }
            if (this.resourcePermissions.canDelete) {
                file.push("Delete");
            }

            const folder = ["Open", "|"];
            if (this.resourcePermissions.canMove) {
                folder.push("Move");
            }
            if (this.resourcePermissions.canRename) {
                folder.push("Rename");
            }

            return {
                file,
                folder,
                layout: ["Refresh"],
                visible: true,
            };
        },
    },
    methods: {
        ensureValidBearertoken() {
            // Make sure that the user's bearer token hasn't expired
            Vue.prototype.$http
                .get("/v1/resources/operations/ensurevalidbearertoken")
                .then((res) => {
                    this.fileManagerVisible = true;
                    this.resourcePermissions = JSON.parse(res.headers["x-gp-permissions"]).resource;
                    console.log("resourcePermissions", this.resourcePermissions);
                });
        },
        onSuccess(args) {
            console.log("onSuccess", args);
        },
        onFolderSelected(folderId) {
            console.log(
                "onFolderSelected",
                folderId,
                this.$refs.file_manager_instance.getSelectedFiles()
            );
            Vue.prototype.$http
                .post(`${process.env.VUE_APP_API_BASE_URL}/v1/resources/operations/action`, {
                    action: "move",
                    data: this.itemsToMove,
                    targetData: {
                        id: folderId,
                    },
                })
                .then((res) => {
                    // this.$refs.file_manager_instance.path = "/";
                    // this.$refs.file_manager_instance.refreshFiles();
                    // this.$refs.file_manager_instance.refreshLayout();
                    this.$refs.file_manager_instance.openFile(folderId);
                    this.itemsToMove = null;

                    this.forceRefresh();
                })
                .catch((err) => {
                    console.log("Could move resouces", err);
                    this.itemsToMove = null;
                });
        },
        forceRefresh() {
            this.fileManagerVisible = false;
            this.$nextTick(() => {
                this.fileManagerVisible = true;
            });
        },
        onFileSelect(args) {
            console.log("onFileSelect", args);
            // return this.$refs.file_manager_instance ? this.$refs.file_manager_instance.path : null;
        },
        beforeSend(args) {
            console.log("beforeSend", args);

            // Set authentication header on the request
            args.ajaxSettings.beforeSend = (xhr) => {
                xhr.httpRequest.setRequestHeader(
                    "authorization",
                    Utilities.userBearerTokenHeader(store.state.user)
                );
            };

            // var data = JSON.parse(args.ajaxSettings.data);
            // if (args.action == "Upload") {
            //     // Allow custom data for upload operations
            //     data.push({ 'username': "user1" });
            //     args.ajaxSettings.data = JSON.stringify(data);
            // } else {
            //     // Add custom parameter column
            //     data["username"] = "user1";
            //     // Add custom parameter in ajax settings
            //     args.ajaxSettings.data = JSON.stringify(data);
            // }
        },
        beforeDownload(args) {
            console.log("beforeDownload", args);
        },
        beforeImageLoad(args) {
            console.log("beforeImageLoad", args);
            args.imageUrl = args.fileDetails[0].url;
        },
        onFileDropped(args) {
            console.log("onFileDropped", args);
        },
        onPopupOpen(args) {
            console.log("onPopupOpen", args);
            if (args.popupName === "Delete") {
                args.popupModule.header = "Delete Resource(s)";
                args.popupModule.content =
                    "<div>Are you sure you want to delete the resource(s)?</div>";
            }
        },
        // File Manager's file beforeFileLoad function
        onBeforeResourceLoad(args) {
            // console.log(`${args.fileDetails.name} is loading`, args);
        },
        // File Manager's file beforeFileOpen function
        onBeforeResourceOpen(args) {
            console.log(`${args.fileDetails.name} is opened`, args);
            this.previewResource = null;

            if (!args.fileDetails.isFile) {
                this.currentFolderId = args.fileDetails.id
                    ? parseInt(args.fileDetails.id, 10)
                    : null;
                return;
            }

            if (args.fileDetails.isAzureBlobResource && Utilities.resourceIsPDF(args.fileDetails)) {
                this.previewResource = {
                    ...args.fileDetails,
                    title: args.fileDetails.name,
                };

                this.$nextTick(() => {
                    this.$bvModal.show("pdf-resource-preview-dialog");
                });
                args.cancel = true;
                return;
            }

            if (
                args.fileDetails.isAzureBlobResource &&
                Utilities.resourceIsImage(args.fileDetails)
            ) {
                this.previewResource = {
                    ...args.fileDetails,
                    title: args.fileDetails.name,
                };

                this.$nextTick(() => {
                    this.$bvModal.show("image-resource-preview-dialog");
                });

                args.cancel = true;
                return;
            }
            if (
                args.fileDetails.isAzureBlobResource &&
                Utilities.resourceIsUploadedVideo(args.fileDetails)
            ) {
                this.previewResource = {
                    ...args.fileDetails,
                    title: args.fileDetails.name,
                };

                this.$nextTick(() => {
                    this.$bvModal.show("video-resource-preview-dialog");
                });

                args.cancel = true;
                return;
            }
            if (Utilities.resourceIsExternalVideo(args.fileDetails)) {
                console.log("Showing external video preview dialog");
                this.previewResource = {
                    title: args.fileDetails.name,
                    loomId: args.fileDetails.loomId,
                    youTubeId: args.fileDetails.youTubeId,
                    vimeoId: args.fileDetails.vimeoId,
                };
                console.log(this.previewResource);

                this.$nextTick(() => {
                    this.$bvModal.show("external-video-resource-preview-dialog");
                });

                args.cancel = true;
                return;
            }
            if (args.fileDetails.isWebLink || args.fileDetails.isAzureBlobResource) {
                const win = window.open(args.fileDetails.url, "_blank");
                win.focus();
            }
        },
        menuClick(args) {
            console.log("menuClick", args, `${this.$refs.file_manager_instance.$el.id}_cm_move`);
            if (args.item.id === `${this.$refs.file_manager_instance.$el.id}_cm_move`) {
                this.itemsToMove = args.fileDetails;
                this.$refs.folder_selection_dialog.showDialog();
            }
        },
        menuOpen(args) {
            console.log("menuOpen", args);
        },
        toolbarClick(args) {
            console.log("toolbarClick", args);
            if (args.item.id === `${this.$refs.file_manager_instance.$el.id}_tb_uploadfile`) {
                // this.$bvModal.show("upload-file-dialog");
                this.$refs.file_manager_instance.uploadFiles();
            }
            if (args.item.id === `${this.$refs.file_manager_instance.$el.id}_tb_uploadlink`) {
                this.$bvModal.show("upload-link-dialog");
            }
            // if (args.item.id === `${this.$refs.file_manager_instance.$el.id}_tb_open`) {
            //     console.log("_tb_open", args, args.fileDetails[0].id);
            //     if (args.fileDetails.length) {
            //         this.$refs.file_manager_instance.openFile(args.fileDetails[0].id);
            //     }
            // }
        },
        toolbarCreate(args) {
            console.log("toolbarCreate", args);
            for (let i = 0; i < args.items.length; i += 1) {
                if (args.items[i].id === `${this.$refs.file_manager_instance.$el.id}_tb_open`) {
                    args.items[i].text = "Open";
                    args.items[i].prefixIcon = "e-icons e-fe-upload";
                    args.items[i].tooltipText = "Open";
                    args.items[i].showTextOn = "Both";
                }
                if (
                    args.items[i].id === `${this.$refs.file_manager_instance.$el.id}_tb_uploadfile`
                ) {
                    args.items[i].text = "Upload file(s)";
                    args.items[i].prefixIcon = "e-icons e-fe-upload";
                    args.items[i].tooltipText = "Upload file(s)";
                    args.items[i].showTextOn = "Both";
                }
                if (
                    args.items[i].id === `${this.$refs.file_manager_instance.$el.id}_tb_uploadlink`
                ) {
                    args.items[i].text = "Add web link";
                    args.items[i].prefixIcon = "e-icons e-fe-link";
                    args.items[i].tooltipText = "Add web link";
                    args.items[i].showTextOn = "Both";
                }
                if (args.items[i].id === `${this.$refs.file_manager_instance.$el.id}_tb_move`) {
                    args.items[i].text = "Move";
                    args.items[i].prefixIcon = "e-icons e-fe-move";
                    args.items[i].tooltipText = "Move";
                    args.items[i].showTextOn = "Both";
                }
            }
        },
        addResourceToEvent(resource, event) {
            const addButton = document.querySelector(`#add-resource-${resource.id}`);
            addButton.disabled = true;
            // eslint-disable-next-line no-undef
            const spinner = new Spinner().spin();
            addButton.appendChild(spinner.el);

            Vue.prototype.$http
                .post(`api/event/${event.id}/existingresource/${resource.id}`, {
                    resourceId: resource.id,
                })
                .then(() => {
                    addButton.disabled = false;
                    spinner.stop();

                    resource.eventsAddedTo.push(event);
                    // Remove event from array
                    resource.eventsNotAddedTo = resource.eventsNotAddedTo.filter(
                        (e) => e.id !== event.id
                    );
                })
                .catch((err) => {
                    LogUtils.Error("Unable to add resource to event", err, "ResourcesView");
                    addButton.disabled = false;
                    spinner.stop();
                    Utilities.gpErrorAlert("An error occurred while adding the resource.");
                });
        },
        addResourceToProgramme(resource, programme) {
            const addButton = document.querySelector(`#add-programme-resource-${resource.id}`);
            addButton.disabled = true;
            // eslint-disable-next-line no-undef
            const spinner = new Spinner().spin();
            addButton.appendChild(spinner.el);

            Vue.prototype.$http
                .post(`/internal/v1/programmes/${programme.id}/resources`, {
                    resourceId: resource.id,
                })
                .then((res) => {
                    addButton.disabled = false;
                    spinner.stop();

                    resource.programmesAddedTo.push(programme);
                    // Remove event from array
                    resource.programmesNotAddedTo = resource.programmesNotAddedTo.filter(
                        (p) => p.id !== programme.id
                    );
                })
                .catch((err) => {
                    LogUtils.Error("Unable to add resource to programme", err, "ResourcesView");
                    addButton.disabled = false;
                    spinner.stop();
                    Utilities.gpErrorAlert("An error occurred while adding the resource.");
                });
        },
        onUploadWebResource() {
            const vm = this;
            const urlEntry = document.getElementById("url-entry");
            const titleEntry = document.getElementById("title-entry");

            // Get the url from the input.
            const url = urlEntry.value;
            const title = titleEntry.value;

            // Create a new FormData object.
            const formData = new FormData();

            // Add the file to the request.
            formData.append("url", url);
            formData.append("title", title);

            // Open the connection.
            Vue.prototype.$http
                .post("mobile/api/resource/webresource", formData)
                .then((res) => {
                    const resourceAdded = res.data;
                    console.log("resourceAdded", resourceAdded);
                    document.querySelector("#upload-web-resource-modal").modal("hide");
                    vm.resources.push(resourceAdded);
                })
                .catch((err) => {
                    LogUtils.Error("Unable to upload resource", err, "ResourcesView");
                    Utilities.gpErrorAlert("An error occurred while uploading the resource.");
                    document.querySelector("#upload-web-resource-modal").modal("hide");
                });
        },
        resourceAddedToEvent(resource, eventId) {
            return resource.events.filter((e) => e.id === eventId).length;
        },
        resourceUploaded(resource) {
            // this.resources.push(resource);
            console.log("file manager", this.$refs.file_manager_instance);
            this.$refs.file_manager_instance.refreshFiles();
        },
        urlApiResourceDownload(resourceId) {
            return `api/resource/download/${resourceId}`;
        },
        urlYoutube(youTubeId) {
            return `https://www.youtube-nocookie.com/embed/${youTubeId}?rel=0`;
        },
        urlVimeo(vimeoId) {
            return `https://player.vimeo.com/video/${vimeoId}`;
        },
        urlLoom(loomId) {
            return `https://www.loom.com/embed/${loomId}`;
        },
        urlApiAvatar(userId) {
            return `/api/avatar/${userId}`;
        },
        onNodeSelect(node, isSelected) {
            console.log("onNodeSelect() called with:", node.data.name, isSelected);
        },
        onPreviewClosed() {
            console.log("onPreviewClosed()");
            this.previewResource = null;
        },
    },
};
</script>

<style lang="scss">
.tree-node .tree-node-label {
    font-size: 1.25em;
    border-width: 10px;
}
.tree-node-icon {
    font-size: 1.25em;
}
.tree-branch-selected span.tree-node-label {
    font-weight: 600;
}
.e-empty-inner-content {
    display: none;
}
#resources-view .e-search-wrap {
    display: none;
}
.mobile #file-manager {
    height: 100% !important;
}
.e-fe-link::before {
    content: "\e34e";
}
.gp-resources .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn {
    color: #fff;
    background-color: #f47920;
    border-color: #f47920;
    // // font-size: .75rem;
    // cursor: pointer;
    // position: relative;
    // text-transform: none;
    // transition: all .15s ease;
    // // letter-spacing: .025em;
    will-change: transform;
    // line-height: 1.5;
    // border-radius: .25rem;
}
.gp-resources
    .e-filemanager
    .e-toolbar
    .e-toolbar-items
    .e-toolbar-item
    .e-dropdown-btn.e-btn.e-tbar-btn:hover {
    background: #f47920;
    border-color: #adadad;
}
.gp-resources .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn-text {
    color: #fff;
}
.gp-resources .e-toolbar .e-icons {
    color: #fff;
}
.gp-resources .e-btn.e-flat.e-primary {
    background-color: #f47920;
    border-color: #f47920;
    color: #fff;
}
#resources-view.gp-resources.mobile .e-tbar-btn .e-btn-icon {
    padding: 0;
}
</style>

<style lang="scss" scoped>
#resources-view {
    height: calc(100vh - 132px);
}
#resources-view.mobile {
    height: calc(100vh - 126px);
}

.page-header-no-extend {
    margin-bottom: 0;
}
</style>
