
import { Component, Emit, Vue } from "vue-property-decorator";
import store from "@/store/index";
import Utilities from "@/utils/Utilities";
import LogUtils from "@/utils/LogUtils";
import { IHomeViewTile } from "./IHomeViewTile";
import { IUserTile } from "./IUserTile";

@Component({
    components: {},
})
export default class TheGettingStarted extends Vue {
    // unreadMessageCount: Number | undefined = 0;
    loading = true;

    zoomIntegrationEnabled = true;

    dismissedTiles: string[] = [];

    get tiles(): IHomeViewTile[] {
        return [
            {
                id: "learn_about_programmes",
                title: "Learn about Programmes",
                userRoles: ["Trainer", "TenantAdmin"],
                content:
                    "Understanding how to use programmes is fundamental to getting the most out of GiraffePad.<br><br> We've made a short video to explain what programmes are all about.",
                buttonText: "Watch the video",
                buttonAction: this.startTour,
                buttonIcon: "play-btn",
                actionArgs: {
                    tourId: "ykzwtd887PA",
                },
                dimissable: true,
                isVisible: () => (this as any).$mq !== "mobile",
            },
            {
                id: "create_a_programme",
                title: "Create a Programme",
                userRoles: ["Trainer", "TenantAdmin"],
                content: "Ready to create your own programme? This tour will show you how.",
                buttonText: "Let's do it!",
                buttonAction: this.startTour,
                buttonIcon: null,
                actionArgs: {
                    tourId: "8YDcyMDjH8p",
                },
                dimissable: false,
                isVisible: () =>
                    !Utilities.userTenantHasProgramme(store.state.user) &&
                    (this as any).$mq !== "mobile",
            },
            {
                id: "take_a_ui_tour",
                title: "Take a UI Tour",
                userRoles: ["Trainer", "TenantAdmin"],
                content: "Let us show you around so you know where to find everything!",
                buttonText: "Start tour",
                buttonAction: this.startTour,
                buttonIcon: null,
                actionArgs: {
                    tourId: "y7JGCfIPQ9m",
                },
                dimissable: true,
                isVisible: () => (this as any).$mq !== "mobile",
            },
            {
                id: "complete_your_profile",
                title: "Complete Your Profile",
                userRoles: ["Trainer", "TenantAdmin"],
                content:
                    "Completing your profile is important - it helps learners and other team members engage and make a connection with you.",
                buttonText: "Show me!",
                buttonAction: this.startTour,
                buttonIcon: null,
                actionArgs: {
                    tourId: "DBecQrTL4wq",
                },
                dimissable: false,
                isVisible: () => !Utilities.userProfileComplete(store.state.user),
            },
            {
                id: "enable_zoom_integration",
                title: "Enable Zoom Integration",
                userRoles: ["TenantAdmin"],
                content:
                    "Connecting to a Zoom account enables you to easily schedule Zoom meetings in your GiraffePad programmes! ",
                buttonText: "Let's do it!",
                buttonAction: this.redirect,
                buttonIcon: null,
                actionArgs: {
                    route: {
                        name: "Integrations",
                    },
                },
                dimissable: true,
                isVisible: () => this.zoomEnabled === false,
            },
            {
                id: "learn_about_questionnaires",
                title: "Learn about Questionnaires",
                userRoles: ["Trainer", "TenantAdmin"],
                content:
                    "The questionnaires feature can be used for all levels of feedback, quizzes, knowledge tests, needs analysis and more.<br><br> Watch the video to see how you can use questionnaires to build even more incredible learning journeys in GiraffePad.",
                buttonText: "Watch the video",
                buttonAction: this.startTour,
                buttonIcon: "play-btn",
                actionArgs: {
                    tourId: "oNm1KHyjfBm",
                },
                dimissable: true,
                isVisible: () => true,
            },
        ];
    }

    get zoomEnabled(): boolean {
        return this.zoomIntegrationEnabled;
    }

    get userTiles(): IHomeViewTile[] {
        // console.log("userTiles()");
        if (this.loading) {
            return [];
        }

        // console.log(this.zoomEnabled);
        // this.tiles.forEach((t) => {
        //     console.log(`${t.id} is visible: `, t.isVisible());
        // });

        const userTiles = this.tiles.filter(
            (t) =>
                Utilities.userIsInAnyOfRoles(store.state.user, t.userRoles) &&
                !this.dismissedTiles.includes(t.id) &&
                t.isVisible()
        );

        return userTiles;
    }

    get isVisible(): boolean {
        return !this.loading && this.userTiles.length > 0;
    }

    startTour(tile: IHomeViewTile) {
        globalThis.helpHero.startTour(tile.actionArgs.tourId);
    }

    redirect(tile: IHomeViewTile) {
        this.$router.push(tile.actionArgs.route);
    }

    confirmHideTile(tile: IHomeViewTile) {
        Utilities.gpModalConfirm(
            this.$bvModal,
            "Hide this?",
            "Please confirm that you don't wish to see this any more.",
            "Hide",
            "Cancel",
            true
        ).then((confirmed) => {
            if (confirmed) {
                Vue.prototype.$http
                    .delete(`/v1/users/me/tiles/${tile.id}`)
                    .then(() => {
                        this.dismissedTiles.push(tile.id);
                    })
                    .catch((err) => {
                        LogUtils.Error("Unable to hide tile", err, "TheGettingStarted");
                    });
            }
        });
    }

    created() {
        // Get the ids of tiles already dsimissed by this user
        Vue.prototype.$http
            .get("/v1/users/me/tiles")
            .then((res) => {
                this.dismissedTiles = res.data
                    .filter((t: IUserTile) => t.hidden)
                    .map((t: IUserTile) => t.tileId);
                this.loading = false;
                this.loaded();
            })
            .catch((err) => {
                LogUtils.Error("Unable to get user's hidden tiles", err, "TheGettingStarted");
                this.loading = false;
                this.loaded();
            });

        Vue.prototype.$http
            .get(`/v1/accounts/${Utilities.userTenantId(store.state.user)}/integrations/zoom`)
            .then((res) => {
                this.zoomIntegrationEnabled = res.data.integrationEnabled;
            })
            .catch(() => {
                this.zoomIntegrationEnabled = false;
            });
    }

    @Emit() loaded() {
        console.log("TheGettingStarted loaded");
    }
}
