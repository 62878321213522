
import { Component, Prop, Vue } from "vue-property-decorator";
import ISection from "@/components/Timeline/ISection";
import EvtSectionTitleChanged from "./EvtSectionTitleChanged";

@Component({
    components: {},
})
export default class TimeLineSectionHeader extends Vue {
    @Prop({ type: Object, required: true }) section!: ISection;

    @Prop({ type: Boolean, default: false }) canEdit!: boolean;

    @Prop({ type: Boolean, default: false }) canDrag!: boolean;

    @Prop({ type: Boolean, default: false }) isExpanded!: boolean;

    @Prop({ type: Boolean, default: false }) hasOverdueItems!: boolean;

    @Prop({ type: Boolean, default: false }) sectionDragInProgress!: boolean;

    @Prop({ type: Boolean, default: false }) showArchived!: boolean;

    @Prop({ type: Boolean, required: true }) autoInvite!: boolean;

    newTitle = "";

    editing = false;

    created() {
        if (this.canEdit && (this.section as any).isNew) {
            delete (this.section as any).isNew;
            this.onRenameClicked();
        }
    }

    get itemsSummary(): string {
        const archivedItemsCount = this.section.items.filter((i) => i.isArchived).length;
        const otherItemsCount = this.section.items.length - archivedItemsCount;
        return archivedItemsCount > 0 && this.showArchived
            ? `(${otherItemsCount} + ${archivedItemsCount} archived)`
            : `(${otherItemsCount})`;
    }

    onDragOver() {
        if (!this.sectionDragInProgress) {
            this.$emit("drag-over", this.section.id);
        }
    }

    onRenameClicked() {
        this.newTitle = this.section.title;
        this.editing = true;
        setTimeout(() => {
            const ref = `section-title-input-${this.section.id}`;
            console.log("Setting focus to ref: ", ref);
            (this.$refs[ref] as HTMLElement).focus();
            (this.$refs[ref] as HTMLElement).scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
        }, 300);
        // this.$nextTick(() => {
        //     const ref = `section-title-input-${this.section.id}`;
        //     console.log("Setting focus to ref: ", ref);
        //     (this.$refs[ref] as HTMLElement).focus();
        // });
    }

    onInviteLearners() {
        this.$emit("section-invite-learners", this.section.id!);
    }

    onAutoInviteNewLearners() {
        this.$emit("section-auto-invite-new-learners", this.section.id!);
    }

    onDeleteClicked() {
        this.$emit("section-delete", this.section.id!);
    }

    onEditingCompleted() {
        if (this.editing) {
            if (this.newTitle.length === 0) {
                this.newTitle = "New section";
            }
            this.$emit(
                "section-title-changed",
                new EvtSectionTitleChanged(this.section.id!, this.newTitle)
            );
            this.editing = false;
        }
    }

    onToggleSection() {
        if (!this.editing) {
            this.$emit("toggle-section", this.section.id);
        }
    }
}
