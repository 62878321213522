
import { defineComponent } from "vue";

export default defineComponent({
    name: "ClientDetailsPanel",
    components: {},
    props: {
        client: {
            type: Object,
            required: true,
        },
    },
    computed: {},
    methods: {
        fullName(firstName, lastName) {
            return `${firstName} ${lastName}`;
        },
    },
});
