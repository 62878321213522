
// import { Component, Prop, Vue } from "vue-property-decorator";

import { defineComponent } from "vue";

// @Component
// export default class HelloWorld extends Vue {
//   @Prop() private msg!: string;
// }

export default defineComponent({
    name: "HelloWorld",
    props: {
        msg: { type: String, required: true },
    },
});
