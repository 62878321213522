import moment from "moment";

export default class Utilities {
    public static fullName(firstName: string, lastName: string): string {
        return `${firstName} ${lastName}`;
    }

    public static userIsInRole(user: any, roleName: string): boolean {
        return (
            user &&
            user.claims.filter(
                (claim: any) =>
                    claim.type === "http://schemas.microsoft.com/ws/2008/06/identity/claims/role" &&
                    claim.value === roleName
            ).length > 0
        );
    }

    public static userIsInAnyOfRoles(user: any, roleNames: string[]): boolean {
        const userIsInAtLeastOneRole = roleNames.some((roleName) =>
            Utilities.userIsInRole(user, roleName)
        );
        return userIsInAtLeastOneRole;
    }

    public static userIsTenantAdmin(user: any): boolean {
        return Utilities.userIsInRole(user, "TenantAdmin");
    }

    public static userTenantName(user: any): boolean {
        const tenantNameClaims = user.claims.filter(
            (claim: any) => claim.type === "http://giraffepad.com/claims/tenantname"
        );
        return tenantNameClaims.length === 0 ? null : tenantNameClaims[0].value;
    }

    public static userTenantHasProgramme(user: any): boolean {
        return user.claims
            .map((c) => c.type)
            .includes("http://giraffepad.com/claims/tenanthasprogramme");
    }

    public static userTenantHasProSubscription(user: any): boolean {
        return user.claims
            .map((c) => c.type)
            .includes("http://giraffepad.com/claims/tenanthasprosubscription");
    }

    public static userTenantHasPerSeatSubscription(user: any): boolean {
        return user.claims
            .map((c) => c.type)
            .includes("http://giraffepad.com/claims/tenanthasperseatsubscription");
    }

    public static userProfileComplete(user: any): boolean {
        return user.claims
            .map((c) => c.type)
            .includes("http://giraffepad.com/claims/profilecomplete");
    }

    public static userIsTrainer(user: any): boolean {
        return Utilities.userIsInRole(user, "Trainer");
    }

    public static userIsLearner(user: any): boolean {
        return Utilities.userIsInRole(user, "Learner");
    }

    public static userHasActiveTenantSubscription(user: any): boolean {
        return (
            user &&
            user.claims.filter(
                (claim: any) =>
                    claim.type === "http://giraffepad.com/claims/subscriptionactive" &&
                    claim.value === "true"
            ).length > 0
        );
    }

    public static userBearerTokenHeader(user: any): string {
        return `bearer ${user.token}`;
    }

    public static userTenantId(user: any): number | null {
        const tenantIdClaims = user.claims.filter(
            (claim: any) => claim.type === "http://giraffepad.com/claims/tenantid"
        );
        return tenantIdClaims.length === 0 ? null : parseInt(tenantIdClaims[0].value, 10);
    }

    public static userLearnerId(user: any): number | null {
        const learnerIdClaims = user.claims.filter(
            (claim: any) => claim.type === "http://giraffepad.com/claims/learnerid"
        );
        return learnerIdClaims.length === 0 ? null : parseInt(learnerIdClaims[0].value, 10);
    }

    public static userTenantStripeCustomerId(user: any): string | null {
        const claims = user.claims.filter(
            (claim: any) => claim.type === "http://giraffepad.com/claims/tenantstripecustomerid"
        );
        return claims.length === 0 ? null : claims[0].value;
    }

    public static userTenantBillflowHash(user: any): string | null {
        const claims = user.claims.filter(
            (claim: any) => claim.type === "http://giraffepad.com/claims/tenantbillflowhash"
        );
        return claims.length === 0 ? null : claims[0].value;
    }

    public static userTenantCurrency(user: any): string | null {
        const claims = user.claims.filter(
            (claim: any) => claim.type === "http://giraffepad.com/claims/tenantcurrency"
        );
        return claims.length === 0 ? null : claims[0].value;
    }

    public static userHasClientsViewPermission(user: any): boolean {
        return (
            user &&
            user.claims.filter(
                (claim: any) =>
                    claim.type === "http://giraffepad.com/claims/clientviewpermission" &&
                    claim.value === "true"
            ).length > 0
        );
    }

    public static userHasTeamViewPermission(user: any): boolean {
        return (
            user &&
            user.claims.filter(
                (claim: any) =>
                    claim.type === "http://giraffepad.com/claims/teamviewpermission" &&
                    claim.value === "true"
            ).length > 0
        );
    }

    public static userHasTeamChatPermission(user: any): boolean {
        return (
            user &&
            user.claims.filter(
                (claim: any) =>
                    claim.type === "http://giraffepad.com/claims/teamchatpermission" &&
                    claim.value === "true"
            ).length > 0
        );
    }

    public static userHasResourcesAccessLibraryPermission(user: any): boolean {
        return (
            user &&
            user.claims.filter(
                (claim: any) =>
                    claim.type ===
                        "http://giraffepad.com/claims/resourcesaccesslibrarypermission" &&
                    claim.value === "true"
            ).length > 0
        );
    }

    public static userHasClaim(user: any, claimType: string): boolean {
        return (
            user &&
            user.claims.filter((claim: any) => claim.type === claimType && claim.value === "true")
                .length > 0
        );
    }

    public static userHasAnyOfClaims(user: any, claimTypes: string[]): boolean {
        const userHasAtLeastOneClaim = claimTypes.some((claimType) =>
            Utilities.userHasClaim(user, claimType)
        );
        return userHasAtLeastOneClaim;
    }

    public static userIsEventLeadTrainer(user: any, event: any): boolean {
        return event.trainer.userId === user.userId;
    }

    public static userIsAnEventTrainer(user: any, event: any): boolean {
        const isAnEventTrainer =
            event.trainer.userId === user.userId ||
            !(event.trainers.find((trainer: any) => trainer.userId === user.userId) === undefined);

        // console.log("Is an event trainer?:", isAnEventTrainer, event.title, event.trainer, event.trainers);
        return isAnEventTrainer;
    }

    public static formattedDateTimeSpan(dateFormat: string, date1: Date, date2?: Date): string {
        const strDate1 = moment(date1).format(dateFormat);
        const strDate2 = date2 ? moment(date2).format(dateFormat) : "";
        if (date2) {
            if (strDate1 !== strDate2) {
                return `${strDate1} - ${strDate2}`;
            }
            return strDate1;
        }
        return strDate1;
    }

    public static CombineDateTime(date: string, time: string): string {
        return `${date}T${time}`;
    }

    public static avatarUrl(userId: string): string {
        return `${process.env.VUE_APP_API_BASE_URL}/v1/users/${userId}/avatar`;
    }

    public static tenantLogoUrl(tenantId: number): string {
        return `${process.env.VUE_APP_API_BASE_URL}/v1/accounts/${tenantId}/logo`;
    }

    public static getEnvironmentName(): string {
        return process.env.VUE_APP_GIRAFFEPAD_ENV!;
    }

    public static containsFilterString(arg: string, filter: string): boolean {
        if (arg === null || arg === undefined || arg.length === 0) {
            return false;
        }

        return arg.toLowerCase().includes(filter.toLowerCase());
    }

    public static gpErrorAlert(
        modal: any,
        message: string,
        title = "Error",
        okText = "OK",
        cancelText = "Cancel"
    ) {
        return modal.msgBoxOk(message, {
            title,
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: okText,
            cancelTitle: cancelText,
            headerClass: "p-2 border-bottom-0",
            footerClass: "p-2 border-top-0",
            hideHeaderClose: true,
            centered: true,
        });
    }

    public static gpSuccessAlert(
        modal: any,
        message: string,
        title = "Success",
        okText = "OK",
        cancelText = "Cancel"
    ) {
        return modal.msgBoxOk(message, {
            title,
            okVariant: "success",
            okTitle: okText,
            cancelTitle: cancelText,
            cancelVariant: "outline-danger",
            headerClass: "modal-header",
            footerClass: "p-2 border-top-0",
            hideHeaderClose: true,
            centered: true,
        });
    }

    public static gpModalConfirm(
        modal: any,
        title: string,
        message: string,
        okText = "OK",
        cancelText = "Cancel",
        isDestructive = false
    ): Promise<boolean | null> {
        return modal.msgBoxConfirm(message, {
            title,
            okVariant: isDestructive ? "danger" : "success",
            okTitle: okText,
            cancelTitle: cancelText,
            cancelVariant: isDestructive ? "outline-default" : "outline-danger",
            headerClass: "modal-header",
            footerClass: "p-2 border-top-0",
            hideHeaderClose: true,
            centered: true,
        });
    }

    public static logAxiosError(error: any) {
        if (error.response) {
            // console.log("Status", error.response.status);
            if (error.response.data.errors) {
                // console.log("Errors:", error.response.data.errors);
            }
        }
    }

    public static isInThePast(dateTime: string): boolean {
        const past = moment().isSameOrAfter(dateTime);
        return past;
    }

    public static relativeDateTimeString(dateTime: Date): string {
        return moment(dateTime).subtract(20, "s").fromNow();
    }

    public static sortEventsAndActivities(items: any[]) {
        const sorted = items.slice().sort((event1, event2) => {
            let date1 = event1.startDateAndTime;
            if (event1.eventType === 3 || event1.eventType === 4 || event1.eventType === 5) {
                date1 = event1.endDateAndTime; // activity due date
            }

            let date2 = event2.startDateAndTime;
            if (event2.eventType === 3 || event2.eventType === 4 || event2.eventType === 5) {
                date2 = event2.endDateAndTime; // activity due date
            }

            if (date1 === null && date2 === null) {
                return event1.id - event2.id; // show in id order
            }

            if (date1 === null) {
                return 1; // show last
            }

            if (date2 === null) {
                return -1; // show last
            }

            return new Date(date1).getTime() - new Date(date2).getTime();
        });

        return sorted;
    }

    public static getStatusNew(
        eventType: number,
        completed: boolean | null,
        recordAttendance: boolean | null,
        past: boolean | null,
        attendanceIntended: boolean | null
    ): any {
        let status: any = {
            icon: "",
            variant: "",
            tooltip: "",
            past: past,
        };
        if (eventType === 0 || eventType === 1 || eventType === 2) {
            if (completed === true) {
                return {
                    icon: "check-circle-fill",
                    variant: "success",
                    tooltip: "Event attended",
                    past: past,
                };
            }
            if (completed === false) {
                return {
                    icon: "x-circle-fill",
                    variant: "danger",
                    tooltip: "Event not attended",
                    past: past,
                };
            }

            // ** completed === null **
            if (recordAttendance && past) {
                return {
                    icon: "question-circle",
                    variant: "default",
                    tooltip: "Attendance not recorded",
                    past: past,
                };
            }
            if (past) {
                return {
                    icon: "check-circle-fill",
                    variant: "success",
                    tooltip: "Attendance assumed",
                    past: past,
                };
            }
            if (attendanceIntended === true) {
                return {
                    icon: "hand-thumbs-up-fill",
                    variant: "success",
                    tooltip: "Attending",
                    past: past,
                };
            }
            if (attendanceIntended === false) {
                return {
                    icon: "hand-thumbs-down-fill",
                    variant: "danger",
                    tooltip: "Not attending",
                    past: past,
                };
            }
            return {
                icon: "clock",
                variant: "default",
                tooltip: "Event not yet started",
                past: past,
            };
        }

        if (eventType === 3) {
            if (completed === true) {
                status = {
                    icon: "check-circle-fill",
                    variant: "success",
                    tooltip: "Activity completed",
                    past: past,
                };
            } else if (completed === false || completed === null) {
                status = {
                    icon: "x-circle-fill",
                    variant: "danger",
                    tooltip: "Activity not completed",
                    past: past,
                };
            }
        } else if (eventType === 4) {
            if (completed === true) {
                status = {
                    icon: "check-circle-fill",
                    variant: "success",
                    tooltip: "Questionnaire completed",
                    past: past,
                };
            } else if (completed === false || completed === null) {
                status = {
                    icon: "x-circle-fill",
                    variant: "danger",
                    tooltip: "Questionnaire not completed",
                    past: past,
                };
            }
        } else if (eventType === 5) {
            if (completed === true) {
                status = {
                    icon: "check-circle-fill",
                    variant: "success",
                    tooltip: "Video watched",
                    past: past,
                };
            } else {
                status = {
                    icon: "x-circle",
                    variant: "default",
                    tooltip: "Video not watched",
                    past: past,
                };
            }
        }
        return status;
    }

    public static sortbyFirstNameLastName(people: any[]): any[] {
        return people.sort((a: any, b: any) => {
            if (a.firstName < b.firstName) return -1;
            if (a.firstName > b.firstName) return 1;
            if (a.lastName < b.lastName) return -1;
            if (a.lastName > b.lastName) return 1;
            return 0;
        });
    }

    public static sortbyEmail(people: any[]): any[] {
        return people.sort((a: any, b: any) => {
            if (a.email < b.email) return -1;
            if (a.email > b.email) return 1;
            return 0;
        });
    }

    public static isOutOfDateOrder(sectionItems: any[], itemId: number) {
        const itemIndex = sectionItems.findIndex((i) => i.id === itemId);
        if (itemIndex === -1) return false;

        if (sectionItems[itemIndex].isArchived) return false;

        if (itemIndex > 0) {
            // Not the first item so check the previous ones
            for (let i = 0; i < itemIndex; i += 1) {
                if (sectionItems[i].isArchived) return false;
                if (
                    this.areOutOfDateOrder(
                        this.getLowerCompareDate(sectionItems[i]),
                        this.getLowerCompareDate(sectionItems[itemIndex])
                    )
                )
                    return true;
            }
        }

        if (itemIndex < sectionItems.length - 1) {
            // Not the last item so check the later ones
            for (let i = itemIndex + 1; i < sectionItems.length; i += 1) {
                if (sectionItems[i].isArchived) return false;
                if (
                    this.areOutOfDateOrder(
                        this.getUpperCompareDate(sectionItems[itemIndex]),
                        this.getUpperCompareDate(sectionItems[i])
                    )
                )
                    return true;
            }
        }

        return false;
    }

    public static getLowerCompareDate(item: any): Date | null {
        if (item.eventType === 0 || item.eventType === 1 || item.eventType === 2) {
            return item.startDateAndTime ? new Date(item.startDateAndTime) : null; // Start date / time
        }

        if (item.eventType === 3 || item.eventType === 4 || item.eventType === 5) {
            return item.endDateAndTime ? new Date(item.endDateAndTime) : null; // Due date
        }

        return null;
    }

    public static getUpperCompareDate(item: any): Date | null {
        if (item.eventType === 0 || item.eventType === 1 || item.eventType === 2) {
            return item.endDateAndTime ? new Date(item.endDateAndTime) : null; // Start date / time
        }
        if (item.eventType === 3 || item.eventType === 4 || item.eventType === 5) {
            return item.endDateAndTime ? new Date(item.endDateAndTime) : null; // Due date
        }

        return null;
    }

    public static areOutOfDateOrder(dateA: Date | null, dateB: Date | null): boolean {
        if (dateA === null || dateB === null) {
            return false;
        }

        if (
            (dateA.getHours() === 0 && dateA.getMinutes() === 0) ||
            (dateB.getHours() === 0 && dateB.getMinutes() === 0)
        ) {
            // Only compare the date portions
            dateA.setHours(0, 0, 0, 0);
            dateB.setHours(0, 0, 0, 0);
        }

        const outOfOrder = dateA > dateB;
        return outOfOrder;
    }

    public static insertEmailSoftBreaks(emailAddress: string) {
        const withBreaks = emailAddress.replace("@", "&#8203;@");
        return withBreaks.replace(".", "&#8203;.");
    }

    public static isValidEmail(value: string): boolean {
        const pattern =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape
        return pattern.test(value);
    }

    public static resourceIsPDF(resource: any): boolean {
        return resource.fileType === "application/pdf";
    }

    public static resourceIsImage(resource: any): boolean {
        return resource.fileType && resource.fileType.startsWith("image/");
    }

    public static resourceIsUploadedVideo(resource: any): boolean {
        return resource.fileType && resource.fileType.startsWith("video/");
    }

    public static resourceIsExternalVideo(resource: any): boolean {
        return (
            resource.youTubeId.length > 0 ||
            resource.vimeoId.length > 0 ||
            resource.loomId.length > 0
        );
    }

    public static timezoneFullDescription(timeZone: any): string {
        return `(${timeZone.utcOffset}) ${timeZone.code} (${timeZone.description})`;
    }
}
