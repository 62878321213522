
import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import store from "@/store";

@Component({
    components: {},
})
export default class ConfirmUserEmailAddressView extends Vue {
    confirming = true;

    confirmed = true;

    created() {
        store.dispatch("signOut");

        const { userId } = this.$route.query;
        const { code } = this.$route.query;

        console.log(this.$route.query);

        axios
            .post(`${process.env.VUE_APP_API_BASE_URL}/v1/users/${userId}/confirmemailaddress`, {
                emailVerificationCode: code,
            })
            .then(() => {
                this.confirmed = true;
                this.confirming = false;
            })
            .catch(() => {
                this.confirmed = false;
                this.confirming = false;
            });
    }
}
