
import Vue, { defineComponent } from "vue";
import Utilities from "@/utils/Utilities";

export default defineComponent({
    name: "ProgrammeDetailsPanel",
    components: {},
    props: {
        programme: {
            type: Object,
            required: true,
        },
        learnerVersion: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
});
