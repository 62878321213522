
import { defineComponent } from "vue";
import Vue from "vue";
import ProgrammeCard from "../../components/programmes/ProgrammeCard.vue";
import EventCard from "../../components/events/EventCard.vue";
import SubscriptionRequired from "../../components/SubscriptionRequired.vue";
import store from "@/store/index";
import Utilities from "@/utils/Utilities";
import LogUtils from "@/utils/LogUtils";
import moment from "moment";
import { UserProgrammePermissions } from "@/contracts/v1/responses/UserProgrammePermissions";
import { Programme } from "@/contracts/v1/responses/Programme";

export default defineComponent({
    components: {
        "programme-card": ProgrammeCard,
        "event-card": EventCard,
        "subscription-required": SubscriptionRequired,
    },
    data() {
        return {
            programmes: [] as Programme[],
            events: [],
            clientExists: false,
            programmePermissions: {} as UserProgrammePermissions,
            clientPermissions: null,
            activeTenantSubscription: Utilities.userHasActiveTenantSubscription(store.state.user),
            userIsTenantAdmin: Utilities.userIsTenantAdmin(store.state.user),
            error: null,
            loading: true,
            showArchived: false,
            filter: "",
        };
    },
    created() {
        const vm = this;
        vm.loading = true;
        Vue.prototype.$http
            .get("/v1/programmes?includeArchived=true")
            .then((res) => {
                console.log("Programmes", res);
                vm.programmePermissions = JSON.parse(res.headers["x-gp-permissions"]).programme;
                vm.programmes = res.data;
                vm.error = null;
                vm.loading = false;
                console.log(res.data);
            })
            .catch((err) => {
                LogUtils.Error("Could not load programmes", err, "ProgrammesView");
                vm.error = "Could not load programmes...";
                vm.loading = false;
            });

        Vue.prototype.$http
            .get("/v1/events?includeArchived=true&standaloneOnly=true")
            .then((res) => {
                console.log("Events", res);
                vm.events = res.data;
            })
            .catch((err) => {
                LogUtils.Error("Could not load events", err, "ProgrammesView");
                vm.error = "Could not load events...";
            });

        Vue.prototype.$http
            .get("/v1/clients")
            .then((clients) => {
                vm.clientExists = clients.data.length > 0;
                vm.clientPermissions = JSON.parse(clients.headers["x-gp-permissions"]).client;
            })
            .catch(() => {
                this.clientExists = false;
            });
    },
    computed: {
        attendeeProgrammes() {
            const vm = this;
            return vm.programmes
                .filter(
                    (programme) =>
                        programme.attending &&
                        (Utilities.containsFilterString(programme.title, this.filter) ||
                            Utilities.containsFilterString(programme.client.name, this.filter))
                )
                .sort((programme1, programme2) => (programme1.id > programme2.id ? -1 : 1));
        },
        tenantProgrammes() {
            const vm = this;
            return vm.programmes
                .filter(
                    (programme) =>
                        !programme.attending &&
                        (vm.showArchived || !programme.isArchived) &&
                        (Utilities.containsFilterString(programme.title, this.filter) ||
                            Utilities.containsFilterString(programme.client.name, this.filter))
                )
                .sort((programme1, programme2) => (programme1.id > programme2.id ? -1 : 1));
        },
        attendeeEvents() {
            const attendeeEvts = this.events
                .filter(
                    (event) =>
                        event.programmeId === null &&
                        event.attending &&
                        (Utilities.containsFilterString(event.title, this.filter) ||
                            Utilities.containsFilterString(event.client.title, this.filter))
                )
                .sort((e1, e2) => (e1.startDateAndTime > e2.startDateAndTime ? -1 : 1));
            console.log("attendeeEvents", attendeeEvts);
            return attendeeEvts;
        },
        facilitatorEvents() {
            const vm = this;
            const facilitatorEvts = vm.events
                .filter(
                    (event) =>
                        event.programmeId === null &&
                        !event.attending &&
                        (vm.showArchived || !event.isArchived) &&
                        (Utilities.containsFilterString(event.title, this.filter) ||
                            Utilities.containsFilterString(event.client.title, this.filter))
                )
                .sort((e1, e2) => (e1.startDateAndTime > e2.startDateAndTime ? -1 : 1));
            console.log("facilitatorEvents", facilitatorEvts);
            return facilitatorEvts;
        },
        archivedTenantProgrammesExist() {
            return (
                this.programmes.filter((programme) => !programme.attending && programme.isArchived)
                    .length > 0
            );
        },
        archivedFacilitatorEventsExist() {
            return (
                this.events.filter(
                    (event) => event.programmeId === null && !event.attending && event.isArchived
                ).length > 0
            );
        },
    },
    methods: {
        filterFormatter(value) {
            return value.toLowerCase();
        },
        getCalendarLink(date) {
            return date ? `calendar/index?date=${moment(date).format("YYYY-MM-DD")}` : "";
        },
        urlProgrammeInfo(programmeId) {
            return `programme/info/${programmeId}`;
        },
        urlProgrammeDetails(programmeId) {
            return `programme/details/${programmeId}`;
        },
        urlProgrammeEdit(programmeId) {
            return `programme/edit/${programmeId}`;
        },
        urlClientDetails(clientId) {
            return `client/details/${clientId}`;
        },
        urlTenantLogo(tenantLogoId) {
            return `/api/avatar/${tenantLogoId}/byid`;
        },
    },
});
