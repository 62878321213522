
import { Component, Emit, Vue } from "vue-property-decorator";
import axios from "axios";
import { Route } from "vue-router";
import TheEnvironmentIndicator from "./TheEnvironmentIndicator.vue";
import UserAvatar from "./UserAvatar.vue";
import TheNotificationsDialog from "./TheNotificationsDialog.vue";
import EventBus from "../eventBus";
import UserUnreadMessagesBadge from "./UserUnreadMessagesBadge.vue";
import Utilities from "@/utils/Utilities";

@Component({
    components: {
        "the-environment-indicator": TheEnvironmentIndicator,
        "user-avatar": UserAvatar,
        "the-notifications-dialog": TheNotificationsDialog,
        "user-unread-messages-badge": UserUnreadMessagesBadge,
    },
})
export default class TheTopBar extends Vue {
    private readonly NotificationsRefreshInterValSeconds: number = 30;

    private readonly NewBuildCheckInterValSeconds: number = 60; // make this longer

    public signedIn = false;

    userId = "";

    firstName = "";

    lastName = "";

    showAvatar = true;

    notifications = [];

    notificationsLastUpdated: Date | null = null;

    newBuildLastChecked: Date | null = null;

    menuVisible = false;

    profileLinks = [
        {
            icon: "mdi-account-circle",
            href: "#",
            title: "Profile",
            click: () => {
                this.$router.push({ name: "Profile" });
            },
        },
        // {
        //     icon: 'settings',
        //     href: '#',
        //     title: 'Settings',
        //     click: (e) => {
        //     console.log(e);
        //     }
        // },
        {
            icon: "mdi-exit-to-app",
            href: "#",
            title: "Sign Out",
            click: () => {
                this.doSignOut();
            },
        },
    ];

    doSignOut() {
        this.$store.dispatch("signOut");
        this.$router.push({ name: "SignIn" });
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    @Emit() navIconClicked() {}

    get notificationsCount() {
        return this.notifications.length;
    }

    get notificationsCountText() {
        return this.notifications.length > 99 ? "99+" : `${this.notifications.length}`;
    }

    get userName() {
        return `${this.firstName} ${this.lastName}`;
    }

    get avatarUrl() {
        return `${process.env.VUE_APP_API_BASE_URL}/v1/users/${this.userId}/avatar`;
    }

    get environmentName() {
        return process.env.VUE_APP_GIRAFFEPAD_ENV;
    }

    loadNotifications(forceUpdate = false) {
        const now = new Date();
        const msSinceLastUpdated = this.notificationsLastUpdated
            ? now.valueOf() - this.notificationsLastUpdated.valueOf()
            : 0;
        // console.log("loadNotifications() called:", forceUpdate, this.notificationsLastUpdated, msSinceLastUpdated);
        if (
            forceUpdate ||
            !this.notificationsLastUpdated ||
            msSinceLastUpdated > this.NotificationsRefreshInterValSeconds * 1000
        ) {
            Vue.prototype.$http
                .get("/v1/notifications")
                .then((res: any) => {
                    // console.log("Notifications", res.data);
                    this.notifications = res.data;
                    this.notificationsLastUpdated = now;
                })
                .catch((err: any) => {
                    console.log("Could not load notifications", err);
                });
        }
    }

    checkForNewVersion() {
        const now = new Date();
        const msSinceLastCheck = this.newBuildLastChecked
            ? now.valueOf() - this.newBuildLastChecked.valueOf()
            : 0;
        // console.log("milliseconds since last check: ", msSinceLastCheck);
        if (
            !this.newBuildLastChecked ||
            msSinceLastCheck > this.NewBuildCheckInterValSeconds * 1000
        ) {
            console.log("checking for new version...");
            axios
                .get("/version.json", {
                    headers: {
                        "cache-control": "max-age=0",
                    },
                })
                .then((res) => {
                    console.log(
                        `This version:  ${process.env.VUE_APP_GIRAFFEPAD_BUILD}, server version ${res.data.version}`
                    );
                    if (res.data.version !== process.env.VUE_APP_GIRAFFEPAD_BUILD) {
                        // Show update to latest version dialog
                        Utilities.gpSuccessAlert(
                            this.$bvModal,
                            "A new version of GiraffePad is available.",
                            "New version available",
                            "Update"
                        ).then(() => {
                            this.getLatestVersion();
                        });
                    } else {
                        this.newBuildLastChecked = now;
                    }
                });
        }
    }

    mounted() {
        if (this.$store.state.user) {
            this.signedIn = true;
            this.firstName = this.$store.state.user.firstName;
            this.lastName = this.$store.state.user.lastName;
            this.userId = this.$store.state.user.userId;
            this.checkForNewVersion();
        }

        EventBus.$on("USER_SIGNED_IN", (user: any) => {
            this.signedIn = true;
            this.firstName = user.firstName;
            this.lastName = user.lastName;
            this.userId = user.userId;
            this.loadNotifications(true);
        });

        EventBus.$on("USER_SIGNED_OUT", () => {
            this.signedIn = false;
            this.firstName = "";
            this.lastName = "";
            this.userId = "";
            this.notifications = [];
        });

        EventBus.$on("USER_AVATAR_UPDATED", () => {
            this.showAvatar = false;
            this.$nextTick(() => {
                this.showAvatar = true;
            });
        });

        EventBus.$on("ROUTE_CHANGED", (to: Route) => {
            this.menuVisible = false;
            if (to.name === "Programmes" || to.name === "Clients" || to.name === "Home") {
                this.checkForNewVersion();
                if (this.signedIn === true) {
                    setTimeout(() => {
                        this.loadNotifications();
                    }, 2000);
                }
            }
        });
    }

    created() {
        this.loadNotifications(true);
    }

    beforeDestroy() {
        console.log("[TheTopBar] Removing event handlers");
        EventBus.$off("USER_SIGNED_IN");
        EventBus.$off("USER_SIGNED_OUT");
        EventBus.$off("USER_AVATAR_UPDATED");
    }

    showNotifications() {
        console.log("Called showNotifications()");
        if (this.notifications.length > 0) {
            this.$bvModal.show("the-notifications-dialog");
        }
    }

    onAllNotificationsDismissed() {
        this.loadNotifications();
    }

    onNotificationDismissed(notificationId: number) {
        const index = this.notifications.findIndex(
            (notification) => notification.id === notificationId
        );
        if (index > -1) {
            this.notifications.splice(index, 1);
        }
    }

    getLatestVersion() {
        window.location.reload();
    }
}
