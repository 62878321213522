<template>
    <base-nav
        v-if="signedIn && $store.state.menuVisible"
        container-classes="container-fluid"
        class="navbar-top border-bottom navbar-expand"
        :class="{ 'bg-secondary navbar-dark': type === 'default' }"
    >
        <!-- Search form -->
        <!-- <b-form class="navbar-search form-inline mr-sm-3"
          :class="{'navbar-search-light': type === 'default', 'navbar-search-dark': type === 'light'}"
          id="navbar-search-main">
      <b-form-group class="mb-0">
        <b-input-group class="input-group-alternative input-group-merge">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fas fa-search"></i></span>
          </div>
          <b-form-input placeholder="Search" type="text"> </b-form-input>
        </b-input-group>
      </b-form-group>
      <button type="button" class="close" data-action="search-close" data-target="#navbar-search-main" aria-label="Close">
        <span aria-hidden="true">×</span>
      </button>
    </b-form> -->
        <!-- Navbar links -->
        <b-navbar-nav class="align-items-center ml-md-auto">
            <!-- This item dont have <b-nav-item> because they add class 'nav-link' which is not needed here -->

            <the-environment-indicator
                :environment-name="environmentName"
                class="mr-3"
            ></the-environment-indicator>

            <li class="nav-item d-xl-none">
                <!-- Sidenav toggler -->
                <div
                    class="pr-3 sidenav-toggler"
                    :class="{
                        active: $sidebar.showSidebar,
                        'sidenav-toggler-dark': type === 'default',
                        'sidenav-toggler-light': type === 'light',
                    }"
                    @click="toggleSidebar"
                    data-tour="sidenav-toggler"
                >
                    <div class="sidenav-toggler-inner">
                        <i class="sidenav-toggler-line"></i>
                        <i class="sidenav-toggler-line"></i>
                        <i class="sidenav-toggler-line"></i>
                    </div>
                </div>
            </li>

            <b-nav-item :to="{ name: 'Messages' }" data-tour="navbar-messages">
                <b-icon icon="chat-fill"></b-icon>
                <user-unread-messages-badge class="header-badge"></user-unread-messages-badge>
            </b-nav-item>

            <b-nav-item
                :disabled="notificationsCount === 0"
                @click.prevent="showNotifications()"
                data-tour="navbar-notifications"
            >
                <b-icon icon="bell-fill"></b-icon>
                <b-badge v-if="notificationsCount > 0" class="header-badge" pill variant="info">{{
                    notificationsCountText
                }}</b-badge>
                <the-notifications-dialog
                    :notifications="notifications"
                    @notification-dismissed="onNotificationDismissed"
                    @all-notifications-dismissed="onAllNotificationsDismissed"
                ></the-notifications-dialog>
            </b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav
            v-if="signedIn"
            class="align-items-center ml-auto ml-md-0"
            data-tour="navbar-usermenu"
        >
            <base-dropdown
                menu-on-right
                class="nav-item"
                tag="li"
                title-tag="a"
                title-classes="nav-link pr-0"
            >
                <a href="#" class="nav-link pr-0" @click.prevent slot="title-container">
                    <b-media no-body class="align-items-center">
                        <user-avatar
                            v-if="showAvatar"
                            size="40"
                            :userId="userId"
                            :firstName="firstName"
                            :lastName="lastName"
                        ></user-avatar>
                        <b-media-body class="ml-2 d-none d-lg-block">
                            <span class="mb-0 text-sm font-weight-bold"
                                >{{ firstName }} {{ lastName }}</span
                            >
                        </b-media-body>
                    </b-media>
                </a>

                <template>
                    <b-dropdown-item
                        :to="{ name: 'EditProfile' }"
                        data-tour="navbar-usermenu-profile"
                    >
                        <i class="ni ni-single-02"></i>
                        <span>My profile</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                        href="https://giraffepad.outseta.com/support/kb"
                        target="_blank"
                        data-tour="navbar-usermenu-kb"
                    >
                        <i class="ni ni-support-16"></i>
                        <span>Help</span>
                    </b-dropdown-item>
                    <div class="dropdown-divider"></div>
                    <b-dropdown-item @click="doSignOut" data-tour="navbar-usermenu-signout">
                        <i class="ni ni-user-run"></i>
                        <span>Sign out</span>
                    </b-dropdown-item>
                </template>
            </base-dropdown>
        </b-navbar-nav>
    </base-nav>
</template>
<script>
// import { CollapseTransition } from "vue2-transitions";
// import { BaseNav, Modal } from "@/components";
import Vue from "vue";
import axios from "axios";
import BaseNav from "@/components/Navbar/BaseNav.vue";
import EventBus from "@/eventBus";
import UserAvatar from "@/components/UserAvatar.vue";
import TheNotificationsDialog from "@/components/TheNotificationsDialog.vue";
import TheEnvironmentIndicator from "@/components/TheEnvironmentIndicator.vue";
import UserUnreadMessagesBadge from "@/components/UserUnreadMessagesBadge.vue";
import Utilities from "@/utils/Utilities";
import LogUtils from "@/utils/LogUtils";

export default {
    components: {
        // CollapseTransition,
        BaseNav,
        // Modal,
        "user-avatar": UserAvatar,
        "the-environment-indicator": TheEnvironmentIndicator,
        "the-notifications-dialog": TheNotificationsDialog,
        "user-unread-messages-badge": UserUnreadMessagesBadge,
    },
    props: {
        type: {
            type: String,
            default: "default", // default|light
            description: "Look of the dashboard navbar. Default (Green) or light (gray)",
        },
    },
    computed: {
        routeName() {
            const { name } = this.$route;
            return this.capitalizeFirstLetter(name);
        },
        environmentName() {
            return process.env.VUE_APP_GIRAFFEPAD_ENV;
        },
        userName() {
            return `${this.firstName} ${this.lastName}`;
        },
        avatarUrl() {
            return `${process.env.VUE_APP_API_BASE_URL}/v1/users/${this.userId}/avatar`;
        },
        notificationsCount() {
            return this.notifications.length;
        },
        notificationsCountText() {
            return this.notifications.length > 99 ? "99+" : `${this.notifications.length}`;
        },
    },
    created() {
        setTimeout(() => {
            this.loadNotifications(true);
        }, 2000);
    },
    mounted() {
        if (this.$store.state.user) {
            this.signedIn = true;
            this.firstName = this.$store.state.user.firstName;
            this.lastName = this.$store.state.user.lastName;
            this.userId = this.$store.state.user.userId;
            this.checkForNewVersion();
        }

        EventBus.$on("USER_SIGNED_IN", (user) => {
            this.signedIn = true;
            this.firstName = user.firstName;
            this.lastName = user.lastName;
            this.userId = user.userId;
            // this.loadNotifications(true);
        });

        EventBus.$on("USER_SIGNED_OUT", () => {
            this.signedIn = false;
            this.firstName = "";
            this.lastName = "";
            this.userId = "";
            this.notifications = [];
        });

        EventBus.$on("USER_AVATAR_UPDATED", () => {
            this.showAvatar = false;
            this.$nextTick(() => {
                this.showAvatar = true;
            });
        });

        EventBus.$on("USER_NAMES_UPDATED", () => {
            this.firstName = this.$store.state.user.firstName;
            this.lastName = this.$store.state.user.lastName;
        });

        EventBus.$on("ROUTE_CHANGED", (to) => {
            console.log("ROUTE_CHANGED received");
            if (this.signedIn === true) {
                if (
                    to.name === "Events" ||
                    to.name === "Programmes" ||
                    to.name === "Clients" ||
                    to.name === "Home"
                ) {
                    this.checkForNewVersion();
                }
                setTimeout(() => {
                    this.loadNotifications();
                }, 2000);
            }
        });
    },
    beforeDestroy() {
        EventBus.$off("USER_SIGNED_IN");
        EventBus.$off("USER_SIGNED_OUT");
        EventBus.$off("USER_AVATAR_UPDATED");
        EventBus.$off("USER_NAMES_UPDATED");
        EventBus.$off("ROUTE_CHANGED");
    },
    data() {
        return {
            NotificationsRefreshInterValSeconds: 30,
            NewBuildCheckInterValSeconds: 60, // make this longer
            userId: "",
            firstName: "",
            lastName: "",
            signedIn: false,
            showAvatar: true,
            activeNotifications: false,
            showMenu: false,
            searchModalVisible: false,
            searchQuery: "",
            notifications: [],
            notificationsLastUpdated: null,
            newBuildLastChecked: null,
        };
    },
    methods: {
        capitalizeFirstLetter(str) {
            return str && str.length > 0 ? str.charAt(0).toUpperCase() + str.slice(1) : "";
        },
        toggleNotificationDropDown() {
            this.activeNotifications = !this.activeNotifications;
        },
        closeDropDown() {
            this.activeNotifications = false;
        },
        toggleSidebar() {
            this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
        },
        hideSidebar() {
            this.$sidebar.displaySidebar(false);
        },
        doSignOut() {
            this.$store.dispatch("signOut");
            this.$router.push({ name: "SignIn" });
        },
        loadNotifications(forceUpdate) {
            console.log("loadNotifications called with forceUpdate =", forceUpdate);
            if (this.signedIn) {
                const now = new Date();
                const msSinceLastUpdated = this.notificationsLastUpdated
                    ? now.valueOf() - this.notificationsLastUpdated.valueOf()
                    : 0;
                // console.log("loadNotifications() called:", forceUpdate, this.notificationsLastUpdated, msSinceLastUpdated);
                if (
                    forceUpdate ||
                    !this.notificationsLastUpdated ||
                    msSinceLastUpdated > this.NotificationsRefreshInterValSeconds * 1000
                ) {
                    console.log("getting notifications ...");
                    Vue.prototype.$http
                        .get("/v1/notifications")
                        .then((res) => {
                            // console.log("Notifications", res.data);
                            this.notifications = res.data;
                            this.notificationsLastUpdated = now;
                        })
                        .catch((err) => {
                            // LogUtils.Error("Could not load notifications", err, "DashboardNavbar");
                            console.log("Could not load notifications", err);
                        });
                }
            }
        },
        showNotifications() {
            console.log("Called showNotifications()");
            if (this.notifications.length > 0) {
                this.$bvModal.show("the-notifications-dialog");
            }
        },
        onAllNotificationsDismissed() {
            this.loadNotifications(true);
        },
        onNotificationDismissed(notificationId) {
            const index = this.notifications.findIndex(
                (notification) => notification.id === notificationId
            );
            if (index > -1) {
                this.notifications.splice(index, 1);
            }
        },
        getLatestVersion() {
            window.location.reload(true);
        },
        checkForNewVersion() {
            const now = new Date();
            const msSinceLastCheck = this.newBuildLastChecked
                ? now.valueOf() - this.newBuildLastChecked.valueOf()
                : 0;
            // console.log("milliseconds since last check: ", msSinceLastCheck);
            if (
                !this.newBuildLastChecked ||
                msSinceLastCheck > this.NewBuildCheckInterValSeconds * 1000
            ) {
                console.log("checking for new version...");
                axios.get(`/version.json?timestamp=${new Date().getTime()}`).then((res) => {
                    console.log(
                        `This version:  ${process.env.VUE_APP_GIRAFFEPAD_BUILD}, server version ${res.data.version}`
                    );
                    if (res.data.version !== process.env.VUE_APP_GIRAFFEPAD_BUILD) {
                        // Show update to latest version dialog
                        Utilities.gpSuccessAlert(
                            this.$bvModal,
                            "A new version of GiraffePad is available.",
                            "New version available",
                            "Update"
                        ).then(() => {
                            this.getLatestVersion();
                        });
                    } else {
                        this.newBuildLastChecked = now;
                    }
                });
            }
        },
    },
};
</script>

<style scoped>
object {
    margin-bottom: -4px;
}
.header-badge.badge {
    position: relative;
    top: -10px;
    left: -5px;
}
</style>
