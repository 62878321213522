
import { defineComponent } from "vue";
export default defineComponent({
    inheritAttrs: false,
    name: "base-input",
    props: {
        required: {
            type: Boolean,
            description: "Whether input is required (adds an asterix *)",
        },
        group: {
            type: Boolean,
            description: "Whether input is an input group (manual override in special cases)",
        },
        alternative: {
            type: Boolean,
            description: "Whether input is of alternative layout",
        },
        label: {
            type: String,
            description: "Input label (text before input)",
        },
        error: {
            type: String,
            description: "Input error (below input)",
        },
        successMessage: {
            type: String,
            description: "Input success message",
            default: "",
        },
        labelClasses: {
            type: String,
            description: "Input label css classes",
            default: "form-control-label",
        },
        inputClasses: {
            type: String,
            description: "Input css classes",
        },
        inputGroupClasses: {
            type: String,
            description: "Input group css classes",
        },
        value: {
            type: [String, Number],
            description: "Input value",
        },
        type: {
            type: String,
            description: "Input type",
            default: "text",
        },
        appendIcon: {
            type: String,
            description: "Append icon (right)",
        },
        prependIcon: {
            type: String,
            description: "Prepend icon (left)",
        },
        rules: {
            type: [String, Array, Object],
            description: "Vee validate validation rules",
            default: "",
        },
        name: {
            type: String,
            description: "Input name (used for validation)",
            default: "",
        },
    },
    data() {
        return {
            focused: false,
        };
    },
    computed: {
        listeners() {
            return {
                ...this.$listeners,
                input: this.updateValue,
                focus: this.onFocus,
                blur: this.onBlur,
            };
        },
        slotData() {
            return {
                focused: this.focused,
                error: this.error,
                ...this.listeners,
            };
        },
        hasIcon() {
            const { append, prepend } = this.$slots;
            return (
                append !== undefined ||
                prepend !== undefined ||
                this.appendIcon !== undefined ||
                this.prependIcon !== undefined ||
                this.group
            );
        },
    },
    methods: {
        updateValue(evt) {
            const { value } = evt.target;
            this.$emit("input", value);
        },
        onFocus(evt) {
            this.focused = true;
            this.$emit("focus", evt);
        },
        onBlur(evt) {
            this.focused = false;
            this.$emit("blur", evt);
        },
    },
});
