
// See also https://vloth.github.io/emails-input/examples.html
import { Component, Vue } from "vue-property-decorator";
import Utilities from "../../utils/Utilities";

@Component({
    components: {},
})
export default class MultipleEmailsInput extends Vue {
    emails: string[] = [];

    inputValue = "";

    pasteSplitPattern = /(?:,| |\n|\s{2,})+/;

    handleInputConfirm(evt) {
        console.log("handleInputConfirm()", evt);
        // if (evt && evt.relatedTarget && evt.relatedTarget.textContent === "Cancel") return;
        const { inputValue } = this;
        if (inputValue) {
            console.log("emails", this.emails);
            this.emails.push(inputValue.trim());
            this.$emit("input", this.emails.join(" "));
        }
        this.inputValue = "";
        this.setFocus();
    }

    // onInput(evt) {
    //     console.log("onInput()", evt);
    //     this.$emit("input");
    // }

    onRemove(index) {
        console.log("onRemove()", index);
        this.emails.splice(index, 1);
        this.$emit("input", this.emails.join(" "));
        this.setFocus();
    }

    onPaste(evt) {
        console.log("onPaste()", evt);
        const chunks = evt.clipboardData.getData("Text").trim().split(this.pasteSplitPattern);
        chunks.forEach((element) => this.emails.push(element.trim()));
        this.$emit("input", this.emails.join(" "));
        this.setFocus();
    }

    getVariant(value: string): string {
        const variant = Utilities.isValidEmail(value) ? "info" : "danger";
        console.log(value, variant);
        return variant;
    }

    setFocus() {
        this.$nextTick(() => {
            if (this.$refs["email-input"]) {
                (this.$refs["email-input"] as HTMLElement).focus();
            }
        });
    }
}
