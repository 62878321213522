
import { Component, Prop, Vue } from "vue-property-decorator";
import TimeLineItem from "@/components/Timeline/TimeLineItem.vue";
import UserAvatar from "@/components/UserAvatar.vue";
import Utilities from "@/utils/Utilities";
import ChannelUnreadMessagesBadge from "@/components/ChannelUnreadMessagesBadge.vue";

@Component({
    components: {
        TimeLineItem,
        UserAvatar,
        ChannelUnreadMessagesBadge,
    },
})
export default class TimeLineItemActivity extends Vue {
    @Prop({ type: Number, required: true }) programmeId!: number;

    @Prop({ required: true }) item!: any;

    @Prop({ type: Boolean, required: true }) userIsAdmin!: boolean;

    @Prop({ type: Boolean, required: true }) userIsLearner!: boolean;

    @Prop({ type: Number, required: false }) learnerId!: number;

    @Prop({ type: Boolean, required: false, default: false }) canDrag!: boolean;

    @Prop({ type: Boolean, required: true }) outOfDateOrder!: boolean;

    @Prop({ required: true }) showStatus!: boolean;

    @Prop({ type: Number, required: false }) fromTab!: number;

    @Prop({ type: Boolean, required: true }) autoInvite!: boolean;

    formattedDateTimeSpan(dateFormat, date1, date2) {
        return Utilities.formattedDateTimeSpan(dateFormat, date1, date2);
    }

    get status(): any {
        return Utilities.getStatusNew(this.item.eventType, this.item.completed, null, null, null);
    }

    isOverdue(activity: any) {
        let overdue = false;
        if (this.learnerId || this.userIsLearner) {
            overdue =
                !(activity.completed === true) &&
                activity.endDateAndTime &&
                Utilities.isInThePast(activity.endDateAndTime);
        }
        return overdue;
    }
}
