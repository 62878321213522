export const routes = {
    v1: {
        clients: {
            create: () => "v1/clients",
            getAll: () => "v1/clients",
            get: (clientId: number) => `/v1/clients/${clientId}`,
            update: (clientId: number) => `/v1/clients/${clientId}`,
            delete: (clientId: number) => `/clients/${clientId}`,
        },
        accounts: {
            update: (tenantId: number) => `v1/accounts/${tenantId}`,
            get: (tenantId: number) => `v1/accounts/${tenantId}`,
            createLogo: (tenantId: number) => `v1/accounts/${tenantId}/logo`,
            getLogo: (tenantId: number) => `v1/accounts/${tenantId}/logo`,
        },
        accountIntegrations_Zoom: {
            get: (tenantId: number, userSpecific?: boolean) =>
                `v1/accounts/${tenantId}/integrations/zoom` + userSpecific
                    ? "?userSpecific=true"
                    : "",
            create: (tenantId: number) => `v1/accounts/${tenantId}/integrations/zoom`,
            delete: (tenantId: number) => `v1/accounts/${tenantId}/integrations/zoom`,
        },
        accountIntegrations_Eventbrite: {
            get: (tenantId: number) => `v1/accounts/${tenantId}/integrations/eventbrite`,
            create: (tenantId: number) => `v1/accounts/${tenantId}/integrations/eventbrite`,
            delete: (tenantId: number) => `v1/accounts/${tenantId}/integrations/eventbrite`,
            getEvents: (tenantId: number) =>
                `v1/accounts/${tenantId}/integrations/eventbrite/events`,
        },
        accountIntegrations_QuickBooks: {
            get: (tenantId: number) => `v1/accounts/${tenantId}/integrations/quickbooks`,
            create: (tenantId: number) => `v1/accounts/${tenantId}/integrations/quickbooks`,
            delete: (tenantId: number) => `v1/accounts/${tenantId}/integrations/quickbooks`,
            getInvoices: (tenantId: number) =>
                `v1/accounts/${tenantId}/integrations/quickbooks/invoices`,
        },
        countries: {
            getAll: () => "v1/countries",
        },
        subscription: {
            update: () => "v1/subscription",
            delete: () => "v1/subscription",
            get: () => "v1/subscription",
        },
        subscriptionPricing: {
            get: () => "v1/subscription/pricing",
        },
        clientProgrammes: {
            getAll: (clientId: number) => `v1/clients/${clientId}/programmes`,
        },
        notifications: {
            getAll: () => "v1/notifications",
            delete: (notificationId: number) => `v1/notifications/${notificationId}`,
            deleteAll: () => "v1/notifications",
        },
        resources: {
            getAll: () => "v1/resources",
            get: (resourceId: number) => `v1/resources/${resourceId}`,
            getBlobResourceUploadUrl: () => "v1/resources/blobuploadurl",
            createWebResource: () => "v1/resources/webresources",
            createAzureBlobResource: () => "v1/resources/azureblobresources",
        },
        resourceOperations: {
            action: () => "v1/resources/operations/action",
            download: () => "v1/resources/operations/download",
            upload: () => "v1/resources/operations/upload",
            getImage: () => "v1/resources/operations/getimage",
            ensureValidBearerToken: () => "v1/resources/operations/ensurevalidbearertoken",
        },
        trainers: {
            create: () => "v1/trainers",
            getAll: () => "v1/trainers",
            get: (trainerId: number) => `v1/trainers/${trainerId}`,
            delete: (trainerId: number) => `v1/trainers/${trainerId}`,
            permissions: (trainerId: number) => `v1/trainers/${trainerId}/permissions`,
        },
        tenants: {
            getAll: () => "v1/tenants",
            delete: (tenantId: number) => `v1/tenants/${tenantId}`,
            get: (tenantId: number) => `v1/tenants/${tenantId}`,
            create: () => "v1/tenants",
            createFromUser: (userId: string) => `v1/tenants/${userId}`,
            getUsage: (tenantId: number) => `v1/tenants/${tenantId}/usage`,
        },
        programmes: {
            getAll: () => "v1/programmes",
            update: (programmeId: number) => `v1/programmes/${programmeId}`,
            delete: (programmeId: number) => `v1/programmes/${programmeId}`,
            get: (programmeId: number) => `v1/programmes/${programmeId}`,
            clone: (programmeId: number) => `v1/programmes/${programmeId}/clone`,
            archive: (programmeId: number) => `v1/programmes/${programmeId}/archive`,
            eventbrite: (programmeId: number) => `v1/programmes/${programmeId}/eventbrite`,
            create: () => "v1/programmes",
            getBannerImageUploadUrl: () => "v1/programmes/bannerimageuploadurl",
            allLearners: (programmeId: number) => `v1/programmes/${programmeId}/alllearners`,
        },
        programmeObjectives: {
            getAll: (programmeId: number) => `v1/programmes/${programmeId}/objectives`,
            update: (programmeId: number, objectiveId: number) =>
                `v1/programmes/${programmeId}/objectives/${objectiveId}`,
            delete: (programmeId: number, objectiveId: number) =>
                `v1/programmes/${programmeId}/objectives/${objectiveId}`,
            get: (programmeId: number, objectiveId: number) =>
                `v1/programmes/${programmeId}/objectives/${objectiveId}`,
            create: (programmeId: number) => `v1/programmes/${programmeId}/objectives`,
            reorder: (programmeId: number) => `v1/programmes/${programmeId}/objectives/reorder`,
        },
        programmeSections: {
            getAll: (programmeId: number) => `v1/programmes/${programmeId}/sections`,
            update: (programmeId: number, sectionId: number) =>
                `v1/programmes/${programmeId}/sections/${sectionId}`,
            delete: (programmeId: number, sectionId: number) =>
                `v1/programmes/${programmeId}/sections/${sectionId}`,
            get: (programmeId: number, sectionId: number) =>
                `v1/programmes/${programmeId}/sections/${sectionId}`,
            allSectionLearners: (programmeId: number, sectionId: number) =>
                `v1/programmes/${programmeId}/sections/${sectionId}/alllearners`,
            create: (programmeId: number) => `v1/programmes/${programmeId}/sections`,
            reorderSections: (programmeId: number) =>
                `v1/programmes/${programmeId}/sections/reorder`,
            reorderSectionItems: (programmeId: number, sectionId: number) =>
                `v1/programmes/${programmeId}/sections/${sectionId}/reorder`,
        },
        programmeTrainerResources: {
            delete: (programmeId: number, resourceId: number) =>
                `v1/programmes/${programmeId}/trainerresources/${resourceId}`,
            create: (programmeId: number) => `v1/programmes/${programmeId}/trainerresources`,
            reorder: (programmeId: number) =>
                `v1/programmes/${programmeId}/trainerresources/reorder`,
            allowDownloads: (programmeId: number, resourceId: number) =>
                `v1/programmes/${programmeId}/trainerresources/${resourceId}/allowdownloads`,
        },
        programmeResources: {
            getAll: (programmeId: number) => `v1/programmes/${programmeId}/resources`,
            update: (programmeId: number, resourceId: number) =>
                `v1/programmes/${programmeId}/resources/${resourceId}`,
            delete: (programmeId: number, resourceId: number) =>
                `v1/programmes/${programmeId}/resources/${resourceId}`,
            get: (programmeId: number, resourceId: number) =>
                `v1/programmes/${programmeId}/resources/${resourceId}`,
            createWebResource: (programmeId: number) => `v1/programmes/${programmeId}/webresources`,
            createAzureBlobResource: (programmeId: number) =>
                `v1/programmes/${programmeId}/azureblobresources`,
            create: (programmeId: number) => `v1/programmes/${programmeId}/resources`,
            reorder: (programmeId: number) => `v1/programmes/${programmeId}/resources/reorder`,
            cllowDownloads: (programmeId: number, resourceId: number) =>
                `v1/programmes/${programmeId}/resources/${resourceId}/allowdownloads`,
        },
        programmeTrainers: {
            create: (programmeId: number) => `v1/programmes/${programmeId}/trainers`,
            getAll: (programmeId: number) => `v1/programmes/${programmeId}/trainers`,
            get: (programmeId: number, trainerId: number) =>
                `v1/programmes/${programmeId}/trainers/${trainerId}`,
            delete: (programmeId: number, trainerId: number) =>
                `v1/programmes/${programmeId}/trainers/${trainerId}`,
        },
        programmeLearners: {
            create: (programmeId: number) => `v1/programmes/${programmeId}/learners`,
            getAllByEvent: (programmeId: number) => `v1/programmes/${programmeId}/learners`,
            get: (programmeId: number, learnerId: number) =>
                `v1/programmes/${programmeId}/learners/${learnerId}`,
            delete: (programmeId: number, learnerId: number) =>
                `v1/programmes/${programmeId}/learners/${learnerId}`,
            allEvents: (programmeId: number, learnerId: number) =>
                `v1/programmes/${programmeId}/learners/${learnerId}/allevents`,
        },
        programmeLearnerObjectives: {
            getAll: (programmeId: number, learnerId: number) =>
                `v1/programmes/${programmeId}/learners/${learnerId}/objectives`,
            update: (programmeId: number, learnerId: number, objectiveId: number) =>
                `v1/programmes/${programmeId}/learners/${learnerId}/objectives/${objectiveId}`,
            delete: (programmeId: number, learnerId: number, objectiveId: number) =>
                `v1/programmes/${programmeId}/learners/${learnerId}/objectives/${objectiveId}`,
            get: (programmeId: number, learnerId: number, objectiveId: number) =>
                `v1/programmes/${programmeId}/learners/${learnerId}/objectives/${objectiveId}`,
            create: (programmeId: number, learnerId: number) =>
                `v1/programmes/${programmeId}/learners/${learnerId}/objectives`,
            reorder: (programmeId: number, learnerId: number) =>
                `v1/programmes/${programmeId}/learners/${learnerId}/objectives/reorder`,
        },
        programmeLearnerResources: {
            getAll: (programmeId: number, learnerId: number) =>
                `v1/programmes/${programmeId}/learners/${learnerId}/resources`,
            update: (programmeId: number, learnerId: number, resourceId: number) =>
                `v1/programmes/${programmeId}/learners/${learnerId}/resources/${resourceId}`,
            delete: (programmeId: number, learnerId: number, resourceId: number) =>
                `v1/programmes/${programmeId}/learners/${learnerId}/resources/${resourceId}`,
            get: (programmeId: number, learnerId: number, resourceId: number) =>
                `v1/programmes/${programmeId}/learners/${learnerId}/resources/${resourceId}`,
            createWebResource: (programmeId: number, learnerId: number) =>
                `v1/programmes/${programmeId}/learners/${learnerId}/webresources`,
            createAzureBlobResource: (programmeId: number, learnerId: number) =>
                `v1/programmes/${programmeId}/learners/${learnerId}/azureblobresources`,
        },
        timeZones: {
            getAll: (dateTime: string) => `v1/timezones/${dateTime}`,
            get: (dateTime: string) => `v1/timezone/${dateTime}`,
            convert: (dateTime: string) => `v1/timezones/${dateTime}/convert`,
        },
        tasks: {
            run: (taskName: string) => `v1/tasks/${taskName}`,
        },
        users: {
            get: (userId: string) => `v1/users/${userId}`,
            getByEmail: (email: string) => `v1/users/${email}/byemail`,
            confirmEmailAddress: (userId: string) => `v1/users/${userId}/confirmemailaddress`,
            completeRegistration: (userId: string) => `v1/users/${userId}/completeregistration`,
            update: (userId: string) => `v1/users/${userId}`,
            getChatToken: (userId: string) => `v1/users/${userId}/chatToken`,
            sendLearnerRegistrationEmail: (email: string) =>
                `v1/users/${email}/sendlearnerregistrationemail`,
            sendAddressConfirmationEmail: (email: string) =>
                `v1/users/${email}/sendaddressconfirmationemail`,
            forgotPassword: () => "v1/users/forgotpassword",
            resetPassword: (userId: string) => `v1/users/${userId}/resetpassword`,
            tiles: (userId: string) => `v1/users/${userId}/tiles`,
            hideTile: (tileId: number) => `v1/users/{userId}/tiles/${tileId}`,
            createPrivateChatChannel: (userId: string) => `v1/users/${userId}/privateChatChannel`,
        },
        userAvatar: {
            create: (userId: string) => `v1/users/${userId}/avatar`,
            get: (userId: string) => `v1/users/${userId}/avatar`,
        },
        identity: {
            register: () => "v1/identity/register",
            login: () => "v1/identity/login",
            logout: () => "v1/identity/logout",
            refresh: () => "v1/identity/refresh",
        },
        events: {
            getAll: () => `v1/events`,
            update: (eventId: number) => `v1/events/${eventId}`,
            delete: (eventId: number) => `v1/events/${eventId}`,
            get: (eventId: number) => `v1/events/${eventId}`,
            create: () => `v1/events`,
            clone: (eventId: number) => `v1/events/${eventId}/clone`,
            archive: (eventId: number) => `v1/events/${eventId}/archive`,
            getZoomMeeting: (eventId: number) => `v1/events/${eventId}/zoommeeting`,
            patch: (eventId: number) => `v1/events/${eventId}`,
        },
        programmeActivities: {
            create: (programmeId: number) => `v1/programmes/${programmeId}/activities`,
            clone: (programmeId: number, activityId: number) =>
                `v1/programmes/${programmeId}/activities/${activityId}/clone`,
            get: (programmeId: number, activityId: number) =>
                `v1/programmes/${programmeId}/activities/${activityId}`,
            update: (programmeId: number, activityId: number) =>
                `v1/programmes/${programmeId}/activities/${activityId}`,
            delete: (programmeId: number, activityId: number) =>
                `v1/programmes/${programmeId}/activities/${activityId}`,
        },
        programmeQuestionnaires: {
            create: (programmeId: number) => `v1/programmes/${programmeId}/questionnaires`,
            clone: (programmeId: number, questionnaireId: number) =>
                `v1/programmes/${programmeId}/questionnaires/${questionnaireId}/clone`,
            get: (programmeId: number, questionnaireId: number) =>
                `v1/programmes/${programmeId}/questionnaires/${questionnaireId}`,
            update: (programmeId: number, questionnaireId: number) =>
                `v1/programmes/${programmeId}/questionnaires/${questionnaireId}`,
            delete: (programmeId: number, questionnaireId: number) =>
                `v1/programmes/${programmeId}/questionnaires/${questionnaireId}`,
        },
        questionnaireQuestions: {
            getAll: (questionnaireId: number) => `v1/questionnaires/${questionnaireId}/questions`,
            create: (questionnaireId: number) => `v1/questionnaires/${questionnaireId}/questions`,
            get: (questionnaireId: number, questionId: number) =>
                `v1/questionnaires/${questionnaireId}/questions/${questionId}`,
            update: (questionnaireId: number, questionId: number) =>
                `v1/questionnaires/${questionnaireId}/questions/${questionId}`,
            delete: (questionnaireId: number, questionId: number) =>
                `v1/questionnaires/${questionnaireId}/questions/${questionId}`,
            reorder: (questionnaireId: number) =>
                `v1/questionnaires/${questionnaireId}/questions/reorder`,
        },
        questionnaireAnswers: {
            get: (questionnaireId: number) => `v1/questionnaires/${questionnaireId}/answers`,
            download: (questionnaireId: number) =>
                `v1/questionnaires/${questionnaireId}/answers/export`,
        },
        questionnaireLearnerAnswers: {
            get: (questionnaireId: number, learnerId: number) =>
                `v1/questionnaires/${questionnaireId}/learners/${learnerId}/answers`,
            create: (questionnaireId: number, learnerId: number) =>
                `v1/questionnaires/${questionnaireId}/learners/${learnerId}/answers`,
            createAnswer: (questionnaireId: number, learnerId: number, questionId: number) =>
                `v1/questionnaires/${questionnaireId}/learners/${learnerId}/answers/${questionId}`,
            delete: (questionnaireId: number, learnerId: number) =>
                `v1/questionnaires/${questionnaireId}/learners/${learnerId}/answers`,
        },
        eventObjectives: {
            getAll: (eventId: number) => `v1/events/${eventId}/objectives`,
            update: (eventId: number, objectiveId: number) =>
                `v1/events/${eventId}/objectives/${objectiveId}`,
            delete: (eventId: number, objectiveId: number) =>
                `v1/events/${eventId}/objectives/${objectiveId}`,
            get: (eventId: number, objectiveId: number) =>
                `v1/events/${eventId}/objectives/${objectiveId}`,
            create: (eventId: number) => `v1/events/${eventId}/objectives`,
            reorder: (eventId: number) => `v1/events/${eventId}/objectives/reorder`,
        },
        eventInstructions: {
            getAll: (eventId: number) => `v1/events/${eventId}/instructions`,
            update: (eventId: number, instructionId: number) =>
                `v1/events/${eventId}/instructions/${instructionId}`,
            delete: (eventId: number, instructionId: number) =>
                `v1/events/${eventId}/instructions/${instructionId}`,
            get: (eventId: number, instructionId: number) =>
                `v1/events/${eventId}/instructions/${instructionId}`,
            create: (eventId: number) => `v1/events/${eventId}/instructions`,
            reorder: (eventId: number) => `v1/events/${eventId}/instructions/reorder`,
        },
        eventResources: {
            getAll: (eventId: number) => `v1/events/${eventId}/resources`,
            update: (eventId: number, resourceId: number) =>
                `v1/events/${eventId}/resources/${resourceId}`,
            delete: (eventId: number, resourceId: number) =>
                `v1/events/${eventId}/resources/${resourceId}`,
            get: (eventId: number, resourceId: number) =>
                `v1/events/${eventId}/resources/${resourceId}`,
            createWebResource: (eventId: number) => `v1/events/${eventId}/webresources`,
            createAzureBlobResource: (eventId: number) => `v1/events/${eventId}/azureblobresources`,
            create: (eventId: number) => `v1/events/${eventId}/resources`,
            reorder: (eventId: number) => `v1/events/${eventId}/resources/reorder`,
            allowDownloads: (eventId: number, resourceId: number) =>
                `v1/events/${eventId}/resources/${resourceId}/allowdownloads`,
        },
        eventTrainers: {
            create: (eventId: number) => `v1/events/${eventId}/trainers`,
            getAll: (eventId: number) => `v1/events/${eventId}/trainers`,
            get: (eventId: number, trainerId: number) =>
                `v1/events/${eventId}/trainers/${trainerId}`,
            delete: (eventId: number, trainerId: number) =>
                `v1/events/${eventId}/trainers/${trainerId}`,
        },
        eventLearners: {
            create: (eventId: number) => `v1/events/${eventId}/learners`,
            getAll: (eventId: number) => `v1/events/${eventId}/learners`,
            get: (eventId: number, learnerId: number) =>
                `v1/events/${eventId}/learners/${learnerId}`,
            delete: (eventId: number, learnerId: number) =>
                `v1/events/${eventId}/learners/${learnerId}`,
            markCompleted: (eventId: number, learnerId: number) =>
                `v1/events/${eventId}/learners/${learnerId}/completed`,
            clearCompleted: (eventId: number, learnerId: number) =>
                `v1/events/${eventId}/learners/${learnerId}/completed`,
            markAttendanceIntended: (eventId: number, learnerId: number) =>
                `v1/events/${eventId}/learners/${learnerId}/attending`,
            clearAttendanceIntended: (eventId: number, learnerId: number) =>
                `v1/events/${eventId}/learners/${learnerId}/attending`,
        },
        eventLearnerObjectives: {
            getAll: (eventId: number, learnerId: number) =>
                `v1/events/${eventId}/learners/${learnerId}/objectives`,
            update: (eventId: number, learnerId: number, objectiveId: number) =>
                `v1/events/${eventId}/learners/${learnerId}/objectives/${objectiveId}`,
            delete: (eventId: number, learnerId: number, objectiveId: number) =>
                `v1/events/${eventId}/learners/${learnerId}/objectives/${objectiveId}`,
            get: (eventId: number, learnerId: number, objectiveId: number) =>
                `v1/events/${eventId}/learners/${learnerId}/objectives/${objectiveId}`,
            create: (eventId: number, learnerId: number) =>
                `v1/events/${eventId}/learners/${learnerId}/objectives`,
            reorder: (eventId: number, learnerId: number) =>
                `v1/events/${eventId}/learners/${learnerId}/objectives/reorder`,
        },
        eventLearnerActions: {
            getAll: (eventId: number, learnerId: number) =>
                `v1/events/${eventId}/learners/${learnerId}/actions`,
            update: (eventId: number, learnerId: number, actionId: number) =>
                `v1/events/${eventId}/learners/${learnerId}/actions/${actionId}`,
            delete: (eventId: number, learnerId: number, actionId: number) =>
                `v1/events/${eventId}/learners/${learnerId}/actions/${actionId}`,
            get: (eventId: number, learnerId: number, actionId: number) =>
                `v1/events/${eventId}/learners/${learnerId}/actions/${actionId}`,
            create: (eventId: number, learnerId: number) =>
                `v1/events/${eventId}/learners/${learnerId}/actions`,
        },
        eventLearnerFeedback: {
            getAll: (eventId: number, learnerId: number) =>
                `v1/events/${eventId}/learners/${learnerId}/feedback`,
            update: (eventId: number, learnerId: number, feedbackId: number) =>
                `v1/events/${eventId}/learners/${learnerId}/feedback/${feedbackId}`,
            delete: (eventId: number, learnerId: number, feedbackId: number) =>
                `v1/events/${eventId}/learners/${learnerId}/feedback/${feedbackId}`,
            get: (eventId: number, learnerId: number, feedbackId: number) =>
                `v1/events/${eventId}/learners/${learnerId}/feedback/${feedbackId}`,
            create: (eventId: number, learnerId: number) =>
                `v1/events/${eventId}/learners/${learnerId}/feedback`,
        },
        eventLearnerResources: {
            getAll: (eventId: number, learnerId: number) =>
                `v1/events/${eventId}/learners/${learnerId}/resources`,
            update: (eventId: number, learnerId: number, resourceId: number) =>
                `v1/events/${eventId}/learners/${learnerId}/resources/${resourceId}`,
            delete: (eventId: number, learnerId: number, resourceId: number) =>
                `v1/events/${eventId}/learners/${learnerId}/resources/${resourceId}`,
            get: (eventId: number, learnerId: number, resourceId: number) =>
                `v1/events/${eventId}/learners/${learnerId}/resources/${resourceId}`,
            createWebResource: (eventId: number, learnerId: number) =>
                `v1/events/${eventId}/learners/${learnerId}/webresources`,
            createAzureBlobResource: (eventId: number, learnerId: number) =>
                `v1/events/${eventId}/learners/${learnerId}/azureblobresources`,
        },
        chatMessages: {
            delete: (channelId: string, messageId: string) =>
                `v1/channels/${channelId}/messages/${messageId}`,
        },
    },
};
