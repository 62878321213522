
import { defineComponent } from "vue";
import store from "@/store/index";
import Utilities from "@/utils/Utilities";
import ListOptions from "@/utils/ListOptions";
import GoogleMap from "@/components/GoogleMap.vue";
// import TimeZoneFormLabel from "@/components/dateTime/TimeZoneFormLabel.vue";

export default defineComponent({
    name: "EventDetailsPanel",
    components: {
        GoogleMap,
        // TimeZoneFormLabel,
    },
    props: {
        event: {
            type: Object,
            required: true,
        },
        zoomMeetingStartUrl: {
            type: String,
            required: false,
            default: null,
        },
        zoomMeetingJoinUrl: {
            type: String,
            required: false,
            default: null,
        },
        showAttendanceRecording: {
            type: Boolean,
            required: false,
            default: true,
        },
        learnerVersion: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        locationIsHyperlink() {
            return this.event.location.startsWith("https:");
        },
        showMap() {
            return this.event.longitude && this.event.latitude;
        },
        reminderEmailDaysText() {
            return ListOptions.reminderEmailDaysText(this.event.reminderEmailDays, true);
        },
        userTimeZone() {
            return store.state.user.timeZone;
        },
    },
    methods: {
        formattedDateTimeSpan(dateFormat, date1, date2) {
            return Utilities.formattedDateTimeSpan(dateFormat, date1, date2);
        },
    },
});
