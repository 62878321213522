
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class ExternalVideoResourcePreviewDialog extends Vue {
    @Prop({ required: true, default: "" }) title!: string;

    @Prop({ required: false, default: null }) loomId!: string;

    @Prop({ required: false, default: null }) youTubeId!: string;

    @Prop({ required: false, default: null }) vimeoId!: string;

    onHidden() {
        this.$emit("close");
    }

    urlYoutube(youTubeId: string): string {
        return `https://www.youtube-nocookie.com/embed/${youTubeId}?rel=0`;
    }

    urlVimeo(vimeoId: string): string {
        return `https://player.vimeo.com/video/${vimeoId}`;
    }

    urlLoom(loomId: string): string {
        return `https://www.loom.com/embed/${loomId}`;
    }
}
