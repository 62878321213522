import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import initHelpHero from "helphero";
import EventBus from "../eventBus";
import ChatService from "@/services/ChatService";
import Utilities from "@/utils/Utilities";
import LogUtils from "@/utils/LogUtils";
import IpStackService from "@/services/ipStackService";
import { RaygunStatic } from "raygun4js";
declare global {
    interface Window {
        Raygun: RaygunStatic;
    }
}

globalThis.helpHero = initHelpHero("sj8ylHOXpUx");

Vue.use(Vuex);

export default new Vuex.Store({
    strict: true,
    state: {
        user: {
            userId: "",
            firstName: "",
            lastName: "",
            token: "",
            refreshToken: "",
            claims: [
                {
                    type: "",
                    value: "",
                },
            ],
            timeZone: "",
        },
        signedIn: false,
        menuVisible: false,
    },
    mutations: {
        setUser: (state, newUser) => {
            console.log("setUser called with: ", newUser);

            let currentUserTimeZone: string | undefined;
            if (state.user) {
                currentUserTimeZone = state.user.timeZone;
            }

            if (newUser && !newUser.timeZone) {
                console.log("Retaining user time zone", currentUserTimeZone);
                newUser.timeZone = currentUserTimeZone;
            }

            state.user = newUser;

            if (newUser) {
                state.signedIn = true;
                localStorage.setItem("user", JSON.stringify(newUser));
                Vue.prototype.$http.defaults.headers.authorization = `bearer ${newUser.token}`;

                const emailClaim = newUser.claims.find(
                    (claim: any) =>
                        claim.type ===
                        "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"
                );
                const userEmail = emailClaim ? emailClaim.value : "";

                EventBus.$emit("USER_SIGNED_IN", newUser);

                // Vue.prototype.$rollbar.configure({
                //     payload: {
                //         person: {
                //             id: newUser.userId, // required
                //             email: userEmail,
                //         },
                //     },
                // });
                window.Raygun.setUser(newUser.userId, false, userEmail);

                if (Utilities.userTenantId(newUser) !== null) {
                    const userId = `${process.env.VUE_APP_GIRAFFEPAD_ENV}-${newUser.userId}`;
                    const props = {
                        version: process.env.VUE_APP_GIRAFFEPAD_BUILD,
                        firstName: newUser.firstName,
                        email: userEmail,
                        environment: process.env.VUE_APP_GIRAFFEPAD_ENV,
                        isTenantAdmin: Utilities.userIsTenantAdmin(newUser),
                        isTrainer: Utilities.userIsTrainer(newUser),
                        tenantName: Utilities.userTenantName(newUser),
                    };
                    globalThis.helpHero.identify(userId, props);
                }
            } else {
                state.signedIn = false;
                Vue.prototype.$http.defaults.headers.authorization = "";
                // Vue.prototype.$rollbar.configure({
                //     payload: {
                //         person: null,
                //     },
                // });
                window.Raygun.setUser("", false, "");
                window.Raygun.endSession();

                globalThis.helpHero.setOptions({ show: false });

                EventBus.$emit("USER_SIGNED_OUT");
            }
        },
        setUserNames: (state, newNames) => {
            state.user.firstName = newNames.firstName;
            state.user.lastName = newNames.lastName;
            EventBus.$emit("USER_NAMES_UPDATED");
        },
        setUserTimeZone: (state, newTimeZone) => {
            state.user.timeZone = newTimeZone;
            localStorage.setItem("user", JSON.stringify(state.user));
        },
        setMenuVisible: (state, visible) => {
            state.menuVisible = visible;
        },
    },
    actions: {
        showMenu: (context, visible: boolean) =>
            new Promise<void>((resolve) => {
                context.commit("setMenuVisible", visible);
                resolve();
            }),
        // updateTokens: (context, newTokens) => new Promise((resolve) => {
        //     const serialisedUser = localStorage.getItem("user");
        //     if (serialisedUser != null && serialisedUser !== "") {
        //         const user = JSON.parse(serialisedUser);
        //         console.log("User retrieved from local storage: ");
        //         user.token = newTokens.token;
        //         user.refreshToken = newTokens.refreshToken;
        //         context.commit("setUser", user);
        //     } else {
        //         context.commit("setUser", null);
        //     }
        //     resolve();
        // }),
        updateUser: (context, user) =>
            new Promise<void>((resolve) => {
                console.log("Updating user...");
                context.commit("setUser", user);
                resolve();
            }),
        updateUserNames: (context, names) =>
            new Promise<void>((resolve) => {
                console.log("Updating user names...");
                context.commit("setUserNames", names);
                resolve();
            }),
        updateUserTimeZone: (context, timeZone) =>
            new Promise<void>((resolve) => {
                console.log("Updating user time zone...");
                context.commit("setUserTimeZone", timeZone);
                resolve();
            }),
        loadUserFromLocalStorage: (context) =>
            new Promise<void>((resolve) => {
                const serialisedUser = localStorage.getItem("user");
                if (serialisedUser != null && serialisedUser !== "") {
                    const user = JSON.parse(serialisedUser);
                    console.log("User retrieved from local storage: ", user);
                    if (user.userId != null) {
                        IpStackService.getUserTimeZone()
                            .then((timeZone: string) => {
                                if (!user.timeZone || user.timeZone === "") {
                                    user.timeZone = timeZone;
                                }
                            })
                            .finally(() => {
                                if (!user.timeZone || user.timeZone === "") {
                                    user.timeZone = "Europe/London";
                                }
                                context.commit("setUser", user);
                                globalThis.chatService = new ChatService();
                                globalThis.chatService.initialise();
                                resolve();
                            });
                    } else {
                        context.commit("setUser", null);
                        resolve();
                    }
                } else {
                    context.commit("setUser", null);
                    resolve();
                }
            }),
        signIn: (context, credentials) =>
            new Promise<void>((resolve, reject) => {
                // var bodyFormData = new FormData()
                // bodyFormData.set('email', credentials.email)
                // bodyFormData.set('password', credentials.password)
                const userLoginRequest = {
                    email: credentials.email,
                    password: credentials.password,
                };

                axios
                    .post(
                        `${process.env.VUE_APP_API_BASE_URL}/v1/identity/login`,
                        userLoginRequest,
                        {
                            headers: {
                                "Content-Type": "application/json",
                            },
                        }
                    )
                    .then((res: any) => {
                        const user = res.data;
                        // get the user's timezone
                        IpStackService.getUserTimeZone()
                            .then((timeZone: string) => {
                                user.timeZone = timeZone;
                            })
                            .catch(() => {
                                // Try to get the time zone from the browser
                                user.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                            })
                            .finally(() => {
                                if (!user.timeZone || user.timeZone === "") {
                                    user.timeZone = "Europe/London";
                                }
                                context.commit("setUser", user);
                                globalThis.chatService = new ChatService();
                                globalThis.chatService.initialise();
                                resolve();
                            });
                    })
                    .catch((error: any) => {
                        if (error.response) {
                            // Request made and server responded
                            LogUtils.Info(
                                `Failed to sign in as ${userLoginRequest.email}`,
                                error.response.data
                            );
                            context.commit("setUser", null);
                            localStorage.setItem("user", "");
                            reject(error.response.data.errors[0]);
                        } else if (error.request) {
                            // The request was made but no response was received
                            console.log(error.request);
                            reject(new Error("Server didn't respond"));
                        } else {
                            // Something happened in setting up the request that triggered an Error
                            console.log("Error", error.message);
                            reject(new Error(error.message));
                        }
                    });
            }),
        signOut: (context) => {
            context.commit("setUser", null);
            localStorage.setItem("user", "");
            if (globalThis.chatService !== undefined) {
                globalThis.chatService.disconnect();
            }
            // console.log("User removed from local storage.");
        },
        updateToolbar: (context) => {
            if (context.state.user) {
                EventBus.$emit("USER_SIGNED_IN", context.state.user);
            } else {
                EventBus.$emit("USER_SIGNED_OUT");
            }
        },
    },
});
