import { RaygunStatic, RaygunV2 } from "raygun4js";

declare global {
    interface Window {
        Raygun: RaygunStatic;
    }
}

export default class LogUtils {
    public static Error(description: string, err: Error, tag: string) {
        if (process.env.VUE_APP_GIRAFFEPAD_ENV === "development") {
            console.error(err);
            // eslint-disable-next-line
            alert(`ERROR: ${description}`);
        } else {
            window.Raygun.send(
                err,
                [{ description }],
                [`gp-env-${process.env.VUE_APP_GIRAFFEPAD_ENV}`, tag]
            );
        }
    }

    public static Info(description: string, data: any) {
        if (process.env.VUE_APP_GIRAFFEPAD_ENV === "development") {
            console.log(`INFO: ${description}`, data);
            // eslint-disable-next-line
            alert(`INFO: ${description}`);
        } else {
            // Vue.prototype.$rollbar.info(description, data);
        }
    }
}
