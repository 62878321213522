
import { defineComponent } from "vue";

export default defineComponent({
    name: "CloneProgrammeDialog",
    data() {
        return {
            cloning: false,
        };
    },
    computed: {
        otherProgrammes() {
            return this.programmes
                .filter((p) => p.id !== this.sourceProgrammeId)
                .sort((a, b) => (a.id < b.id ? 1 : -1))
                .map((p) => ({ value: p.id, text: p.title }));
        },
    },
    methods: {
        onCancel() {
            this.$bvModal.hide("clone-programme-dialog");
        },
        onOk() {
            this.cloning = true;
            const selectedProgrameId =
                this.programmeId === null ? this.sourceProgrammeId : this.programmeId;
            this.$emit("clone-programme", selectedProgrameId);
        },
    },
});
