
import { defineComponent } from "vue";
import Vue from "vue";
import UserAvatar from "@/components/UserAvatar.vue";
import ClientDetailsPanel from "@/components/clients/ClientDetailsPanel.vue";
import BannerImage from "@/components/BannerImage.vue";
import store from "@/store/index";
import Utilities from "@/utils/Utilities";
import LogUtils from "@/utils/LogUtils";
import { getApiV1 } from "@/services/getApiV1";
import moment from "moment";
import { Client } from "@/contracts/v1/responses/Client";
import { EventSummary } from "@/contracts/v1/responses/EventSummary";
import { UserProgrammePermissions } from "@/contracts/v1/responses/UserProgrammePermissions";

export default defineComponent({
    components: {
        "user-avatar": UserAvatar,
        "banner-image": BannerImage,
        "client-details-panel": ClientDetailsPanel,
    },
    props: {
        clientId: { type: Number, required: true },
    },
    data() {
        return {
            client: {} as Client,
            error: null as string | null,
            loading: true,
            clientPermissions: null,
            programmePermissions: {} as UserProgrammePermissions,
            userIsTenantAdmin: Utilities.userIsTenantAdmin(store.state.user),
        };
    },
    created() {
        const vm = this;
        getApiV1()
            .clients.get(vm.clientId)
            .then((res) => {
                console.log("Client", res);
                vm.clientPermissions = JSON.parse(res.headers["x-gp-permissions"]).client;
                vm.programmePermissions = JSON.parse(res.headers["x-gp-permissions"]).programme;
                vm.client = res.data;
                vm.error = null;
                vm.loading = false;
            })
            .catch((err) => {
                LogUtils.Error("Could not load client details", err, "ClientView");
                vm.error = "Could not load client details...";
            });
    },
    computed: {
        tenantId() {
            return Utilities.userTenantId(store.state.user);
        },
        standaloneEvents() {
            return (this.client?.events as EventSummary[])
                .filter((event) => event.programmeId === null)
                .sort((event1, event2) => {
                    return (
                        new Date(event2.startDateAndTime).getTime() -
                        new Date(event1.startDateAndTime).getTime()
                    );
                });
        },
    },
    methods: {
        programmeSelected(programmeId) {
            console.log(programmeId);
            // Expand the events div (if there is one)
            // eslint-disable-next-line no-undef, @typescript-eslint/no-explicit-any
            (document.querySelector(`#programme${programmeId}`) as any).collapse("toggle");
        },
        fullName(firstName, lastName) {
            return `${firstName} ${lastName}`;
        },
        urlApiAvatar(userId) {
            return `${process.env.VUE_APP_API_BASE_URL}/v1/users/${userId}/avatar`;
        },
        deleteClient() {
            const deleteButton = document.querySelector("#delete-client") as HTMLButtonElement;
            Utilities.gpModalConfirm(
                this.$bvModal,
                "Delete Client",
                "Please confirm that you wish to delete this client. This action cannot be reversed - all data relating to this client will be permanently deleted!",
                "Delete",
                "Cancel",
                true
            ).then((confirmed) => {
                if (confirmed) {
                    deleteButton.disabled = true;
                    Vue.prototype.$http
                        .delete(`/v1/clients/${this.clientId}`)
                        .then(() => {
                            this.$router.push({ name: "Clients" });
                        })
                        .catch((err) => {
                            LogUtils.Error("Could not delete client", err, "ClientView");
                            deleteButton.disabled = false;
                            if (err !== undefined) {
                                Utilities.gpErrorAlert(
                                    this.$bvModal,
                                    "An error occurred while deleting the client.",
                                    "Delete client failed"
                                );
                            }
                        });
                }
            });
        },
        canAddEvents() {
            return this.client!.events.length > 0 && !this.client!.isSampleData;
        },
        getCalendarLink(date) {
            return date ? `calendar/index?date=${moment(date).format("YYYY-MM-DD")}` : "";
        },
        formattedDateTimeSpan(dateFormat, date1, date2) {
            return Utilities.formattedDateTimeSpan(dateFormat, date1, date2);
        },
    },
});
