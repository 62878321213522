
import { defineComponent } from "vue";
import SubscriptionRequired from "../../components/SubscriptionRequired.vue";
import store from "@/store/index";
import Utilities from "@/utils/Utilities";
import LogUtils from "@/utils/LogUtils";
import { getApiV1 } from "@/services/getApiV1";
import { ClientSummary } from "@/contracts/v1/responses/ClientSummary";

export default defineComponent({
    components: {
        "subscription-required": SubscriptionRequired,
    },
    data() {
        return {
            clients: [] as ClientSummary[],
            invoices: [],
            canCreate: false,
            activeTenantSubscription: Utilities.userHasActiveTenantSubscription(store.state.user),
            error: null as string | null,
            loading: true,
        };
    },
    created() {
        const vm = this;
        getApiV1()
            .clients.getAll()
            .then((res) => {
                console.log("Clients", res);
                vm.clients = res.data.sort((client1: ClientSummary, client2: ClientSummary) =>
                    client1.name < client2.name ? -1 : 1
                );
                const clientPermissions = JSON.parse(res.headers["x-gp-permissions"]).client;
                vm.canCreate = clientPermissions.canCreate;
                vm.error = null;
                vm.loading = false;
            })
            .catch((err) => {
                LogUtils.Error("Could not load clients", err, "ClientsView");
                vm.clients = [];
                vm.error = "Could not load clients...";
            });
    },
    computed: {
        navigationDisabled() {
            return !this.activeTenantSubscription;
        },
        orderedClients() {
            return this.clients;
        },
    },
    methods: {
        urlClientDetails(clientId) {
            return `client/details/${clientId}`;
        },
        urlClientEdit(clientId) {
            return `client/edit/${clientId}`;
        },
        fullName(firstName, lastName) {
            return `${firstName} ${lastName}`;
        },
    },
});
