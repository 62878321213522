
import { Component, Vue } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import axios, { AxiosResponse, AxiosError } from "axios";
import LogUtils from "@/utils/LogUtils";
import store from "@/store";

@Component({
    components: {},
})
export default class SignUpView extends Vue {
    private validationObserver!: InstanceType<typeof ValidationObserver>;

    email = "";

    error = "";

    loading = true;

    signingUp = false;

    unregisteredLearner = false;

    unregisteredNonLearner = false;

    existingUser = false;

    tier: string | null = null;

    currency: string | null = null;

    mounted() {
        this.validationObserver = this.$refs.form as InstanceType<typeof ValidationObserver>;
    }

    created() {
        store.dispatch("signOut");

        if (this.$route.query.service) {
            this.currency = this.$route.query.service as string;
        } else {
            this.currency = "USD";
        }

        if (this.$route.query.tier) {
            this.tier = this.$route.query.tier as string;
            this.loading = false;
        } else {
            this.$router.push({ name: "ChoosePlan" });
        }
    }

    // sleep = (milliseconds: number) => new Promise(resolve => setTimeout(resolve, milliseconds))

    async trySignUp() {
        this.signingUp = true;
        const { email } = this;
        this.error = "";
        axios
            .get(`${process.env.VUE_APP_API_BASE_URL}/v1/users/${email}/byemail`)
            .then((response: AxiosResponse) => {
                this.signingUp = false;
                const user = response.data;
                console.log("Existing user: ", user);

                if (!user.hasCompletedRegistration) {
                    if (user.roles.includes("Learner")) {
                        // Learner needs to complete registration
                        Vue.prototype.$http.post(`/v1/users/${email}/sendlearnerregistrationemail`);
                        this.unregisteredLearner = true;
                    } else {
                        // non-learner needs to confirm email address
                        Vue.prototype.$http.post(`/v1/users/${email}/sendaddressconfirmationemail`);
                        this.unregisteredNonLearner = true;
                    }
                } else {
                    // An account with this email address already exists so redirect to Login
                    this.existingUser = true;
                }
            })
            .catch((err: AxiosError) => {
                console.log(err);
                if (err.response?.status === 404) {
                    // Unkown email address provided so proceed with setting up a new tenant
                    this.signingUp = false;
                    this.$router.push({
                        name: "SignUpTenant",
                        params: {
                            emailProp: email,
                            tier: this.tier!,
                            currency: this.currency!,
                        },
                    });
                } else {
                    // Uknkown error
                    LogUtils.Error("Error completing signup", err, "SignUpView");
                    this.error = "An error occurred while completing your signup";
                }
            });
    }
}
