import Vue from "vue";
import { AxiosResponse } from "axios";
import { routes } from "./GpApiRoutes";
import { Client } from "@/contracts/v1/responses/Client";
import { ClientSummary } from "@/contracts/v1/responses/ClientSummary";
import { CreateClientRequest } from "@/contracts/v1/requests/CreateClientRequest";
import { UpdateClientRequest } from "@/contracts/v1/requests/UpdateClientRequest";
// import { UpdateAccountRequest } from "@/contracts/v1/requests/UpdateAccountRequest";
// import { Account } from "@/contracts/v1/responses/Account";
// import { EventbriteIntegration } from "@/contracts/v1/responses/EventbriteIntegration";
// import { CreateEventbriteIntegrationRequest } from "@/contracts/v1/requests/CreateEventbriteIntegrationRequest";
// import { Resource } from "@/contracts/v1/responses/Resource";
// import { Trainer } from "@/contracts/v1/responses/Trainer";
// import { Tenant } from "@/contracts/v1/responses/Tenant";
// import { Programme } from "@/contracts/v1/responses/Programme";
// import { TimeZone } from "@/contracts/v1/responses/TimeZone";
// import { UserSummary } from "@/contracts/v1/responses/UserSummary";
// import { CreateUserAvatarRequest } from "@/contracts/v1/requests/CreateUserAvatarRequest";
// import { Answer } from "@/contracts/v1/responses/Answer";
// import { Question } from "@/contracts/v1/responses/Question";
// import { Item } from "@/contracts/v1/responses/Item";
// import { Objective } from "@/contracts/v1/responses/Objective";
// import { ProgrammeSection } from "@/contracts/v1/responses/ProgrammeSection";
// import { TrainerSummary } from "@/contracts/v1/responses/TrainerSummary";
// import { Event } from "@/contracts/v1/responses/Event";
// import { Instruction } from "@/contracts/v1/responses/Instruction";

const get = <T = any>(route: string): Promise<AxiosResponse<T>> => Vue.prototype.$http.get(route);
const post = <T = any>(route: string, data?: any): Promise<AxiosResponse<T>> =>
    Vue.prototype.$http.post(route, data);
const put = <T = any>(route: string, data?: any): Promise<AxiosResponse<T>> =>
    Vue.prototype.$http.put(route, data);
const del = (route: string): Promise<AxiosResponse<any>> => Vue.prototype.$http.delete(route);

export function getApiV1() /*: ApiV1*/ {
    return {
        clients: {
            create: (request: CreateClientRequest) =>
                post<Client>(routes.v1.clients.create(), request),
            get: (clientId: number) => get<Client>(routes.v1.clients.get(clientId)),
            getAll: () => get<ClientSummary[]>(routes.v1.clients.getAll()),
            update: (clientId: number, request: UpdateClientRequest) =>
                put<Client>(routes.v1.clients.update(clientId), request),
            delete: (clientId: number) => del(routes.v1.clients.delete(clientId)),
        },
        // accounts: {
        //     update: (tenantId: number, request: UpdateAccountRequest) => put<xxxx>(routes.v1.yyyy.update(tenantId: number, request: UpdateAccountRequest)),
        //     get: (tenantId: number) => get<Account>(routes.v1.accounts.get(tenantId)),
        //     createLogo: (tenantId: number, formData: FormData) =>
        //         post(routes.v1.accounts.createLogo(tenantId), formData),
        //     getLogo: (tenantId: number) => get<File>(routes.v1.accounts.getLogo(tenantId)),
        // },
        // accountIntegrations_Zoom: {
        //     get: (tenantId: number, userSpecific?: boolean) =>
        //         get(routes.v1.accountIntegrations_Zoom.get(tenantId, userSpecific)),
        //     create: (tenantId: number) => post(routes.v1.accountIntegrations_Zoom.create(tenantId)),
        //     delete: (tenantId: number) => del(routes.v1.accountIntegrations_Zoom.delete(tenantId)),
        // },
        // accountIntegrations_Eventbrite: {
        //     get: (tenantId: number) =>
        //         get<EventbriteIntegration>(routes.v1.accountIntegrations_Eventbrite.get(tenantId)),
        //     create: (tenantId: number, request: CreateEventbriteIntegrationRequest) =>
        //         post(routes.v1.accountIntegrations_Eventbrite.create(tenantId), request),
        //     delete: (tenantId: number) =>
        //         del(routes.v1.accountIntegrations_Eventbrite.delete(tenantId)),
        //     getEvents: (tenantId: number) =>
        //         get<EventbriteEvents>(routes.v1.accountIntegrations_Eventbrite.get(tenantId)),
        // },
        // accountIntegrations_QuickBooks: {
        //     // get: (tenantId: number) =>
        //     //     get<xxxx>(routes.v1.accountIntegrations_QuickBooks.get(tenantId)),
        //     // create: (tenantId: number) => `v1/accounts/${tenantId}/integrations/quickbooks`,
        //     // delete: (tenantId: number) => `v1/accounts/${tenantId}/integrations/quickbooks`,
        //     // getInvoices: (tenantId: number) =>
        //     //     `v1/accounts/${tenantId}/integrations/quickbooks/invoices`,
        // },
        // countries: {
        //     getAll: () => "v1/countries",
        // },
        // subscription: {
        //     update: () => "v1/subscription",
        //     delete: () => "v1/subscription",
        //     get: () => "v1/subscription",
        // },
        // subscriptionPricing: {
        //     get: () => "v1/subscription/pricing",
        // },
        // clientProgrammes: {
        //     getAll: (clientId: number) => get<Item>(routes.v1.clientProgrammes.getAll(clientId)),
        // },
        // notifications: {
        //     getAll: () => "v1/notifications",
        //     delete: (notificationId: number) => `v1/notifications/${notificationId}`,
        //     deleteAll: () => "v1/notifications",
        // },
        // resources: {
        //     getAll: () => "v1/resources",
        //     get: (resourceId: number) => get<Resource>(routes.v1.resources.get(resourceId)),
        //     getBlobResourceUploadUrl: () => "v1/resources/blobuploadurl",
        //     createWebResource: () => "v1/resources/webresources",
        //     createAzureBlobResource: () => "v1/resources/azureblobresources",
        // },
        // resourceOperations: {
        //     action: () => "v1/resources/operations/action",
        //     download: () => "v1/resources/operations/download",
        //     upload: () => "v1/resources/operations/upload",
        //     getImage: () => "v1/resources/operations/getimage",
        //     ensureValidBearerToken: () => "v1/resources/operations/ensurevalidbearertoken",
        // },
        // trainers: {
        //     create: () => "v1/trainers",
        //     getAll: () => "v1/trainers",
        //     get: (trainerId: number) => get<Trainer>(routes.v1.trainers.get(trainerId)),
        //     delete: (trainerId: number) => `v1/trainers/${trainerId}`,
        //     permissions: (trainerId: number) => `v1/trainers/${trainerId}/permissions`,
        // },
        // tenants: {
        //     getAll: () => "v1/tenants",
        //     delete: (tenantId: number) => `v1/tenants/${tenantId}`,
        //     get: (tenantId: number) => get<Tenant>(routes.v1.tenants.get(tenantId)),
        //     create: () => "v1/tenants",
        //     createFromUser: (userId: string) => `v1/tenants/${userId}`,
        //     getUsage: (tenantId: number) => `v1/tenants/${tenantId}/usage`,
        // },
        // programmes: {
        //     getAll: () => "v1/programmes",
        //     update: (programmeId: number) => put<xxxx>(routes.v1.yyyy.update(programmeId: number)),
        //     delete: (programmeId: number) => `v1/programmes/${programmeId}`,
        //     get: (programmeId: number) => get<Programme>(routes.v1.programmes.get(programmeId)),
        //     clone: (programmeId: number) => `v1/programmes/${programmeId}/clone`,
        //     archive: (programmeId: number) => `v1/programmes/${programmeId}/archive`,
        //     eventbrite: (programmeId: number) => `v1/programmes/${programmeId}/eventbrite`,
        //     create: () => "v1/programmes",
        //     getBannerImageUploadUrl: () => "v1/programmes/bannerimageuploadurl",
        //     allLearners: (programmeId: number) => `v1/programmes/${programmeId}/alllearners`,
        // },
        // programmeObjectives: {
        //     getAll: (programmeId: number) =>
        //         get<Objective>(routes.v1.programmeObjectives.getAll(programmeId)),
        //     update: (programmeId: number, objectiveId: number) => put<xxxx>(routes.v1.programmeObjectives.update(programmeId: number, objectiveId: number)),
        //     delete: (programmeId: number, objectiveId: number) =>
        //         `v1/programmes/${programmeId}/objectives/${objectiveId}`,
        //     get: (programmeId: number, objectiveId: number) =>
        //         `v1/programmes/${programmeId}/objectives/${objectiveId}`,
        //     create: (programmeId: number) => `v1/programmes/${programmeId}/objectives`,
        //     reorder: (programmeId: number) => `v1/programmes/${programmeId}/objectives/reorder`,
        // },
        // programmeSections: {
        //     getAll: (programmeId: number) =>
        //         get<ProgrammeSection>(routes.v1.programmeSections.getAll(programmeId)),
        //     update: (programmeId: number, sectionId: number) => put<xxxx>(routes.v1.yyyy.update(programmeId: number, sectionId: number)),
        //     delete: (programmeId: number, sectionId: number) =>
        //         `v1/programmes/${programmeId}/sections/${sectionId}`,
        //     get: (programmeId: number, sectionId: number) =>
        //         `v1/programmes/${programmeId}/sections/${sectionId}`,
        //     allSectionLearners: (programmeId: number, sectionId: number) =>
        //         `v1/programmes/${programmeId}/sections/${sectionId}/alllearners`,
        //     create: (programmeId: number) => `v1/programmes/${programmeId}/sections`,
        //     reorderSections: (programmeId: number) =>
        //         `v1/programmes/${programmeId}/sections/reorder`,
        //     reorderSectionItems: (programmeId: number, sectionId: number) =>
        //         `v1/programmes/${programmeId}/sections/${sectionId}/reorder`,
        // },
        // programmeTrainerResources: {
        //     delete: (programmeId: number, resourceId: number) =>
        //         `v1/programmes/${programmeId}/trainerresources/${resourceId}`,
        //     create: (programmeId: number) => `v1/programmes/${programmeId}/trainerresources`,
        //     reorder: (programmeId: number) =>
        //         `v1/programmes/${programmeId}/trainerresources/reorder`,
        //     allowDownloads: (programmeId: number, resourceId: number) =>
        //         `v1/programmes/${programmeId}/trainerresources/${resourceId}/allowdownloads`,
        // },
        // programmeResources: {
        //     getAll: (programmeId: number) =>
        //         get<Resource>(routes.v1.programmeResources.getAll(programmeId)),
        //     update: (programmeId: number, resourceId: number) => put<xxxx>(routes.v1.yyyy.update(programmeId: number, resourceId: number)),
        //     delete: (programmeId: number, resourceId: number) =>
        //         `v1/programmes/${programmeId}/resources/${resourceId}`,
        //     get: (programmeId: number, resourceId: number) =>
        //         `v1/programmes/${programmeId}/resources/${resourceId}`,
        //     createWebResource: (programmeId: number) => `v1/programmes/${programmeId}/webresources`,
        //     createAzureBlobResource: (programmeId: number) =>
        //         `v1/programmes/${programmeId}/azureblobresources`,
        //     create: (programmeId: number) => `v1/programmes/${programmeId}/resources`,
        //     reorder: (programmeId: number) => `v1/programmes/${programmeId}/resources/reorder`,
        //     cllowDownloads: (programmeId: number, resourceId: number) =>
        //         `v1/programmes/${programmeId}/resources/${resourceId}/allowdownloads`,
        // },
        // programmeTrainers: {
        //     create: (programmeId: number) => `v1/programmes/${programmeId}/trainers`,
        //     getAll: (programmeId: number) =>
        //         get<TrainerSummary>(routes.v1.programmeTrainers.getAll(programmeId)),
        //     get: (programmeId: number, trainerId: number) =>
        //         `v1/programmes/${programmeId}/trainers/${trainerId}`,
        //     delete: (programmeId: number, trainerId: number) =>
        //         `v1/programmes/${programmeId}/trainers/${trainerId}`,
        // },
        // programmeLearners: {
        //     create: (programmeId: number) => `v1/programmes/${programmeId}/learners`,
        //     getAllByEvent: (programmeId: number) => `v1/programmes/${programmeId}/learners`,
        //     get: (programmeId: number, learnerId: number) =>
        //         `v1/programmes/${programmeId}/learners/${learnerId}`,
        //     delete: (programmeId: number, learnerId: number) =>
        //         `v1/programmes/${programmeId}/learners/${learnerId}`,
        //     allEvents: (programmeId: number, learnerId: number) =>
        //         `v1/programmes/${programmeId}/learners/${learnerId}/allevents`,
        // },
        // programmeLearnerObjectives: {
        //     getAll: (programmeId: number, learnerId: number) =>
        //         `v1/programmes/${programmeId}/learners/${learnerId}/objectives`,
        //     update: (programmeId: number, learnerId: number, objectiveId: number) => put<xxxx>(routes.v1.yyyy.update(programmeId: number, learnerId: number, objectiveId: number)),
        //     delete: (programmeId: number, learnerId: number, objectiveId: number) =>
        //         `v1/programmes/${programmeId}/learners/${learnerId}/objectives/${objectiveId}`,
        //     get: (programmeId: number, learnerId: number, objectiveId: number) =>
        //         `v1/programmes/${programmeId}/learners/${learnerId}/objectives/${objectiveId}`,
        //     create: (programmeId: number, learnerId: number) =>
        //         `v1/programmes/${programmeId}/learners/${learnerId}/objectives`,
        //     reorder: (programmeId: number, learnerId: number) =>
        //         `v1/programmes/${programmeId}/learners/${learnerId}/objectives/reorder`,
        // },
        // programmeLearnerResources: {
        //     getAll: (programmeId: number, learnerId: number) =>
        //         `v1/programmes/${programmeId}/learners/${learnerId}/resources`,
        //     update: (programmeId: number, learnerId: number, resourceId: number) => put<xxxx>(routes.v1.yyyy.update(programmeId: number, learnerId: number, resourceId: number)),
        //     delete: (programmeId: number, learnerId: number, resourceId: number) =>
        //         `v1/programmes/${programmeId}/learners/${learnerId}/resources/${resourceId}`,
        //     get: (programmeId: number, learnerId: number, resourceId: number) =>
        //         `v1/programmes/${programmeId}/learners/${learnerId}/resources/${resourceId}`,
        //     createWebResource: (programmeId: number, learnerId: number) =>
        //         `v1/programmes/${programmeId}/learners/${learnerId}/webresources`,
        //     createAzureBlobResource: (programmeId: number, learnerId: number) =>
        //         `v1/programmes/${programmeId}/learners/${learnerId}/azureblobresources`,
        // },
        // timeZones: {
        //     getAll: (dateTime: string) => get<TimeZone[]>(routes.v1.timeZones.getAll(dateTime)),
        //     get: (dateTime: string) => get<TimeZone>(routes.v1.timeZones.get(dateTime)),
        //     convert: (dateTime: string) => `v1/timezones/${dateTime}/convert`,
        // },
        // tasks: {
        //     run: (taskName: string) => `v1/tasks/${taskName}`,
        // },
        // users: {
        //     get: (userId: string) => get<UserSummary>(routes.v1.users.get(userId)),
        //     getByEmail: (email: string) => `v1/users/${email}/byemail`,
        //     confirmEmailAddress: (userId: string) => `v1/users/${userId}/confirmemailaddress`,
        //     completeRegistration: (userId: string) => `v1/users/${userId}/completeregistration`,
        //     update: (userId: string) => put<xxxx>(routes.v1.yyyy.update(userId: string)),
        //     getChatToken: (userId: string) => `v1/users/${userId}/chatToken`,
        //     sendLearnerRegistrationEmail: (email: string) =>
        //         `v1/users/${email}/sendlearnerregistrationemail`,
        //     sendAddressConfirmationEmail: (email: string) =>
        //         `v1/users/${email}/sendaddressconfirmationemail`,
        //     forgotPassword: () => "v1/users/forgotpassword",
        //     resetPassword: (userId: string) => `v1/users/${userId}/resetpassword`,
        //     tiles: (userId: string) => `v1/users/${userId}/tiles`,
        //     hideTile: (tileId: number) => `v1/users/{userId}/tiles/${tileId}`,
        //     createPrivateChatChannel: (userId: string) => `v1/users/${userId}/privateChatChannel`,
        // },
        // userAvatar: {
        //     create: (userId: string, request: CreateUserAvatarRequest) =>
        //         post(routes.v1.userAvatar.create(userId), request),
        //     get: (userId: string) => get<File>(routes.v1.userAvatar.get(userId)),
        // },
        // identity: {
        //     register: () => "v1/identity/register",
        //     login: () => "v1/identity/login",
        //     logout: () => "v1/identity/logout",
        //     refresh: () => "v1/identity/refresh",
        // },
        // events: {
        //     getAll: () => `v1/events`,
        //     update: (eventId: number) => put<xxxx>(routes.v1.yyyy.update(eventId: number)),
        //     delete: (eventId: number) => `v1/events/${eventId}`,
        //     get: (eventId: number) => get<Event>(routes.v1.events.get(eventId)),
        //     create: () => `v1/events`,
        //     clone: (eventId: number) => `v1/events/${eventId}/clone`,
        //     archive: (eventId: number) => `v1/events/${eventId}/archive`,
        //     getZoomMeeting: (eventId: number) => `v1/events/${eventId}/zoommeeting`,
        //     patch: (eventId: number) => `v1/events/${eventId}`,
        // },
        // programmeActivities: {
        //     create: (programmeId: number) => `v1/programmes/${programmeId}/activities`,
        //     clone: (programmeId: number, activityId: number) =>
        //         `v1/programmes/${programmeId}/activities/${activityId}/clone`,
        //     get: (programmeId: number, activityId: number) =>
        //         `v1/programmes/${programmeId}/activities/${activityId}`,
        //     update: (programmeId: number, activityId: number) => put<xxxx>(routes.v1.yyyy.update(programmeId: number, activityId: number)),
        //     delete: (programmeId: number, activityId: number) =>
        //         `v1/programmes/${programmeId}/activities/${activityId}`,
        // },
        // programmeQuestionnaires: {
        //     create: (programmeId: number) => `v1/programmes/${programmeId}/questionnaires`,
        //     clone: (programmeId: number, questionnaireId: number) =>
        //         `v1/programmes/${programmeId}/questionnaires/${questionnaireId}/clone`,
        //     get: (programmeId: number, questionnaireId: number) =>
        //         `v1/programmes/${programmeId}/questionnaires/${questionnaireId}`,
        //     update: (programmeId: number, questionnaireId: number) => put<xxxx>(routes.v1.yyyy.update(programmeId: number, questionnaireId: number)),
        //     delete: (programmeId: number, questionnaireId: number) =>
        //         `v1/programmes/${programmeId}/questionnaires/${questionnaireId}`,
        // },
        // // questionnaireQuestions: {
        // //     getAll: (questionnaireId: number) => get<xxxx>(routes.v1.questionnaireQuestions.getAll(questionnaireId)),
        // //     create: (questionnaireId: number) => `v1/questionnaires/${questionnaireId}/questions`,
        // //     get: (questionnaireId: number, questionId: number) =>
        // //         `v1/questionnaires/${questionnaireId}/questions/${questionId}`,
        // //     update: (questionnaireId: number, questionId: number) => put<xxxx>(routes.v1.yyyy.update(questionnaireId: number, questionId: number)),
        // //     delete: (questionnaireId: number, questionId: number) =>
        // //         `v1/questionnaires/${questionnaireId}/questions/${questionId}`,
        // //     reorder: (questionnaireId: number) =>
        // //         `v1/questionnaires/${questionnaireId}/questions/reorder`,
        // // },
        // questionnaireAnswers: {
        //     get: (questionnaireId: number) =>
        //         get<Question>(routes.v1.questionnaireAnswers.get(questionnaireId)),
        // },
        // questionnaireLearnerAnswers: {
        //     get: (questionnaireId: number, learnerId: number) =>
        //         `v1/questionnaires/${questionnaireId}/learners/${learnerId}/answers`,
        //     create: (questionnaireId: number, learnerId: number) =>
        //         `v1/questionnaires/${questionnaireId}/learners/${learnerId}/answers`,
        //     createAnswer: (questionnaireId: number, learnerId: number, questionId: number) =>
        //         `v1/questionnaires/${questionnaireId}/learners/${learnerId}/answers/${questionId}`,
        //     delete: (questionnaireId: number, learnerId: number) =>
        //         `v1/questionnaires/${questionnaireId}/learners/${learnerId}/answers`,
        // },
        // eventObjectives: {
        //     getAll: (eventId: number) => get<xxxx>(routes.v1.yyyy.getAll(eventId)),
        //     update: (eventId: number, objectiveId: number) => put<xxxx>(routes.v1.yyyy.update(eventId: number, objectiveId: number)),
        //     delete: (eventId: number, objectiveId: number) =>
        //         `v1/events/${eventId}/objectives/${objectiveId}`,
        //     get: (eventId: number, objectiveId: number) =>
        //         `v1/events/${eventId}/objectives/${objectiveId}`,
        //     create: (eventId: number) => `v1/events/${eventId}/objectives`,
        //     reorder: (eventId: number) => `v1/events/${eventId}/objectives/reorder`,
        // },
        // eventInstructions: {
        //     getAll: (eventId: number) =>
        //         get<Instruction[]>(routes.v1.eventInstructions.getAll(eventId)),
        //     update: (eventId: number, instructionId: number) => put<xxxx>(routes.v1.yyyy.update(eventId: number, instructionId: number)),
        //     delete: (eventId: number, instructionId: number) =>
        //         `v1/events/${eventId}/instructions/${instructionId}`,
        //     get: (eventId: number, instructionId: number) =>
        //         `v1/events/${eventId}/instructions/${instructionId}`,
        //     create: (eventId: number) => `v1/events/${eventId}/instructions`,
        //     reorder: (eventId: number) => `v1/events/${eventId}/instructions/reorder`,
        // },
        // eventResources: {
        //     getAll: (eventId: number) => get<xxxx>(routes.v1.yyyy.getAll(eventId)),
        //     update: (eventId: number, resourceId: number) => put<xxxx>(routes.v1.yyyy.update(eventId: number, resourceId: number)),
        //     delete: (eventId: number, resourceId: number) =>
        //         `v1/events/${eventId}/resources/${resourceId}`,
        //     get: (eventId: number, resourceId: number) =>
        //         `v1/events/${eventId}/resources/${resourceId}`,
        //     createWebResource: (eventId: number) => `v1/events/${eventId}/webresources`,
        //     createAzureBlobResource: (eventId: number) => `v1/events/${eventId}/azureblobresources`,
        //     create: (eventId: number) => `v1/events/${eventId}/resources`,
        //     reorder: (eventId: number) => `v1/events/${eventId}/resources/reorder`,
        //     allowDownloads: (eventId: number, resourceId: number) =>
        //         `v1/events/${eventId}/resources/${resourceId}/allowdownloads`,
        // },
        // eventTrainers: {
        //     create: (eventId: number) => `v1/events/${eventId}/trainers`,
        //     getAll: (eventId: number) => get<xxxx>(routes.v1.yyyy.getAll(eventId)),
        //     get: (eventId: number, trainerId: number) =>
        //         `v1/events/${eventId}/trainers/${trainerId}`,
        //     delete: (eventId: number, trainerId: number) =>
        //         `v1/events/${eventId}/trainers/${trainerId}`,
        // },
        eventLearners: {
            // create: (eventId: number) => `v1/events/${eventId}/learners`,
            // getAll: (eventId: number) => get<xxxx>(routes.v1.yyyy.getAll(eventId)),
            // get: (eventId: number, learnerId: number) =>
            //     `v1/events/${eventId}/learners/${learnerId}`,
            // delete: (eventId: number, learnerId: number) =>
            //     `v1/events/${eventId}/learners/${learnerId}`,
            //     markCompleted: (eventId: number, learnerId: number) =>
            //     `v1/events/${eventId}/learners/${learnerId}/completed`,
            // clearCompleted: (eventId: number, learnerId: number) =>
            //     `v1/events/${eventId}/learners/${learnerId}/completed`,
            markAttendanceIntended: (eventId: number, learnerId: number) =>
                post(routes.v1.eventLearners.markAttendanceIntended(eventId, learnerId)),
            clearAttendanceIntended: (eventId: number, learnerId: number) =>
                del(routes.v1.eventLearners.clearAttendanceIntended(eventId, learnerId)),
        },
        // eventLearnerObjectives: {
        //     getAll: (eventId: number, learnerId: number) =>
        //         `v1/events/${eventId}/learners/${learnerId}/objectives`,
        //     update: (eventId: number, learnerId: number, objectiveId: number) => put<xxxx>(routes.v1.yyyy.update(eventId: number, learnerId: number, objectiveId: number)),
        //     delete: (eventId: number, learnerId: number, objectiveId: number) =>
        //         `v1/events/${eventId}/learners/${learnerId}/objectives/${objectiveId}`,
        //     get: (eventId: number, learnerId: number, objectiveId: number) =>
        //         `v1/events/${eventId}/learners/${learnerId}/objectives/${objectiveId}`,
        //     create: (eventId: number, learnerId: number) =>
        //         `v1/events/${eventId}/learners/${learnerId}/objectives`,
        //     reorder: (eventId: number, learnerId: number) =>
        //         `v1/events/${eventId}/learners/${learnerId}/objectives/reorder`,
        // },
        // eventLearnerActions: {
        //     getAll: (eventId: number, learnerId: number) =>
        //         `v1/events/${eventId}/learners/${learnerId}/actions`,
        //     update: (eventId: number, learnerId: number, actionId: number) => put<xxxx>(routes.v1.yyyy.update(eventId: number, learnerId: number, actionId: number)),
        //     delete: (eventId: number, learnerId: number, actionId: number) =>
        //         `v1/events/${eventId}/learners/${learnerId}/actions/${actionId}`,
        //     get: (eventId: number, learnerId: number, actionId: number) =>
        //         `v1/events/${eventId}/learners/${learnerId}/actions/${actionId}`,
        //     create: (eventId: number, learnerId: number) =>
        //         `v1/events/${eventId}/learners/${learnerId}/actions`,
        // },
        // eventLearnerFeedback: {
        //     getAll: (eventId: number, learnerId: number) =>
        //         `v1/events/${eventId}/learners/${learnerId}/feedback`,
        //     update: (eventId: number, learnerId: number, feedbackId: number) => put<xxxx>(routes.v1.yyyy.update(eventId: number, learnerId: number, feedbackId: number)),
        //     delete: (eventId: number, learnerId: number, feedbackId: number) =>
        //         `v1/events/${eventId}/learners/${learnerId}/feedback/${feedbackId}`,
        //     get: (eventId: number, learnerId: number, feedbackId: number) =>
        //         `v1/events/${eventId}/learners/${learnerId}/feedback/${feedbackId}`,
        //     create: (eventId: number, learnerId: number) =>
        //         `v1/events/${eventId}/learners/${learnerId}/feedback`,
        // },
        // eventLearnerResources: {
        //     getAll: (eventId: number, learnerId: number) =>
        //         `v1/events/${eventId}/learners/${learnerId}/resources`,
        //     update: (eventId: number, learnerId: number, resourceId: number) => put<xxxx>(routes.v1.yyyy.update(eventId: number, learnerId: number, resourceId: number)),
        //     delete: (eventId: number, learnerId: number, resourceId: number) =>
        //         `v1/events/${eventId}/learners/${learnerId}/resources/${resourceId}`,
        //     get: (eventId: number, learnerId: number, resourceId: number) =>
        //         `v1/events/${eventId}/learners/${learnerId}/resources/${resourceId}`,
        //     createWebResource: (eventId: number, learnerId: number) =>
        //         `v1/events/${eventId}/learners/${learnerId}/webresources`,
        //     createAzureBlobResource: (eventId: number, learnerId: number) =>
        //         `v1/events/${eventId}/learners/${learnerId}/azureblobresources`,
        // },
        // chatMessages: {
        //     delete: (channelId: string, messageId: string) =>
        //         `v1/channels/${channelId}/messages/${messageId}`,
        // },
    };
}
