
import { Component, Prop, Vue } from "vue-property-decorator";

import UserAvatar from "@/components/UserAvatar.vue";
import Utilities from "@/utils/Utilities";
import { Notification } from "@/contracts/v1/responses/Notification";

@Component({
    components: {
        "user-avatar": UserAvatar,
    },
})
export default class TheNotificationsDialog extends Vue {
    @Prop({ required: true, default: [] }) notifications!: Notification[];

    localNotifications: Notification[] = [];

    initialise() {
        this.localNotifications = this.notifications.slice();
    }

    getMaxLocalNotificationId(): number {
        const maxLocalNotificationId = this.localNotifications
            .map((notification) => notification.id)
            .reduce((a, b) => Math.max(a, b));
        console.log("Max notification id: ", maxLocalNotificationId);
        return maxLocalNotificationId;
    }

    dismissNotification(notification: any) {
        Vue.prototype.$http
            .delete(`/v1/notifications/${notification.id}`)
            .then(() => {
                console.log("Dismissing notification: ", notification);
                this.localNotifications.splice(this.localNotifications.indexOf(notification), 1);
                this.$emit("notification-dismissed", notification.id);
            })
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            .catch(() => {});
    }

    onDismissAll() {
        console.log("Called onDismissAll()", this.localNotifications.length);
        if (this.localNotifications.length === 0) {
            return;
        }

        Vue.prototype.$http
            .delete(`/v1/notifications?upToId=${this.getMaxLocalNotificationId()}`)
            .then(() => {
                console.log("Dismissing all notifications");
                this.localNotifications = [];
                this.$emit("all-notifications-dismissed");
            })
            .catch((err: any) => {
                console.log("Error getting notifications", err);
            });
    }

    relativeDateTimeString(notification: any): string {
        return Utilities.relativeDateTimeString(notification.systemEventDateTime);
    }
}
