
import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import Utilities from "@/utils/Utilities";

declare global {
    interface Window {
        billflowSettings: any;
    }
}

@Component({
    components: {},
})
export default class ChoosePlanView extends Vue {
    loading = true;

    created() {
        axios
            .get(
                "https://api.ipstack.com/check?access_key=cc59ca4e657fa177e3d8ce07612f05a8&format=1"
            )
            .then((res) => {
                this.loading = false;
                let pricingPageId = "";

                const environmentName = Utilities.getEnvironmentName();
                const tenantCurrency = res.data.currency.code;

                console.log("ChoosePlanView environmentName:", environmentName);
                console.log("ChoosePlanView tenantCurrency:", tenantCurrency);

                if (environmentName === "production") {
                    if (tenantCurrency === "GBP") {
                        pricingPageId = "AN5sUxLNxSA6Ro6TViii"; // Live GBP pricing page id
                    } else if (tenantCurrency === "EUR") {
                        pricingPageId = "OMunUFAmArBMMTTxA2oF"; // Live EUR pricing page id
                    } else if (tenantCurrency === "CAD") {
                        pricingPageId = "FHIOsjMaQryfkZ273x5S"; // Live CAD pricing page id
                    } else if (tenantCurrency === "AUD") {
                        pricingPageId = "U2CiDKuLvlf7sAlReIe3"; // Live AUD pricing page id
                    } else if (tenantCurrency === "NZD") {
                        pricingPageId = "ZcJyxhGrQe6pa7WKg5BF"; // Live NZD pricing page id
                    } else {
                        pricingPageId = "gpkk1y9z6NgKptSKkd"; // Live USD pricing page id
                    }
                } else if (environmentName === "test") {
                    if (tenantCurrency === "GBP") {
                        pricingPageId = "BQbec8kQwHuALQk39xQI"; // Test GBP pricing page id
                    } else {
                        pricingPageId = "lK68ljmifxZpL6JYawiS"; // Test USD pricing page id
                    }
                } else if (environmentName === "development") {
                    if (tenantCurrency === "GBP") {
                        pricingPageId = "ZR9udxyKjVYtjkIfSxft"; // Dev GBP pricing page id
                    } else {
                        pricingPageId = "4cLgS43uM7DHsBDT3Sud"; // Dev USD pricing page id
                    }
                }

                if (pricingPageId !== "") {
                    window.billflowSettings = {
                        billing_page_id: pricingPageId,
                        loader: "1",
                    };
                    console.log("ChoosePlanView using settings:", window.billflowSettings);
                    const s = document.createElement("script");
                    s.src = "https://js.billflow.io/billflow-embed.js";
                    s.async = true;
                    s.type = "text/javascript";
                    const x = document.getElementsByTagName("script")[0];
                    x.parentNode!.insertBefore(s, x);
                }
            });
    }
}
