
import { Component, Vue } from "vue-property-decorator";
import TheGettingStarted from "@/components/home/TheGettingStarted.vue";
import TheKnowledgeBaseLinks from "@/components/home/TheKnowledgeBaseLinks.vue";
import TheRoadmap from "@/components/home/TheRoadmap.vue";

@Component({
    components: {
        TheGettingStarted,
        TheKnowledgeBaseLinks,
        TheRoadmap,
    },
})
export default class HomeView extends Vue {
    loading = true;

    homeViewLoaded() {
        this.loading = false;
    }
}
