
import { defineComponent } from "vue";
import UserAvatar from "@/components/UserAvatar.vue";
import { Trainer } from "@/contracts/v1/responses/Trainer";

export default defineComponent({
    components: {
        "user-avatar": UserAvatar,
    },
    name: "TrainerSelectionDialog",
    props: {
        title: {
            type: String,
            required: true,
        },
        buttonText: {
            type: String,
            required: true,
        },
        trainers: {
            required: true,
            type: Array,
        },
    },
    data() {
        return {
            selectedTrainers: [] as Trainer[],
            isSelectAll: false,
        };
    },
    methods: {
        onCancel() {
            this.selectedTrainers = [];
            this.isSelectAll = false;
            this.$bvModal.hide("trainer-selection-dialog");
        },
        myIncludes(container, value) {
            let returnValue = false;
            const pos = container.indexOf(value);
            if (pos >= 0) {
                returnValue = true;
            }
            return returnValue;
        },
        selectAll() {
            this.isSelectAll = !this.isSelectAll;
            this.selectedTrainers = [];
            if (this.isSelectAll) {
                // Check all
                console.log("Learners ", this.trainers);

                for (let i = 0; i < this.trainers.length; i) {
                    console.log("Selected trainer ", this.trainers[i]);
                    this.selectedTrainers.push(this.trainers[i].id);
                    i += 1;
                }
            }
        },
        onSelected() {
            // Prevent modal from closing
            // console.log(bvModalEvt);
            // bvModalEvt.preventDefault();

            console.log("Called onSelected()");
            const vm = this;
            // this.$bvModal.hide("trainer-selection-dialog");
            vm.$emit(
                "trainers-selected",
                vm.trainers.filter((trainer) => vm.myIncludes(vm.selectedTrainers, trainer.id))
            );
            this.selectedTrainers = [];
            this.isSelectAll = false;
        },
        updateSelectAll() {
            this.$nextTick(() => {
                console.log("updateSelectAll() called");
                console.log("selected", this.selectedTrainers);
                console.log("all", this.trainers);
                if (this.selectedTrainers.length === this.trainers.length) {
                    this.isSelectAll = true;
                } else {
                    this.isSelectAll = false;
                }
            });
        },
        fullName(firstName, lastName) {
            return `${firstName} ${lastName}`;
        },
    },
});
