
import { defineComponent } from "vue";
export default defineComponent({
    name: "base-header",
    props: {
        type: {
            type: String,
            default: "secondary",
            description: "Header background type",
        },
    },
});
