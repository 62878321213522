
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
    components: {},
})
export default class TimeLineAddItemBar extends Vue {
    @Prop({ type: Number, required: true }) programmeId!: number;

    @Prop({ type: Number, required: false, default: null }) sectionId!: number | null;

    @Prop({ type: Boolean, required: true }) hasProSubscription!: boolean;
}
