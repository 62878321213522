import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import Utilities from "@/utils/Utilities";
import SignInView from "@/views/auth/SignInView.vue";
import ChoosePlanView from "@/views/auth/ChoosePlanView.vue";
import SignUpView from "@/views/auth/SignUpView.vue";
import SignUpTenantView from "@/views/auth/SignUpTenantView.vue";
import CompleteUserRegistrationView from "@/views/auth/CompleteUserRegistrationView.vue";
import UpgradeToPaidView from "@/views/auth/UpgradeToPaidView.vue";
import ForgotPasswordView from "@/views/auth/ForgotPasswordView.vue";
import EmailAddressNotConfirmedView from "@/views/auth/EmailAddressNotConfirmedView.vue";
import ConfirmUserEmailAddressView from "@/views/auth/ConfirmUserEmailAddressView.vue";
import ResetPasswordView from "@/views/auth/ResetPasswordView.vue";
import ProgrammeSelfRegistrationView from "@/views/auth/ProgrammeSelfRegistrationView.vue";
import WelcomeView from "@/views/home/WelcomeView.vue";
import HomeView from "@/views/home/HomeView.vue";
import ClientsView from "@/views/clients/ClientsView.vue";
import ClientView from "@/views/clients/ClientView.vue";
import ProgrammesView from "@/views/programmes/ProgrammesView.vue";
import ProgrammeView from "@/views/programmes/ProgrammeView.vue";
import ProgrammeSettingsView from "@/views/programmes/ProgrammeSettingsView.vue";
import ProgrammeAutoInviteView from "@/views/programmes/ProgrammeAutoInviteView.vue";
import EventView from "@/views/events/EventView.vue";
import CalendarView from "@/views/calendar/CalendarView.vue";
import MessagesView from "@/views/chat/MessagesView.vue";
import TeamView from "@/views/team/TeamView.vue";
import ResourcesView from "@/views/resources/ResourcesView.vue";
import FarewellView from "@/views/auth/FarewellView.vue";

import EventBus from "../eventBus";
import { RaygunStatic } from "raygun4js";
declare global {
    interface Window {
        Raygun: RaygunStatic;
    }
}

Vue.use(VueRouter);

export const routes = [
    {
        path: "/",
        name: "Root",
        // component: RootView,
        component: () => import(/* webpackChunkName: "Root" */ "@/views/home/RootView.vue"),
        meta: {
            requiresAuth: true,
            showMenu: false,
        },
    },
    {
        path: "/signin",
        name: "SignIn",
        component: SignInView,
        props: true,
        meta: {
            requiresAuth: false,
            showMenu: false,
        },
    },
    {
        path: "/chooseplan",
        name: "ChoosePlan",
        component: ChoosePlanView,
        meta: {
            requiresAuth: false,
            showMenu: false,
        },
    },
    {
        path: "/signup",
        name: "SignUp",
        component: SignUpView,
        meta: {
            requiresAuth: false,
            showMenu: false,
        },
    },
    {
        path: "/signuptenant",
        name: "SignUpTenant",
        component: SignUpTenantView,
        props: true,
        meta: {
            requiresAuth: false,
            showMenu: false,
        },
    },
    {
        path: "/upgradetopaid",
        name: "UpgradeToPaid",
        component: UpgradeToPaidView,
        meta: {
            requiresAuth: true,
            showMenu: false,
        },
    },
    {
        path: "/confirmlearneremail",
        name: "SignUpLearner",
        component: CompleteUserRegistrationView,
        props: true,
        meta: {
            requiresAuth: false,
            showMenu: false,
        },
    },
    {
        path: "/confirmtraineremail",
        name: "SignUpTrainer",
        component: CompleteUserRegistrationView,
        props: true,
        meta: {
            requiresAuth: false,
            showMenu: false,
        },
    },
    {
        path: "/forgotpassword",
        name: "ForgotPassword",
        component: ForgotPasswordView,
        meta: {
            requiresAuth: false,
            showMenu: false,
        },
    },
    {
        path: "/emailaddressnotconfirmed",
        name: "EmailAddressNotConfirmed",
        component: EmailAddressNotConfirmedView,
        props: true,
        meta: {
            requiresAuth: false,
            showMenu: false,
        },
    },
    {
        path: "/confirmuseremail",
        name: "ConfirmUserEmail",
        component: ConfirmUserEmailAddressView,
        meta: {
            requiresAuth: false,
            showMenu: false,
        },
    },
    {
        path: "/resetpassword",
        name: "ResetPassword",
        component: ResetPasswordView,
        props: true,
        meta: {
            requiresAuth: false,
            showMenu: false,
        },
    },
    {
        path: "/go",
        name: "ProgrammeSelfRegistration",
        component: ProgrammeSelfRegistrationView,
        meta: {
            requiresAuth: false,
            showMenu: false,
        },
    },
    {
        path: "/farewell",
        name: "Farewell",
        component: FarewellView,
        props: true,
        meta: {
            requiresAuth: false,
            showMenu: false,
        },
    },
    {
        path: "/profile/edit",
        name: "EditProfile",
        component: () =>
            import(/* webpackChunkName: "EditProfileView" */ "@/views/profile/EditProfileView.vue"),
        meta: {
            requiresAuth: true,
            showMenu: true,
        },
    },
    {
        path: "/welcome",
        name: "Welcome",
        component: WelcomeView,
        meta: {
            requiresAuth: true,
            showMenu: true,
        },
    },
    {
        path: "/home",
        name: "Home",
        component: HomeView,
        meta: {
            requiresAuth: true,
            requiredRoles: ["Trainer", "TenantAdmin"],
            showMenu: true,
        },
    },
    {
        path: "/clients",
        name: "Clients",
        component: ClientsView,
        meta: {
            requiresAuth: true,
            requiredRoles: ["Trainer", "TenantAdmin"],
            requiredClaims: ["http://giraffepad.com/claims/clientviewpermission"],
            showMenu: true,
        },
    },
    {
        path: "/clients/:clientId",
        name: "Client",
        component: ClientView,
        props: true,
        meta: {
            requiresAuth: true,
            requiredRoles: ["Trainer", "TenantAdmin"],
            requiredClaims: ["http://giraffepad.com/claims/clientviewpermission"],
            showMenu: true,
        },
    },
    {
        path: "/clients/:clientId/edit",
        name: "EditClient",
        component: () =>
            import(/* webpackChunkName: "EditClient" */ "@/views/clients/EditClientView.vue"),
        props: true,
        meta: {
            requiresAuth: true,
            requiredRoles: ["Trainer", "TenantAdmin"],
            requiredClaims: ["http://giraffepad.com/claims/clientviewpermission"],
            showMenu: true,
        },
    },
    {
        path: "/clients/add",
        name: "AddClient",
        component: () =>
            import(/* webpackChunkName: "AddClient" */ "@/views/clients/AddClientView.vue"),
        meta: {
            requiresAuth: true,
            requiredRoles: ["Trainer", "TenantAdmin"],
            requiredClaims: ["http://giraffepad.com/claims/clientviewpermission"],
            showMenu: true,
        },
    },
    {
        path: "/programmes",
        name: "Programmes",
        component: ProgrammesView,
        meta: {
            requiresAuth: true,
            showMenu: true,
        },
    },
    {
        path: "/programmes/:programmeId",
        name: "Programme",
        component: ProgrammeView,
        props: true,
        meta: {
            requiresAuth: true,
            showMenu: true,
        },
    },
    {
        path: "/programmes/:programmeId/settings",
        name: "ProgrammeSettings",
        component: ProgrammeSettingsView,
        props: true,
        meta: {
            requiresAuth: true,
            showMenu: true,
        },
    },
    {
        path: "/programmes/:programmeId/autoinvite",
        name: "ProgrammeAutoInvite",
        component: ProgrammeAutoInviteView,
        props: true,
        meta: {
            requiresAuth: true,
            showMenu: true,
        },
    },
    {
        path: "/programmes/:programmeId/edit",
        name: "EditProgramme",
        component: () =>
            import(
                /* webpackChunkName: "EditProgramme" */ "@/views/programmes/EditProgrammeView.vue"
            ),
        props: true,
        meta: {
            requiresAuth: true,
            showMenu: true,
        },
    },
    {
        path: "/programmes/add",
        name: "AddProgramme",
        component: () =>
            import(
                /* webpackChunkName: "AddProgramme" */ "@/views/programmes/AddProgrammeView.vue"
            ),
        props: true,
        meta: {
            requiresAuth: true,
            showMenu: true,
        },
    },
    {
        path: "/programmes/:programmeId/learnersoverview",
        name: "ProgrammeLearnersOverview",
        component: () =>
            import(
                /* webpackChunkName: "ProgrammeLearners" */ "@/views/programmes/ProgrammeLearnersOverView.vue"
            ),
        props: true,
        meta: {
            requiresAuth: true,
            showMenu: true,
        },
    },
    {
        path: "/programmes/:programmeId/learnersoverview/:learnerId",
        name: "ProgrammeLearnerOverview",
        component: () =>
            import(
                /* webpackChunkName: "ProgrammeLearners" */ "@/views/programmes/ProgrammeLearnersOverView.vue"
            ),
        props: true,
        meta: {
            requiresAuth: true,
            showMenu: true,
        },
    },
    {
        path: "/programmes/:programmeId/learnersoverviewprint",
        name: "ProgrammeLearnersOverviewPrint",
        component: () =>
            import(
                /* webpackChunkName: "ProgrammeLearners" */ "@/views/programmes/ProgrammeLearnersPrintOverView.vue"
            ),
        props: true,
        meta: {
            requiresAuth: true,
            showMenu: false,
        },
    },
    {
        path: "/programmes/:programmeId/learnersoverviewprint/:learnerId",
        name: "ProgrammeLearnerOverviewPrint",
        component: () =>
            import(
                /* webpackChunkName: "ProgrammeLearners" */ "@/views/programmes/ProgrammeLearnersPrintOverView.vue"
            ),
        props: true,
        meta: {
            requiresAuth: true,
            showMenu: false,
        },
    },
    {
        path: "/programmes/:programmeId/learners/:learnerId",
        name: "ProgrammeLearner",
        component: () =>
            import(
                /* webpackChunkName: "ProgrammeLearner" */ "@/views/programmes/ProgrammeLearnerView.vue"
            ),
        props: true,
        meta: {
            requiresAuth: true,
            showMenu: true,
        },
    },
    {
        path: "/programmes/:programmeId/info",
        name: "ProgrammeInfo",
        component: () =>
            import(
                /* webpackChunkName: "ProgrammeInfo" */ "@/views/programmes/ProgrammeInfoView.vue"
            ),
        props: true,
        meta: {
            requiresAuth: true,
            showMenu: true,
        },
    },
    {
        path: "/events",
        redirect: "/programmes",
        name: "Events",
    },
    {
        path: "/events/:eventId",
        name: "Event",
        component: EventView,
        props: true,
        meta: {
            requiredRoles: ["Trainer", "TenantAdmin"],
            requiresAuth: true,
            showMenu: true,
        },
    },
    {
        path: "/events/:eventId/info",
        name: "EventInfo",
        component: () => import(/* webpackChunkName: "Event" */ "@/views/events/EventInfoView.vue"),
        props: true,
        meta: {
            requiredRoles: ["Learner"],
            requiresAuth: true,
            showMenu: true,
        },
    },
    {
        path: "/events/:eventId/learners/:learnerId",
        name: "EventLearner",
        component: () =>
            import(/* webpackChunkName: "Event" */ "@/views/events/EventLearnerView.vue"),
        props: true,
        meta: {
            requiresAuth: true,
            requiredRoles: ["Trainer", "TenantAdmin"],
            showMenu: true,
        },
    },
    {
        path: "/events/add",
        name: "AddEvent",
        component: () =>
            import(/* webpackChunkName: "AddEvent" */ "@/views/events/AddEventView.vue"),
        props: true,
        meta: {
            requiresAuth: true,
            requiredRoles: ["Trainer", "TenantAdmin"],
            showMenu: true,
        },
    },
    {
        path: "/events/:eventId/edit",
        name: "EditEvent",
        component: () =>
            import(/* webpackChunkName: "EditEvent" */ "@/views/events/EditEventView.vue"),
        props: true,
        meta: {
            requiresAuth: true,
            requiredRoles: ["Trainer", "TenantAdmin"],
            showMenu: true,
        },
    },
    {
        path: "/programmes/:programmeId/activities/add",
        name: "AddActivity",
        component: () =>
            import(/* webpackChunkName: "AddActivity" */ "@/views/activities/AddActivityView.vue"),
        props: true,
        meta: {
            requiresAuth: true,
            requiredRoles: ["Trainer", "TenantAdmin"],
            showMenu: true,
        },
    },
    {
        path: "/programmes/:programmeId/activities/:activityId/edit",
        name: "EditActivity",
        component: () =>
            import(
                /* webpackChunkName: "EditActivity" */ "@/views/activities/EditActivityView.vue"
            ),
        props: true,
        meta: {
            requiresAuth: true,
            requiredRoles: ["Trainer", "TenantAdmin"],
            showMenu: true,
        },
    },
    {
        path: "/programmes/:programmeId/activities/:activityId",
        name: "Activity",
        component: () =>
            import(/* webpackChunkName: "Activity" */ "@/views/activities/ActivityView.vue"),
        props: true,
        meta: {
            requiresAuth: true,
            showMenu: true,
        },
    },
    {
        path: "/programmes/:programmeId/activities/:activityId/info",
        name: "ActivityInfo",
        component: () =>
            import(
                /* webpackChunkName: "ActivityInfo" */ "@/views/activities/ActivityInfoView.vue"
            ),
        props: true,
        meta: {
            requiresAuth: true,
            showMenu: true,
        },
    },
    {
        path: "/programmes/:programmeId/activities/:activityId/learners/:learnerId",
        name: "ActivityLearner",
        component: () =>
            import(
                /* webpackChunkName: "ActvityLearner" */ "@/views/activities/ActivityLearnerView.vue"
            ),
        props: true,
        meta: {
            requiresAuth: true,
            requiredRoles: ["Trainer", "TenantAdmin"],
            showMenu: true,
        },
    },
    {
        path: "/programmes/:programmeId/videoactivities/add",
        name: "AddVideoActivity",
        component: () =>
            import(
                /* webpackChunkName: "AddActivity" */ "@/views/microlearning/video/AddVideoActivityView.vue"
            ),
        props: true,
        meta: {
            requiresAuth: true,
            requiredRoles: ["Trainer", "TenantAdmin"],
            showMenu: true,
        },
    },
    {
        path: "/programmes/:programmeId/videoactivities/:activityId/edit",
        name: "EditVideoActivity",
        component: () =>
            import(
                /* webpackChunkName: "EditActivity" */ "@/views/microlearning/video/EditVideoActivityView.vue"
            ),
        props: true,
        meta: {
            requiresAuth: true,
            requiredRoles: ["Trainer", "TenantAdmin"],
            showMenu: true,
        },
    },
    {
        path: "/programmes/:programmeId/videoactivities/:activityId",
        name: "VideoActivity",
        component: () =>
            import(
                /* webpackChunkName: "Activity" */ "@/views/microlearning/video/VideoActivityView.vue"
            ),
        props: true,
        meta: {
            requiresAuth: true,
            showMenu: true,
        },
    },
    {
        path: "/programmes/:programmeId/videoactivities/:activityId/info",
        name: "VideoActivityInfo",
        component: () =>
            import(
                /* webpackChunkName: "ActivityInfo" */ "@/views/microlearning/video/VideoActivityInfoView.vue"
            ),
        props: true,
        meta: {
            requiresAuth: true,
            showMenu: true,
        },
    },
    {
        path: "/programmes/:programmeId/avideoctivities/:activityId/learners/:learnerId",
        name: "VideoActivityLearner",
        component: () =>
            import(
                /* webpackChunkName: "ActvityLearner" */ "@/views/microlearning/video/VideoActivityLearnerView.vue"
            ),
        props: true,
        meta: {
            requiresAuth: true,
            requiredRoles: ["Trainer", "TenantAdmin"],
            showMenu: true,
        },
    },
    {
        path: "/programmes/:programmeId/questionnaires/add",
        name: "AddQuestionnaire",
        component: () =>
            import(
                /* webpackChunkName: "AddQuestionnaire" */ "@/views/questionnaires/AddQuestionnaireView.vue"
            ),
        props: true,
        meta: {
            requiresAuth: true,
            requiredRoles: ["Trainer", "TenantAdmin"],
            showMenu: true,
        },
    },
    {
        path: "/programmes/:programmeId/questionnaires/:questionnaireId/edit",
        name: "EditQuestionnaire",
        component: () =>
            import(
                /* webpackChunkName: "EditQuestionnaire" */ "@/views/questionnaires/EditQuestionnaireView.vue"
            ),
        props: true,
        meta: {
            requiresAuth: true,
            requiredRoles: ["Trainer", "TenantAdmin"],
            showMenu: true,
        },
    },
    {
        path: "/programmes/:programmeId/questionnaires/:questionnaireId",
        name: "Questionnaire",
        component: () =>
            import(
                /* webpackChunkName: "Questionnaire" */ "@/views/questionnaires/QuestionnaireView.vue"
            ),
        props: true,
        meta: {
            requiresAuth: true,
            showMenu: true,
        },
    },
    {
        path: "/programmes/:programmeId/questionnaires/:questionnaireId/preview",
        name: "QuestionnairePreview",
        component: () =>
            import(
                /* webpackChunkName: "Questionnaire" */ "@/views/questionnaires/QuestionnairePreviewView.vue"
            ),
        props: true,
        meta: {
            requiresAuth: true,
            showMenu: false,
        },
    },
    {
        path: "/programmes/:programmeId/questionnaires/:questionnaireId/responses",
        name: "QuestionnaireResponsesSummary",
        component: () =>
            import(
                /* webpackChunkName: "Questionnaire" */ "@/views/questionnaires/QuestionnaireResponsesSummaryView.vue"
            ),
        props: true,
        meta: {
            requiresAuth: true,
            showMenu: true,
        },
    },
    {
        path: "/programmes/:programmeId/questionnaires/:questionnaireId/printresponses",
        name: "QuestionnairePrintResponsesSummary",
        component: () =>
            import(
                /* webpackChunkName: "Questionnaire" */ "@/views/questionnaires/QuestionnairePrintResponsesSummaryView.vue"
            ),
        props: true,
        meta: {
            requiresAuth: true,
            showMenu: false,
        },
    },
    {
        path: "/programmes/:programmeId/questionnaires/:questionnaireId/info",
        name: "QuestionnaireInfo",
        component: () =>
            import(
                /* webpackChunkName: "Questionnaire" */ "@/views/questionnaires/QuestionnaireInfoView.vue"
            ),
        props: true,
        meta: {
            requiresAuth: true,
            showMenu: true,
        },
    },
    {
        path: "/programmes/:programmeId/questionnaires/:questionnaireId/take",
        name: "TakeQuestionnaire",
        component: () =>
            import(
                /* webpackChunkName: "Questionnaire" */ "@/views/questionnaires/TakeQuestionnaireView.vue"
            ),
        props: true,
        meta: {
            requiresAuth: true,
            showMenu: false,
        },
    },
    {
        path: "/programmes/:programmeId/questionnaires/:questionnaireId/learners/:learnerId",
        name: "QuestionnaireLearner",
        component: () =>
            import(
                /* webpackChunkName: "Questionnaire" */ "@/views/questionnaires/QuestionnaireLearnerView.vue"
            ),
        props: true,
        meta: {
            requiresAuth: true,
            requiredRoles: ["Trainer", "TenantAdmin"],
            showMenu: true,
        },
    },
    {
        path: "/programmes/:programmeId/questionnaires/:questionnaireId/learners/:learnerId/printresponses",
        name: "QuestionnairePrintLearnerResponses",
        component: () =>
            import(
                /* webpackChunkName: "Questionnaire" */ "@/views/questionnaires/QuestionnairePrintLearnerResponsesView.vue"
            ),
        props: true,
        meta: {
            requiresAuth: true,
            requiredRoles: ["Trainer", "TenantAdmin"],
            showMenu: false,
        },
    },
    {
        path: "/team",
        name: "Team",
        component: TeamView,
        meta: {
            requiresAuth: true,
            requiredRoles: ["Trainer", "TenantAdmin"],
            requiredClaims: ["http://giraffepad.com/claims/teamviewpermission"],
            showMenu: true,
        },
    },
    {
        path: "/team/addtrainer",
        name: "AddTrainer",
        component: () =>
            import(/* webpackChunkName: "AddTrainer" */ "@/views/team/AddTrainerView.vue"),
        meta: {
            requiresAuth: true,
            requiredRoles: ["Trainer", "TenantAdmin"],
            requiredClaims: ["http://giraffepad.com/claims/teamviewpermission"],
            showMenu: true,
        },
    },
    {
        path: "/team/:memberId",
        name: "TeamMember",
        component: () =>
            import(/* webpackChunkName: "TeamMember" */ "@/views/team/TeamMemberView.vue"),
        props: true,
        meta: {
            requiresAuth: true,
            requiredRoles: ["Trainer", "TenantAdmin"],
            requiredClaims: ["http://giraffepad.com/claims/teamviewpermission"],
            showMenu: true,
        },
    },
    {
        path: "/team/:memberId/permissions",
        name: "TeamMemberPermissions",
        component: () =>
            import(
                /* webpackChunkName: "TeamMember" */ "@/views/team/TeamMemberPermissionsView.vue"
            ),
        props: true,
        meta: {
            requiresAuth: true,
            requiredRoles: ["TenantAdmin"],
            requiredClaims: ["http://giraffepad.com/claims/teamviewpermission"],
            showMenu: true,
        },
    },
    {
        path: "/messages",
        name: "Messages",
        component: MessagesView,
        meta: {
            requiresAuth: true,
            showMenu: true,
        },
    },
    {
        path: "/calendar/:displayDate?",
        name: "Calendar",
        component: CalendarView,
        props: true,
        meta: {
            requiresAuth: true,
            showMenu: true,
        },
    },
    {
        path: "/resources",
        name: "Resources",
        component: ResourcesView,
        meta: {
            requiresAuth: true,
            requiredRoles: ["Trainer", "TenantAdmin"],
            requiredClaims: ["http://giraffepad.com/claims/resourcesaccesslibrarypermission"],
            showMenu: true,
        },
    },
    {
        path: "/settings/account",
        name: "Account",
        component: () =>
            import(/* webpackChunkName: "Account" */ "@/views/settings/account/AccountView.vue"),
        meta: {
            requiresAuth: true,
            requiredRoles: ["TenantAdmin"],
            showMenu: true,
        },
    },
    {
        path: "/settings/account/edit",
        name: "EditAccount",
        component: () =>
            import(
                /* webpackChunkName: "EditAccount" */ "@/views/settings/account/EditAccountView.vue"
            ),
        meta: {
            requiresAuth: true,
            requiredRoles: ["TenantAdmin"],
            showMenu: true,
        },
    },
    {
        path: "/settings/integrations",
        name: "Integrations",
        component: () =>
            import(
                /* webpackChunkName: "Integrations" */ "@/views/settings/integrations/IntegrationsView.vue"
            ),
        meta: {
            requiresAuth: true,
            requiredRoles: ["TenantAdmin"],
            showMenu: true,
        },
    },
    {
        path: "/settings/subscription",
        name: "Subscription",
        component: () =>
            import(
                /* webpackChunkName: "Subscription" */ "@/views/settings/account/BillflowPortalView.vue"
            ),
        meta: {
            requiresAuth: true,
            requiredRoles: ["TenantAdmin"],
            showMenu: true,
        },
    },
    {
        path: "/settings/stripesubscription",
        name: "StripeSubscription",
        component: () =>
            import(
                /* webpackChunkName: "Subscription" */ "@/views/settings/account/StripePortalView.vue"
            ),
        meta: {
            requiresAuth: true,
            requiredRoles: ["TenantAdmin"],
            showMenu: true,
        },
    },
    {
        path: "/settings/subscription/usage",
        name: "Usage",
        component: () =>
            import(/* webpackChunkName: "Subscription" */ "@/views/settings/usage/UsageView.vue"),
        meta: {
            requiresAuth: true,
            requiredRoles: ["TenantAdmin"],
            requireUsageBasedSubscription: true,
            showMenu: true,
        },
    },
    {
        path: "/settings/integrations/zoomredirect",
        name: "ZoomRedirect",
        component: () =>
            import(
                /* webpackChunkName: "Integrations" */ "@/views/settings/integrations/zoom/ZoomRedirectView.vue"
            ),
        meta: {
            requiresAuth: true,
            requiredRoles: ["TenantAdmin"],
            showMenu: true,
        },
    },
    {
        path: "/settings/integrations/eventbriteredirect",
        name: "EventbriteRedirect",
        component: () =>
            import(
                /* webpackChunkName: "Integrations" */ "@/views/settings/integrations/eventbrite/EventbriteRedirectView.vue"
            ),
        meta: {
            requiresAuth: true,
            requiredRoles: ["TenantAdmin"],
            showMenu: true,
        },
    },
    {
        path: "/settings/integrations/quickbooksredirect",
        name: "QuickBooksRedirect",
        component: () =>
            import(
                /* webpackChunkName: "Integrations" */ "@/views/settings/integrations/quickBooks/QuickBooksRedirectView.vue"
            ),
        meta: {
            requiresAuth: true,
            requiredRoles: ["TenantAdmin"],
            showMenu: true,
        },
    },
    {
        path: "/errors/accessdenied",
        name: "AccessDenied",
        component: () =>
            import(/* webpackChunkName: "AccessDenied" */ "@/views/errors/AccessDeniedView.vue"),
        meta: {
            requiresAuth: false,
            showMenu: true,
        },
    },
    {
        path: "*",
        name: "NotFound",
        component: () =>
            import(/* webpackChunkName: "NotFound" */ "@/views/errors/NotFoundView.vue"),
        // redirect: { name: "NotFoundView" },
        meta: {
            requiresAuth: false,
            showMenu: true,
        },
    },
];

const router = new VueRouter({
    linkActiveClass: "active",
    linkExactActiveClass: "active-exact",
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({ x: 0, y: 0 });
            }, 500);
        });
    },
});

router.beforeEach((to, from, next) => {
    console.log("Router from:", from);
    console.log("Router to: ", to);

    if (
        to.matched.some((record) => record.meta && record.meta.requiresAuth) &&
        !store.state.signedIn
    ) {
        // Redirect to sign-in if necessary
        console.log("SignIn then redirect to ", to);
        if (to.name === "Root") {
            next({
                name: "SignIn",
            });
        } else {
            next({
                name: "SignIn",
                query: { redirect: to.fullPath },
            });
        }
    } else if (to.name === "Root" || (to.name === "SignIn" && store.state.signedIn)) {
        // Redirect to suitable starting view
        if (
            Utilities.userIsTrainer(store.state.user) ||
            Utilities.userIsTenantAdmin(store.state.user)
        ) {
            next({
                name: "Home",
            });
        } else {
            next({
                name: "Programmes",
            });
        }
    } else if (
        to.meta &&
        to.meta.requiredRoles &&
        !Utilities.userIsInAnyOfRoles(store.state.user, to.meta.requiredRoles)
    ) {
        next({
            name: "AccessDenied",
        });
    } else if (
        to.meta &&
        to.meta.requiredClaims &&
        !Utilities.userHasAnyOfClaims(store.state.user, to.meta.requiredClaims)
    ) {
        console.log(
            "Could not find matching claims",
            to.meta.requiredClaims,
            store.state.user.claims.map((c) => c.type)
        );
        next({
            name: "AccessDenied",
        });
    } else {
        // Navigate to the target route!
        store.dispatch("showMenu", to.meta && to.meta.showMenu);
        next();
    }
});

router.afterEach((to, from) => {
    console.log("ROUTE_CHANGED sent", from, to);
    window.Raygun.trackEvent("pageView", { path: to.path });

    EventBus.$emit("ROUTE_CHANGED", to);
});

export default router;
