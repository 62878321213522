
import { Component, Prop, Watch, Vue, Emit } from "vue-property-decorator";
import TimeZoneUtils from "@/utils/TimeZoneUtils";
@Component({
    components: {},
})
export default class TimeZoneFormLabel extends Vue {
    @Prop({ type: String, required: true }) dateTime!: string;

    @Prop({ type: String, required: true }) timeZone!: string;

    @Prop({ default: false }) changeable!: boolean;

    @Prop({ default: false }) short!: boolean;

    selectedTimeZone: string | null = null;

    description = "";

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    @Emit() changeRequested() {}

    @Watch("dateTime")
    watchDateTime() {
        this.loadTimeZoneDescription();
    }

    created() {
        this.loadTimeZoneDescription();
    }

    loadTimeZoneDescription() {
        TimeZoneUtils.GetTimeZoneDescriptionForDateTime(
            this.timeZone,
            this.dateTime,
            this.short
        ).then((desc) => {
            this.description = desc;
            console.log(this.description);
        });
    }
}
