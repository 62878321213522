
import { Component, Prop, Vue } from "vue-property-decorator";
import { ProgrammeSection } from "@/contracts/v1/responses/ProgrammeSection";
import ProgrammeAutoInviteItem from "./ProgrammeAutoInviteItem.vue";

@Component({
    components: {
        "programme-auto-invite-item": ProgrammeAutoInviteItem,
    },
})
export default class ProgrammeAutoInviteSection extends Vue {
    @Prop({ required: true }) programmeId!: any;
    @Prop({ required: true }) programmeAutoInviteLearners!: boolean;
    @Prop({ required: true }) section!: ProgrammeSection;

    sectionAutoInviteLearners = false;

    created() {
        this.sectionAutoInviteLearners = this.section.autoInviteLearners;
    }

    get valueToDisplay() {
        return this.programmeAutoInviteLearners ? true : this.sectionAutoInviteLearners;
    }

    onSectionAutoInviteChanged(checked: boolean) {
        this.sectionAutoInviteLearners = checked;
        this.$emit("section-auto-invite-changed", this.section.id, checked);
    }

    onItemAutoInviteChanged(itemId: number, autoInviteLearners: boolean) {
        console.log("Section emitting item-auto-invite-changed");
        this.$emit("item-auto-invite-changed", itemId, autoInviteLearners);
    }
}
