// Polyfills for js features used in the Dashboard but not supported in some browsers (mainly IE)
import "@/polyfills";
// Validation plugin used to validate forms
import { configure, extend } from "vee-validate";
// A plugin file where you could register global components used across the app
// A plugin file where you could register global directives
// Sidebar on the right. Used as a local plugin in DashboardLayout.vue

// element ui language configuration
import lang from "element-ui/lib/locale/lang/en";
import locale from "element-ui/lib/locale";

// vue-bootstrap
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

// asset imports
import "@/assets/sass/argon.scss";
import "@/assets/css/nucleo/css/nucleo.css";
import * as rules from "vee-validate/dist/rules";
import { messages } from "vee-validate/dist/locale/en.json";
// Notifications plugin. Used on Notifications page
// import Notifications from "@/components/NotificationPlugin";
import SideBar from "@/components/SidebarPlugin";
import GlobalDirectives from "./globalDirectives";
import GlobalComponents from "./globalComponents";

locale.use(lang);

Object.keys(rules).forEach((rule) => {
    extend(rule, {
        ...rules[rule], // copies rule configuration
        message: messages[rule], // assign message
    });
});
export default {
    install(Vue) {
        Vue.use(GlobalComponents);
        Vue.use(GlobalDirectives);
        Vue.use(SideBar);
        // Vue.use(Notifications);
        Vue.use(BootstrapVue);
        Vue.use(IconsPlugin);
        configure({
            classes: {
                valid: "is-valid",
                invalid: "is-invalid",
                dirty: ["is-dirty", "is-dirty"], // multiple classes per flag!
            },
        });
    },
};
