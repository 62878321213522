
import { Component, Vue } from "vue-property-decorator";
import store from "@/store/index";
import Utilities from "@/utils/Utilities";
import { IRoadMapItem } from "./IRoadMapItem";

@Component({
    components: {},
})
export default class TheRoadmap extends Vue {
    loading = true;

    roadmapItems: IRoadMapItem[] = [
        {
            id: "scorm",
            title: "SCORM support",
            description:
                "Extend your GiraffePad programmes with support for courses and modules authored in the industry-standard SCORM format.",
            userRoles: ["Trainer", "TenantAdmin"],
        },
    ];

    get userRoadmapItems(): IRoadMapItem[] {
        if (this.loading) {
            return [];
        }

        return this.roadmapItems.filter((i) =>
            Utilities.userIsInAnyOfRoles(store.state.user, i.userRoles)
        );
    }

    get isVisible(): boolean {
        return !this.loading && this.userRoadmapItems.length > 0;
    }

    created() {
        this.loading = false;
    }
}
