
import { defineComponent } from "vue";
import Vue from "vue";
import Draggable from "vuedraggable";
import ResourceComponent from "@/components/Resource.vue";
import Conversation from "@/components/ConversationView.vue";
import ResourcesAddItemBar from "@/components/ResourcesAddItemBar.vue";
import LearnerSelectionDialog from "@/components/LearnerSelectionDialog.vue";
import TrainerSelectionDialog from "@/components/TrainerSelectionDialog.vue";
import UploadLinkDialog from "@/components/UploadLinkDialog.vue";
import UploadFileDialog from "@/components/UploadFileDialog.vue";
import ProgrammeTimeLine from "@/components/programmes/ProgrammeTimeLine.vue";
import ProgrammeDetailsPanel from "@/components/programmes/ProgrammeDetailsPanel.vue";
import CloneProgrammeDialog from "@/components/programmes/CloneProgrammeDialog.vue";
import store from "@/store/index";
import Utilities from "@/utils/Utilities";
import LogUtils from "@/utils/LogUtils";
import PersonList from "@/components/PersonList.vue";
import ChannelUnreadMessagesBadge from "@/components/ChannelUnreadMessagesBadge.vue";
import FolderResourcesSelectionDialog from "@/components/FolderResourcesSelectionDialog.vue";
import BannerImage from "@/components/BannerImage.vue";
import TimeLineAddItemBar from "@/components/Timeline/TimeLineAddItemBar.vue";
import MultipleEmailsInput from "@/components/Inputs/MultipleEmailsInput.vue";
import { Programme } from "@/contracts/v1/responses/Programme";
import { ProgrammeSection } from "@/contracts/v1/responses/ProgrammeSection";
import { Resource } from "@/contracts/v1/responses/Resource";
import { Trainer } from "@/contracts/v1/responses/Trainer";
import { Objective } from "@/contracts/v1/responses/Objective";
import { UserProgrammePermissions } from "@/contracts/v1/responses/UserProgrammePermissions";
import { UserIntegrationsPermissions } from "@/contracts/v1/responses/UserIntegrationsPermissions";
import { UserTimelineItemPermissions } from "@/contracts/v1/responses/UserTimelineItemPermissions";
import { LearnerSummary } from "@/contracts/v1/responses/LearnerSummary";

export default defineComponent({
    components: {
        Conversation,
        draggable: Draggable,
        "resource-component": ResourceComponent,
        "banner-image": BannerImage,
        "upload-link-dialog": UploadLinkDialog,
        "upload-file-dialog": UploadFileDialog,
        ProgrammeTimeLine,
        "learner-selection-dialog": LearnerSelectionDialog,
        "trainer-selection-dialog": TrainerSelectionDialog,
        "person-list": PersonList,
        "channel-unread-messages-badge": ChannelUnreadMessagesBadge,
        "folder-resources-selection-dialog": FolderResourcesSelectionDialog,
        "clone-programme-dialog": CloneProgrammeDialog,
        TimeLineAddItemBar,
        ResourcesAddItemBar,
        MultipleEmailsInput,
        ProgrammeDetailsPanel,
    },
    data() {
        return {
            currentProgrammeId: undefined,
            programme: {} as Programme,
            archived: false,
            objectives: [] as Objective[],
            programmeResources: [] as Resource[],
            programmeTrainerResources: [] as Resource[],
            unreadMessageCount: 0,
            error: null,
            loading: false,
            chatClient: null,
            addingObjective: false,
            newObjective: "",
            invitingLearner: false,
            newLearnerEmails: "",
            chatChannelId: null,
            currentChatTheme: "LightTheme",
            programmePermissions: {} as UserProgrammePermissions,
            integrationsPermissions: {} as UserIntegrationsPermissions,
            timelineItemPermissions: {} as UserTimelineItemPermissions,
            tenantTrainers: [] as Trainer[],
            showArchived: false,
            userIsTenantAdmin: Utilities.userIsTenantAdmin(store.state.user),
            sections: [] as ProgrammeSection[],
            uploadingTrainerResource: false,
            savingNewLearner: false,
            chatActivated: false,
            chatVisible: false,
            infoTabSelected: true,
            resourcesTabSelected: false,
            bannerSizeClass: "banner",
            currentTab: 0,
            selectedSectionId: null,
            sectionNotInvitedProgrammeLearners: [],
        };
    },
    props: {
        programmeId: { type: Number, required: true },
        openProgrammeSectionId: { type: Number, required: false },
        initialTab: { type: Number, required: false },
    },
    created() {
        const vm = this;
        this.$watch(
            "$route.params.programmeId",
            (programmeId) => {
                console.log("routeChanged to cloned programme ", programmeId);
                vm.loadProgramme(programmeId);
            },
            { immediate: true }
        );
    },
    mounted() {
        if (this.initialTab) {
            this.onTabActivated(this.initialTab);
        }
        this.$nextTick(() => {
            // console.log(vm.$refs);
            // console.log(vm.$refs.messagesList);
            // console.log('messagesList', this.$refs['messagesList']);
            // Enable bootstrap tooltips
            // this.$("[data-toggle=\"tooltip\"]").tooltip();
        });
    },
    computed: {
        showStatusLink() {
            return this.programme.learners.length > 0 && this.programme.events.length > 0;
        },
        objectiveDragOptions() {
            return {
                // animation: 200,
                // group: "objectives",
                // disabled: false,
                // dragClass: "dragging",
                handle: ".drag-handle",
            };
        },
        resourceDragOptions() {
            return {
                handle: ".drag-handle",
            };
        },
        learnerResources() {
            return this.programme.resources.filter(
                (resource) => resource.learner != null && !resource.isPrivate
            );
        },
        archivedItemsExist() {
            return this.programme.events.find((e) => e.isArchived);
        },
        notAddedTrainers() {
            const vm = this;
            if (vm.loading) return [];
            const addedTrainerIds = this.programme.trainers.map(
                (programmeTrainer) => programmeTrainer.id
            );
            return this.tenantTrainers.filter(
                (tenantTrainer) =>
                    !vm.myIncludes(addedTrainerIds, tenantTrainer.id) &&
                    tenantTrainer.id !== vm.programme.trainer.id
            );
        },
        trainersAvailableToAdd() {
            return this.programme.trainers.length < this.tenantTrainers.length - 1;
        },
        registeredLearners() {
            return Utilities.sortbyFirstNameLastName(
                this.programme.learners.filter((person) => person.hasCompletedRegistration)
            );
        },
        unregisteredLearners() {
            return Utilities.sortbyEmail(
                this.programme.learners.filter((person) => !person.hasCompletedRegistration)
            );
        },
        hasProSubscription() {
            return Utilities.userTenantHasProSubscription(store.state.user);
        },
        programmeLearners() {
            return this.programme ? this.programme.learners : [];
        },
    },
    methods: {
        loadProgramme(programmeId) {
            const vm = this;
            vm.loading = true;
            vm.currentProgrammeId = programmeId;
            Vue.prototype.$http
                .get(
                    `/v1/programmes/${vm.currentProgrammeId}?timeZone=${this.$store.state.user.timeZone}`
                )
                .then((res) => {
                    vm.programmePermissions = JSON.parse(res.headers["x-gp-permissions"]).programme;
                    console.log("programmePermissions", vm.programmePermissions);
                    vm.integrationsPermissions = JSON.parse(
                        res.headers["x-gp-permissions"]
                    ).integrations;
                    vm.timelineItemPermissions = JSON.parse(
                        res.headers["x-gp-permissions"]
                    ).timelineItem;
                    vm.programme = res.data;
                    console.log("Programme", vm.programme);

                    if (!this.userIsAuthorised()) {
                        vm.$router.replace({ name: "AccessDenied" });
                    } else {
                        vm.chatChannelId = vm.programme.chatChannelId;
                        vm.error = null;
                        vm.archived = vm.programme.isArchived;
                        vm.loading = false;
                        console.log("programmePermissions", vm.programmePermissions);
                        console.log("integrationsPermissions", vm.integrationsPermissions);
                        console.log("timelineItemPermissions", vm.timelineItemPermissions);
                        console.log("learnerResources", vm.learnerResources);

                        vm.sections = [];
                        if (
                            this.programme.events.filter((e) => e.programmeSectionId === null)
                                .length > 0
                        ) {
                            vm.sections.push({
                                id: null,
                                title: "items not in a section",
                                items: this.programme.events.filter(
                                    (e) => e.programmeSectionId === null
                                ),
                                order: 0,
                                expanded: true,
                                autoInviteLearners: false,
                            });
                        }

                        vm.programme.programmeSections
                            .map((ps) => ({
                                id: ps.id,
                                title: ps.title,
                                items: this.programme.events.filter(
                                    (e) =>
                                        e.programmeSectionId !== null &&
                                        e.programmeSectionId === ps.id
                                ),
                                order: ps.order,
                                expanded: this.openProgrammeSectionId === ps.id,
                                autoInviteLearners: ps.autoInviteLearners,
                            }))
                            .forEach((item) => vm.sections.push(item));

                        vm.sections.sort((a, b) => (a.id === null || a.order < b.order ? -1 : 1));

                        // this.logSections("Created sections");

                        vm.objectives = vm.programme.programmeObjectives
                            .map((obj) => ({
                                id: obj.id,
                                objective: obj.objective,
                                editing: false,
                                newObjective: "",
                                order: obj.order,
                            }))
                            .sort((a, b) => (a.order < b.order ? -1 : 1));

                        vm.programmeResources = vm.programme.resources
                            .filter((resource) => resource.learner == null)
                            .sort((a, b) => (a.order < b.order ? -1 : 1));
                        console.log("programmeResources", vm.programmeResources);

                        vm.programmeTrainerResources = vm.programme.trainerResources.sort((a, b) =>
                            a.order < b.order ? -1 : 1
                        );
                        console.log("programmeTrainerResources", vm.programmeTrainerResources);

                        Vue.prototype.$http
                            .get("/v1/trainers")
                            .then((res2) => {
                                vm.tenantTrainers = res2.data;
                                console.log("Tenant trainers", vm.tenantTrainers);
                            })
                            .catch((err) => {
                                LogUtils.Error("Could not load trainers", err, "ProgrammeView");
                            });
                    }
                })
                .catch((err) => {
                    console.log(err);
                    if (err.response?.status === 401) {
                        vm.$router.replace({ name: "AccessDenied" });
                    } else if (err.response?.status === 404) {
                        vm.$router.replace({ name: "NotFound" });
                    } else {
                        LogUtils.Error("Could not load programme details", err, "ProgrammeView");
                        vm.error = "Could not load programme details...";
                    }
                });
        },
        userIsAuthorised() {
            return (
                this.programme.facilitating ||
                (Utilities.userTenantId(store.state.user) === this.programme.tenant.id &&
                    Utilities.userIsTenantAdmin(store.state.user))
            );
        },
        onTabActivated(index) {
            this.currentTab = index;
            console.log("Tab activated: ", this.currentTab);
            if (this.currentTab === 5) {
                this.chatActivated = true;
            }

            this.infoTabSelected = this.currentTab === 0;
            this.resourcesTabSelected = this.currentTab === 4;

            this.bannerSizeClass = this.infoTabSelected ? "banner-large" : "banner-small";
        },
        onShowChat() {
            this.chatActivated = true;
        },
        showUploadFileDialog(isTrainerResource) {
            console.log("refs", this.$refs);
            this.uploadingTrainerResource = isTrainerResource;
            this.$bvModal.show("upload-file-dialog");
        },
        showUploadLinkDialog(isTrainerResource) {
            this.uploadingTrainerResource = isTrainerResource;
            this.$bvModal.show("upload-link-dialog");
        },
        showResourcesDialog(isTrainerResource) {
            this.uploadingTrainerResource = isTrainerResource;
            this.$refs.resources_selection_dialog.showDialog();
        },
        myIncludes(container, value) {
            let returnValue = false;
            const pos = container.indexOf(value);
            if (pos >= 0) {
                returnValue = true;
            }
            return returnValue;
        },
        addObjective() {
            this.addingObjective = true;
            this.$nextTick(() => {
                this.$refs["add-objective"].focus();
            });
        },
        objectiveClicked(objective) {
            if (this.programmePermissions.canEdit) {
                objective.newObjective = objective.objective;
                objective.editing = true;
                this.$nextTick(() => {
                    this.$refs[`objective-${objective.id}`][0].focus();
                });
            }
        },
        objectiveEdited(objective) {
            // console.log(objective);
            if (
                this.programmePermissions.canEdit &&
                objective.objective !== objective.newObjective
            ) {
                // console.log(objective);
                Vue.prototype.$http
                    .put(`/v1/programmes/${this.programme.id}/objectives/${objective.id}`, {
                        id: objective.id,
                        programmeId: this.programme.id,
                        objective: objective.newObjective,
                    })
                    .then(() => {
                        objective.objective = objective.newObjective;
                        objective.editing = false;
                    })
                    .catch((err) => {
                        LogUtils.Error("Unable to edit outcome", err, "ProgrammeView");
                        Utilities.gpErrorAlert(
                            this.$bvModal,
                            "An error occurred while updating the outcome."
                        );
                        objective.editing = false;
                    });
            } else {
                objective.editing = false;
            }
        },
        saveNewObjective() {
            const vm = this;
            Vue.prototype.$http
                .post(`/v1/programmes/${this.programme.id}/objectives`, {
                    programmeId: vm.programme.id,
                    objective: vm.newObjective,
                })
                .then((res) => {
                    // console.log('saveNewObjective returned: ', res.data);
                    vm.addingObjective = false;
                    vm.newObjective = "";
                    vm.objectives.push({
                        id: res.data.id,
                        objective: res.data.objective,
                        editing: false,
                        newObjective: "",
                    });
                })
                .catch((err) => {
                    LogUtils.Error("Unable to add outcome", err, "ProgrammeView");
                    Utilities.gpErrorAlert(
                        this.$bvModal,
                        "An error occurred while adding the outcome."
                    );
                });
        },
        inviteLearner() {
            this.invitingLearner = true;
            this.$nextTick(() => {
                this.$refs["new-learner-emails"].$refs["email-input"].focus();
            });
        },
        saveNewLearners() {
            const vm = this;
            this.savingNewLearner = true;
            Vue.prototype.$http
                .post(`/v1/programmes/${this.programme.id}/learners`, {
                    learnerEmails: vm.newLearnerEmails,
                })
                .then((res) => {
                    console.log("saveNewLearners returned: ", res.data);
                    vm.invitingLearner = false;
                    vm.newLearnerEmails = "";
                    res.data.forEach((learner) => {
                        vm.programme.learners.push(learner);
                    });
                    this.savingNewLearner = false;
                })
                .catch((err) => {
                    LogUtils.Error("Unable to add learner", err, "ProgrammeView");
                    Utilities.gpErrorAlert(
                        this.$bvModal,
                        "An error occurred while adding the learner."
                    );
                    this.savingNewLearner = false;
                });
        },
        cancelInviteNewLearners() {
            console.log("cancelInviteNewLearners()");
            this.invitingLearner = false;
        },
        resendInvitation(person) {
            Vue.prototype.$http
                .post(`/v1/programmes/${this.programme.id}/learners`, {
                    learnerEmails: person.email,
                })
                .then((res) => {
                    Utilities.gpSuccessAlert(this.$bvModal, "Learner invitation resent.");
                    console.log("resendInvitation returned: ", res.data);
                })
                .catch((err) => {
                    LogUtils.Error("Unable to re-invite learner", err, "ProgrammeView");
                    Utilities.gpErrorAlert(
                        this.$bvModal,
                        "An error occurred while re-inviting the learner."
                    );
                });
        },
        deleteObjective(objective) {
            const vm = this;
            Vue.prototype.$http
                .delete(`/v1/programmes/${this.programme.id}/objectives/${objective.id}`)
                .then(() => {
                    vm.objectives.splice(vm.objectives.indexOf(objective), 1);
                })
                .catch((err) => {
                    LogUtils.Error("Unable to delete outcome", err, "ProgrammeView");
                    Utilities.gpErrorAlert(
                        this.$bvModal,
                        "An error occurred while deleting outcome."
                    );
                });
        },
        cloneProgramme() {
            const vm = this;
            const cloneButton = document.querySelector("#clone-programme") as HTMLButtonElement;
            cloneButton.disabled = true;
            Vue.prototype.$http
                .post(`/v1/programmes/${vm.programme.id}/clone`)
                .then((response) => {
                    this.$bvModal.hide("clone-programme-dialog");
                    const newProgramme = response.data;
                    console.log(newProgramme);
                    cloneButton.disabled = false;
                    Utilities.gpSuccessAlert(
                        vm.$bvModal,
                        "The programme has been cloned.",
                        "Programme cloned",
                        "Go to my cloned programme"
                    ).then(() => {
                        this.chatChannelId = null; // Allow cleanup of chat channel
                        this.$nextTick(() => {
                            vm.$router.push({
                                name: "Programme",
                                params: { programmeId: newProgramme.id },
                            });
                        });
                    });
                })
                .catch((err) => {
                    this.$bvModal.hide("clone-programme-dialog");
                    LogUtils.Error("Unable to clone programme", err, "ProgrammeView");
                    cloneButton.disabled = false;
                    Utilities.gpErrorAlert(
                        vm.$bvModal,
                        "An error occurred while cloning this programme.",
                        "Clone programme failed"
                    );
                });
        },
        deleteProgramme() {
            const vm = this;
            const deleteButton = document.querySelector("#delete-programme") as HTMLButtonElement;
            if (vm.programme.events.length) {
                Utilities.gpErrorAlert(
                    this.$bvModal,
                    "A programme with events or activities cannot be deleted. If you wish to delete this programme you must delete it's events and activities first.",
                    "Delete Programme"
                );
            } else {
                Utilities.gpModalConfirm(
                    this.$bvModal,
                    "Delete Programme",
                    "Please confirm that you wish to delete this programme. This action cannot be reversed - all data relating to this programme will be permanently deleted!",
                    "Delete",
                    "Cancel",
                    true
                ).then((confirmed) => {
                    if (confirmed) {
                        deleteButton.disabled = true;
                        Vue.prototype.$http
                            .delete(`/v1/programmes/${vm.programme.id}`)
                            .then(() => {
                                // console.log(w);
                                Utilities.gpSuccessAlert(this.$bvModal, "Programme deleted.").then(
                                    () => {
                                        this.$router.push({ name: "Programmes" });
                                    }
                                );
                            })
                            .catch((err) => {
                                LogUtils.Error("Unable to delete programme", err, "ProgrammeView");
                                deleteButton.disabled = false;
                                if (err !== undefined) {
                                    Utilities.gpErrorAlert(
                                        this.$bvModal,
                                        "An error occurred while deleting the programme.",
                                        "Delete failed"
                                    );
                                }
                            });
                    }
                });
            }
        },
        archiveProgramme() {
            const vm = this;
            const archiveButton = document.querySelector("#archive-programme") as HTMLButtonElement;

            Utilities.gpModalConfirm(
                this.$bvModal,
                "Archive Programme",
                "Please confirm that you wish to archive this programme. This action is reversible and no data will be deleted.",
                "Archive",
                "Cancel"
            ).then((confirmed) => {
                if (confirmed) {
                    archiveButton.disabled = true;
                    Vue.prototype.$http
                        .post(`/v1/programmes/${vm.programme.id}/archive`, { setArchived: true })
                        .then(() => {
                            // console.log(w);
                            vm.archived = true;
                            archiveButton.disabled = false;
                        })
                        .catch((err) => {
                            LogUtils.Error("Unable to archive programme", err, "ProgrammeView");
                            archiveButton.disabled = false;
                            if (err !== undefined) {
                                Utilities.gpErrorAlert(
                                    this.$bvModal,
                                    "An error occurred while archiving the programme.",
                                    "Archive failed"
                                );
                            }
                        });
                }
            });
        },
        restoreProgramme() {
            const vm = this;
            const restoreButton = document.querySelector("#restore-programme") as HTMLButtonElement;

            Utilities.gpModalConfirm(
                this.$bvModal,
                "Restore Programme",
                "Please confirm that you wish to restore this archived programme.",
                "Restore",
                "Cancel"
            ).then((confirmed) => {
                if (confirmed) {
                    restoreButton.disabled = true;
                    Vue.prototype.$http
                        .post(`/v1/programmes/${vm.programme.id}/archive`, { setArchived: false })
                        .then(() => {
                            // console.log(w);
                            vm.archived = false;
                            restoreButton.disabled = false;
                        })
                        .catch((err) => {
                            LogUtils.Error(
                                "Unable to restore archived programme",
                                err,
                                "ProgrammeView"
                            );
                            restoreButton.disabled = false;
                            if (err !== undefined) {
                                Utilities.gpErrorAlert(
                                    this.$bvModal,
                                    "An error occurred while retrieving archived programme.",
                                    "Restore failed"
                                );
                            }
                        });
                }
            });
        },
        fullName(firstName, lastName) {
            return `${firstName} ${lastName}`;
        },
        avatarUrl(userId) {
            return Utilities.avatarUrl(userId);
        },
        urlProgrammeEdit(programmeId) {
            return `/programme/edit/${programmeId}`;
        },
        formattedDateTimeSpan(dateFormat, date1, date2) {
            return Utilities.formattedDateTimeSpan(dateFormat, date1, date2);
        },
        resourceUploaded(resource) {
            if (this.uploadingTrainerResource) {
                this.programmeTrainerResources.push(resource);
            } else {
                this.programmeResources.push(resource);
            }
        },
        deleteProgrammeResource(resource) {
            const vm = this;
            Vue.prototype.$http
                .delete(`/v1/programmes/${this.programme.id}/resources/${resource.id}`)
                .then(() => {
                    vm.programmeResources.splice(vm.programmeResources.indexOf(resource), 1);
                })
                .catch((err) => {
                    LogUtils.Error(
                        "Unable to remove resource from programme",
                        err,
                        "ProgrammeView"
                    );
                    Utilities.gpErrorAlert(
                        this.$bvModal,
                        "An error occurred while removing the resource from the programme."
                    );
                });
        },
        deleteProgrammeTrainerResource(resource) {
            const vm = this;
            Vue.prototype.$http
                .delete(`/v1/programmes/${this.programme.id}/trainerresources/${resource.id}`)
                .then(() => {
                    vm.programmeTrainerResources.splice(
                        vm.programmeTrainerResources.indexOf(resource),
                        1
                    );
                })
                .catch((err) => {
                    LogUtils.Error(
                        "Unable to remove trainer resource from programme",
                        err,
                        "ProgrammeView"
                    );
                    Utilities.gpErrorAlert(
                        this.$bvModal,
                        "An error occurred while removing the trainer resource from the programme."
                    );
                });
        },
        isPDF(resource) {
            return resource.fileType === "application/pdf";
        },
        isImage(resource) {
            return resource.fileType && resource.fileType.startsWith("image/");
        },
        onAllowResourceDownloads(resource) {
            Vue.prototype.$http
                .post(`/v1/programmes/${this.programme.id}/resources/${resource.id}/allowdownloads`)
                .then(() => {
                    resource.allowDownloads = true;
                })
                .catch((err) => {
                    LogUtils.Error("Unable to allow resource downloads", err, "ProgrammeView");
                    Utilities.gpErrorAlert(
                        this.$bvModal,
                        "An error occurred while allowing resource downloads."
                    );
                });
        },
        onBlockResourceDownloads(resource) {
            Vue.prototype.$http
                .delete(
                    `/v1/programmes/${this.programme.id}/resources/${resource.id}/allowdownloads`
                )
                .then(() => {
                    resource.allowDownloads = false;
                })
                .catch((err) => {
                    LogUtils.Error("Unable to block resource downloads", err, "ProgrammeView");
                    Utilities.gpErrorAlert(
                        this.$bvModal,
                        "An error occurred while blocking resource downloads."
                    );
                });
        },
        onAllowTrainerResourceDownloads(resource) {
            Vue.prototype.$http
                .post(
                    `/v1/programmes/${this.programme.id}/trainerresources/${resource.id}/allowdownloads`
                )
                .then(() => {
                    resource.allowDownloads = true;
                })
                .catch((err) => {
                    LogUtils.Error("Unable to allow resource downloads", err, "ProgrammeView");
                    Utilities.gpErrorAlert(
                        this.$bvModal,
                        "An error occurred while allowing resource downloads."
                    );
                });
        },
        onBlockTrainerResourceDownloads(resource) {
            Vue.prototype.$http
                .delete(
                    `/v1/programmes/${this.programme.id}/trainerresources/${resource.id}/allowdownloads`
                )
                .then(() => {
                    resource.allowDownloads = false;
                })
                .catch((err) => {
                    LogUtils.Error("Unable to block resource downloads", err, "ProgrammeView");
                    Utilities.gpErrorAlert(
                        this.$bvModal,
                        "An error occurred while blocking resource downloads."
                    );
                });
        },
        makeLearnerResourcePrivate(resource) {
            const vm = this;
            const patchDoc = [{ op: "replace", path: "/isPrivate", value: true }];
            Vue.prototype.$http
                .patch(
                    `/v1/programmes/${vm.programmeId}/learners/${resource.learner.id}/resources/${resource.id}`,
                    patchDoc
                )
                .then(() => {
                    resource.isPrivate = true;
                })
                .catch((err) => {
                    LogUtils.Error(
                        "Unable to change programme learner resource privacy",
                        err,
                        "ProgrammeView"
                    );
                    Utilities.gpErrorAlert(
                        this.$bvModal,
                        "An error occurred while updating the resource's privacy."
                    );
                });
        },
        removeLearner(learner) {
            const vm = this;
            // var deleteButton = document.querySelector("#delete-event") as HTMLButtonElement;
            const learnerName = learner.hasCompletedRegistration
                ? `${learner.firstName} ${learner.lastName}`
                : learner.email;

            Utilities.gpModalConfirm(
                this.$bvModal,
                "Remove learner",
                `Please confirm that you wish to remove ${learnerName} from this programme and all it's events.`,
                "Remove",
                "Cancel",
                true
            ).then((confirmed) => {
                if (confirmed) {
                    Vue.prototype.$http
                        .delete(`/v1/programmes/${vm.programme.id}/learners/${learner.id}`)
                        .then(() => {
                            vm.programme.learners.splice(vm.programme.learners.indexOf(learner), 1);
                            Utilities.gpSuccessAlert(this.$bvModal, "Learner removed.");
                        })
                        .catch((err) => {
                            LogUtils.Error(
                                "Unable to remove learner from programme",
                                err,
                                "ProgrammeView"
                            );
                            // deleteButton.disabled = false;
                            if (err !== undefined) {
                                Utilities.gpErrorAlert(
                                    this.$bvModal,
                                    "An error occurred while removing the learner.",
                                    "Delete failed"
                                );
                            }
                        });
                }
            });
        },
        trainersSelected(trainers) {
            console.log("trainersSelected", trainers);
            const vm = this;
            trainers.forEach((trainer) => {
                Vue.prototype.$http
                    .post(`/v1/programmes/${vm.programme.id}/trainers`, { trainerId: trainer.id })
                    .then(() => {
                        vm.programme.trainers.push(trainer);
                    })
                    .catch((err) => {
                        LogUtils.Error("Unable to add trainer to programme", err, "ProgrammeView");
                    });
            });
        },
        removeTrainer(trainer) {
            const vm = this;
            console.log(vm);

            if (vm.isAProgrammeEventLeadTrainer(trainer.id)) {
                Utilities.gpErrorAlert(
                    this.$bvModal,
                    `${vm.fullName(
                        trainer.firstName,
                        trainer.lastName
                    )} is leading an event in this programme so cannot be removed.`,
                    "Trainer removal not allowed"
                );
            } else {
                Utilities.gpModalConfirm(
                    vm.$bvModal,
                    "Remove trainer",
                    `Please confirm that you wish to remove ${trainer.firstName} ${trainer.lastName} from this programme and all it's events.`,
                    "Remove",
                    "Cancel",
                    true
                ).then((confirmed) => {
                    if (confirmed) {
                        Vue.prototype.$http
                            .delete(`/v1/programmes/${vm.programme.id}/trainers/${trainer.id}`)
                            .then(() => {
                                this.programme.trainers.splice(
                                    vm.programme.trainers.indexOf(trainer),
                                    1
                                );
                                console.log(this);
                                Utilities.gpSuccessAlert(this.$bvModal, "Trainer removed.");
                            })
                            .catch((err) => {
                                LogUtils.Error(
                                    "Unable to remove trainer from programme",
                                    err,
                                    "ProgrammeView"
                                );
                                if (err !== undefined) {
                                    Utilities.gpErrorAlert(
                                        vm.$bvModal,
                                        "An error occurred while removing the trainer.",
                                        "Remove trainer failed"
                                    );
                                }
                            });
                    }
                });
            }
        },
        isAProgrammeEventLeadTrainer(trainerId) {
            const vm = this;
            console.log(this.programme);
            const eventLeadTrainerIds = vm.programme.events.map((event) => event.trainer.id);
            console.log(eventLeadTrainerIds);
            return vm.myIncludes(eventLeadTrainerIds, trainerId);
        },
        tenantLogoUrl(tenantId) {
            return Utilities.tenantLogoUrl(tenantId);
        },
        onResourcesSelected(resourceIds) {
            console.log("resourcesSelected", resourceIds);
            const vm = this;
            let endpoint = `/v1/programmes/${vm.programme.id}/resources`;
            if (this.uploadingTrainerResource) {
                endpoint = `/v1/programmes/${vm.programme.id}/trainerresources`;
            }
            resourceIds.forEach((resourceId) => {
                Vue.prototype.$http
                    .post(endpoint, { resourceId })
                    .then((res) => {
                        if (this.uploadingTrainerResource) {
                            this.programmeTrainerResources.push(res.data);
                        } else {
                            this.programmeResources.push(res.data);
                        }
                    })
                    .catch((err) => {
                        LogUtils.Error("Unable to add resources", err, "ProgrammeView");
                        if (err !== undefined) {
                            Utilities.gpErrorAlert(
                                this.$bvModal,
                                "An error occurred while adding the resource(s).",
                                "Add resource(s) failed"
                            );
                        }
                    });
            });
        },
        onObjectiveOrderChanged(evt) {
            console.log(evt);
            if (evt.moved) {
                const orderedIds = this.objectives.map((obj) => obj.id);
                console.log("Objective ids", orderedIds);
                Vue.prototype.$http
                    .put(`/v1/programmes/${this.programme.id}/objectives/reorder`, orderedIds)
                    // .then(() => {
                    //     console.log("Suuccessfully reordered ids", orderedIds);
                    // })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        onProgrammeResourceOrderChanged(evt) {
            console.log(evt);
            if (evt.moved) {
                const orderedIds = this.programmeResources.map((obj) => obj.id);
                console.log("Resource ids", orderedIds);
                Vue.prototype.$http
                    .put(`/v1/programmes/${this.programme.id}/resources/reorder`, orderedIds)
                    // .then(() => {
                    //     console.log("Suuccessfully reordered ids", orderedIds);
                    // })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        onProgrammeTrainerResourceOrderChanged(evt) {
            console.log(evt);
            if (evt.moved) {
                const orderedIds = this.programmeTrainerResources.map((obj) => obj.id);
                console.log("Resource ids", orderedIds);
                Vue.prototype.$http
                    .put(`/v1/programmes/${this.programme.id}/trainerresources/reorder`, orderedIds)
                    // .then(() => {
                    //     console.log("Suuccessfully reordered ids", orderedIds);
                    // })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        onInviteLearnersToAllClicked() {
            this.$bvModal.show("programme-learner-selection-dialog");
        },
        onSectionTitleChanged(evt) {
            console.log("ProgrammeView.onSectionTitleChanged()", evt);
            const sectionIndex = this.sections.findIndex((s) => s.id === evt.sectionId);
            if (sectionIndex !== -1) {
                const vm = this;
                Vue.prototype.$http
                    .put(`/v1/programmes/${this.programme.id}/sections/${evt.sectionId}`, {
                        title: evt.newTitle,
                    })
                    .then((res) => {
                        console.log("onSectionTitleChanged received updated section", res.data);
                        vm.sections.splice(sectionIndex, 1, {
                            ...vm.sections[sectionIndex],
                            title: evt.newTitle,
                        });
                    })
                    .catch((err) => {
                        LogUtils.Error("Unable to update section", err, "ProgrammeView");
                        Utilities.gpErrorAlert(
                            this.$bvModal,
                            "An error occurred while updating the section."
                        );
                    });
            }
        },
        onSectionInviteLearners(sectionId) {
            const vm = this;
            vm.selectedSectionId = sectionId;
            vm.sectionNotInvitedProgrammeLearners = [];
            Vue.prototype.$http
                .get(`/v1/programmes/${vm.currentProgrammeId}/sections/${sectionId}/alllearners`)
                .then((res) => {
                    let sectionLearners = res.data;
                    vm.sectionNotInvitedProgrammeLearners = vm.programme.learners.filter(
                        (pl) => !sectionLearners.some((sl) => sl.id == pl.id)
                    );
                    console.log(vm.programme.learners, vm.sectionNotInvitedProgrammeLearners);
                    if (vm.sectionNotInvitedProgrammeLearners.length == 0) {
                        Utilities.gpSuccessAlert(
                            this.$bvModal,
                            "All programme learners are invited to this section."
                        );
                    } else {
                        this.$bvModal.show("learner-selection-dialog");
                    }
                });
        },
        onAutoInviteNewLearners() {
            const vm = this;
            if (vm.programme.autoInviteLearners) {
                Vue.prototype.$http
                    .delete(`/v1/programmes/${this.programme.id}/autoinvite`)
                    .then(() => {
                        vm.programme.autoInviteLearners = false;
                    });
            } else {
                Vue.prototype.$http
                    .post(`/v1/programmes/${this.programme.id}/autoinvite`)
                    .then(() => {
                        vm.programme.autoInviteLearners = true;
                    });
            }
        },
        onSectionAutoInviteNewLearners(sectionId) {
            const sectionIndex = this.sections.findIndex((s) => s.id === sectionId);
            if (sectionIndex !== -1) {
                const vm = this;
                if (vm.sections[sectionIndex].autoInviteLearners) {
                    Vue.prototype.$http
                        .delete(
                            `/v1/programmes/${this.programme.id}/sections/${sectionId}/autoinvite`
                        )
                        .then(() => {
                            vm.sections[sectionIndex].autoInviteLearners = false;
                        });
                } else {
                    Vue.prototype.$http
                        .post(
                            `/v1/programmes/${this.programme.id}/sections/${sectionId}/autoinvite`
                        )
                        .then(() => {
                            vm.sections[sectionIndex].autoInviteLearners = true;
                        });
                }
            }
        },
        onSectionDeleteClicked(sectionId) {
            const sectionIndex = this.sections.findIndex((s) => s.id === sectionId);
            if (sectionIndex !== -1) {
                const vm = this;
                Vue.prototype.$http
                    .delete(`/v1/programmes/${this.programme.id}/sections/${sectionId}`)
                    .then(() => {
                        vm.sections.splice(sectionIndex, 1);
                    })
                    .catch((err) => {
                        LogUtils.Error("Unable to delete section", err, "ProgrammeView");
                        Utilities.gpErrorAlert(
                            this.$bvModal,
                            "An error occurred while deleting the section."
                        );
                    });
            }
        },
        onSectionMoved(evt) {
            console.log("ProgrammeView.onSectionMoved()", evt);

            const movedSection = this.sections.splice(evt.oldIndex, 1)[0];
            this.sections.splice(evt.newIndex, 0, movedSection);
            this.sections.forEach((s, i) => {
                s.order = s.id == null ? 0 : i + 1;
            });
            this.sections.sort((a, b) => (a.id === null || a.order < b.order ? -1 : 1));

            Vue.prototype.$http
                .put(
                    `/v1/programmes/${this.programme.id}/sections/reorder`,
                    this.sections.filter((s) => s.id !== null).map((s) => s.id)
                )
                .catch((err) => {
                    LogUtils.Error("Unable to re-order sections", err, "ProgrammeView");
                });

            // this.logSections("after moving section");
        },
        logSections(title) {
            console.log(`*** ${title} ***`);
            this.sections.forEach((s) => this.logSection(s));
        },
        logSection(section) {
            console.log(`[${section.id}] ${section.title} (order = ${section.order})`);
            section.items.forEach((item) => {
                console.log(`    [${item.id}] ${item.title} (order = ${item.order})`);
            });
        },
        onItemMoved(evt) {
            console.log("ProgrammeView.onItemMoved()", evt);

            if (evt.fromSectionId !== evt.toSectionId) {
                const patchDoc = [
                    { op: "replace", path: "/programmeSectionId", value: evt.toSectionId },
                ];
                Vue.prototype.$http
                    .patch(`/v1/events/${evt.itemId}`, patchDoc)
                    .then(() => {
                        this.updateSectionItemsOrder(evt);
                    })
                    .catch((err) => {
                        LogUtils.Error("Unable to update item's section", err, "ProgrammeView");
                        Utilities.gpErrorAlert(
                            this.$bvModal,
                            "An error occurred while updating the item's section."
                        );
                    });
            } else {
                this.updateSectionItemsOrder(evt);
            }
        },
        updateSectionItemsOrder(evt) {
            console.log("updateSectionItemsOrder()", evt);

            const fromSection = this.sections.find((s) => s.id === evt.fromSectionId);
            const toSection = this.sections.find((s) => s.id === evt.toSectionId);

            if (toSection !== undefined && fromSection !== undefined) {
                const itemIndex = fromSection.items.findIndex((e) => e.id === evt.itemId);
                if (itemIndex !== -1) {
                    // this.logSection(fromSection);
                    const item = fromSection.items.splice(itemIndex, 1)[0]; // eslint-disable-line prefer-destructuring
                    // this.logSection(toSection);
                    item.sectionId = evt.toSectionId;
                    toSection.items.splice(evt.newIndex, 0, item);
                    // this.logSection(toSection);
                }
            }
            const orderedIds = toSection.items.map((item) => item.id);
            console.log("Item ids", orderedIds);
            Vue.prototype.$http
                .put(
                    `/v1/programmes/${this.programme.id}/sections/${
                        toSection.id ? toSection.id : 0
                    }/reorder`,
                    orderedIds
                )
                .catch((err) => {
                    console.log(err);
                });
        },
        onShowArchivedClicked() {
            this.showArchived = true;
        },
        onHideArchivedClicked() {
            this.showArchived = false;
        },
        onCreateSectionClicked() {
            const vm = this;
            Vue.prototype.$http
                .post(`/v1/programmes/${this.programme.id}/sections`, { title: "" })
                .then((res) => {
                    console.log("onCreateSectionClicked received new section", res.data);
                    vm.sections.push({
                        id: res.data.id,
                        title: res.data.title,
                        items: [],
                        expanded: true,
                        isNew: true,
                    });
                })
                .catch((err) => {
                    LogUtils.Error("Unable to add section", err, "ProgrammeView");
                    Utilities.gpErrorAlert(
                        this.$bvModal,
                        "An error occurred while adding the section."
                    );
                });
        },
        onToggleSection(sectionId) {
            const section = this.sections.find((s) => s.id === sectionId);
            section.expanded = !section.expanded;
        },
        onExpandSection(sectionId) {
            const section = this.sections.find((s) => s.id === sectionId);
            section.expanded = true;
        },
        insertEmailSoftBreaks(email) {
            return Utilities.insertEmailSoftBreaks(email);
        },
        sectionLearnersSelected(learners: LearnerSummary[]) {
            console.log("learnersSelected", learners);
            const vm = this;
            vm.sectionNotInvitedProgrammeLearners = [];
            learners.forEach((learner) => {
                Vue.prototype.$http
                    .post(
                        `/v1/programmes/${vm.programme.id}/sections/${vm.selectedSectionId}/learners/${learner.id}`
                    )
                    .catch((err) => {
                        LogUtils.Error("Unable to add learner to section", err, "ProgrammeView");
                    });
            });
            Utilities.gpSuccessAlert(
                this.$bvModal,
                "The selected learner(s) have been invited to all items in this section.",
                "Learner(s) Invited"
            );
        },
        programmeLearnersSelected(learners: LearnerSummary[]) {
            console.log("programme learners selected", learners);
            const vm = this;
            learners.forEach((learner) => {
                Vue.prototype.$http
                    .post(`/v1/programmes/${vm.programme.id}/learners/${learner.id}/allevents`)
                    .catch((err) => {
                        LogUtils.Error(
                            "Unable to add learner to programme items",
                            err,
                            "ProgrammeView"
                        );
                    });
            });
            Utilities.gpSuccessAlert(
                this.$bvModal,
                "The selected learner(s) have been invited to all items in the programme.",
                "Learner(s) Invited"
            );
        },
    },
});
