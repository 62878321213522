
import { Component, Prop, Vue, Emit } from "vue-property-decorator";

@Component
export default class TitleBar extends Vue {
    @Prop() title!: string;

    @Prop({ required: true, default: "" }) itemName!: string;

    @Prop({ default: false }) flat!: boolean;

    @Prop({ default: false }) loading!: boolean;

    @Prop({ default: false }) showAddButton!: boolean;

    @Prop({ default: false }) showEditButton!: boolean;

    @Prop({ default: false }) showDeleteButton!: boolean;

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    @Emit() addClicked() {}

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    @Emit() editClicked() {}

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    @Emit() deletelicked() {}
}
