
import { Component, Prop, Vue } from "vue-property-decorator";
import UserAvatar from "@/components/UserAvatar.vue";

@Component({
    components: {
        "user-avatar": UserAvatar,
    },
})
export default class PersonList extends Vue {
    @Prop() people!: unknown[];

    @Prop({ default: false }) showStatus!: boolean;
}
