
import Vue, { defineComponent } from "vue";
import Utilities from "@/utils/Utilities";
import LogUtils from "@/utils/LogUtils";

export default defineComponent({
    name: "UploadLinkDialog",
    props: {
        title: {
            type: String,
            required: true,
        },
        eventId: {
            type: Number,
            required: false,
        },
        programmeId: {
            type: Number,
            required: false,
        },
        learnerId: {
            type: Number,
            required: false,
        },
        folderId: {
            type: Number,
            required: false,
        },
        isTrainerResource: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            formData: {
                url: null,
                title: null,
            },
        };
    },
    methods: {
        onCancel() {
            this.formData.url = null;
            this.formData.title = null;
            this.$bvModal.hide("upload-link-dialog");
        },
        onUploadWebResource() {
            console.log("Called onUploadWebResource()", this.formData);
            const vm = this;

            // Open the connection.
            let postUrl = "";
            if (this.eventId) {
                if (this.learnerId) {
                    postUrl = `/v1/events/${this.eventId}/learners/${vm.learnerId}/webresources`;
                } else {
                    postUrl = `/v1/events/${this.eventId}/webresources`;
                }
            } else if (this.programmeId) {
                if (this.learnerId) {
                    postUrl = `/v1/programmes/${this.programmeId}/learners/${vm.learnerId}/webresources`;
                } else {
                    postUrl = `/v1/programmes/${this.programmeId}/webresources`;
                }
            } else {
                postUrl = "/v1/resources/webresources";
            }

            postUrl = `${postUrl}?isTrainerResource=${this.isTrainerResource ? "true" : "false"}`;

            if (this.folderId) {
                postUrl = `${postUrl}&folderId=${this.folderId}`;
            }

            Vue.prototype.$http
                .post(postUrl, this.formData)
                .then((res) => {
                    const resourceAdded = res.data;
                    console.log("resourceAdded", resourceAdded);
                    vm.$emit("resource-uploaded", resourceAdded);
                    this.$bvModal.hide("upload-link-dialog");
                    this.formData.url = null;
                    this.formData.title = null;
                })
                .catch((err) => {
                    LogUtils.Error("Unable to upload resource", err, "UploadLinkDialog");
                    Utilities.gpErrorAlert(this.$bvModal, "Unable to upload resource at this time");
                    this.$bvModal.hide("upload-link-dialog");
                    this.formData.url = null;
                    this.formData.title = null;
                });
        },
    },
});
