
import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import { Conversation } from "@twilio/conversations/lib/conversation";
import { Message } from "@twilio/conversations/lib/message";
import { Paginator } from "@twilio/conversations/lib/interfaces/paginator";
import { Participant } from "@twilio/conversations/lib/participant";
import { User } from "@twilio/conversations/lib/user";
import { VEmojiPicker } from "v-emoji-picker";
import imageCompression from "browser-image-compression";
import Utilities from "@/utils/Utilities";
import LogUtils from "@/utils/LogUtils";
import store from "@/store/index";
import ImageResourcePreviewDialog from "@/components/resources/ImageResourcePreviewDialog.vue";

@Component({
    components: {
        "v-emoji-picker": VEmojiPicker,
        ImageResourcePreviewDialog,
    },
})
export default class ConversationView extends Vue {
    @Prop({ type: String, required: false }) conversationSid!: string;

    @Prop({ type: String, required: false }) header!: string;

    @Prop({ type: Boolean, required: false, default: false }) fullHeight!: boolean;

    @Prop({ type: Boolean, required: false, default: false }) stickyTop!: boolean;

    @Prop({ type: Boolean, required: false, default: false }) focusOnLoaded!: boolean;

    @Prop({ type: String, required: false }) heightpx!: string;

    @Prop({ type: String, required: false }) chatBoxUniqueId!: string;

    @Prop({ type: Boolean, required: false, default: false }) canDeleteMessages!: boolean;

    @Watch("conversationSid")
    async watchConversationSid(newVal) {
        await this.unloadConversation();

        if (!(newVal === "" || newVal === 0)) {
            await this.loadConversation(newVal);
        } else {
            this.unloadConversation();
        }
    }

    currentConversation: Conversation | null = null;

    messages: Array<Message> = [];

    visibleMessages = [];

    paginator: Paginator<Message> | null = null;

    // firstMessageIndex: number | undefined = undefined;
    firstMessageIndex = 0;

    participants: Array<Participant> = [];

    users: Array<User> = [];

    loading = true;

    mediaUrlsLoaded = false;

    showEmojiPicker = false;

    emojiPickeri18n = {
        search: "Search...",
        categories: {
            Activity: "Activities",
            Flags: "Flags",
            Foods: "Food",
            Frequently: "Frequently used",
            Objects: "Objects",
            Nature: "Nature",
            Peoples: "People",
            Symbols: "Symbols",
            Places: "Places",
        },
    };

    newMessageText = "";

    replyToMessage: Message | null = null;

    currentImageFile: File | null = null;

    currentImageFileContent: string | null = null;

    sending = false;

    imagePreviewUrl = "";

    async mounted() {
        await this.loadConversation(this.conversationSid);
    }

    beforeDestroy() {
        this.unloadConversation();
    }

    get chatDivId() {
        if (this.chatBoxUniqueId) return `chat-div${this.chatBoxUniqueId}`;
        return "chat-div";
    }

    get currentUserId(): string {
        return store.state.user.userId;
    }

    get showLoading(): boolean {
        return this.currentConversation !== null && this.loading;
    }

    get imageSelected(): boolean {
        return this.currentImageFileContent != null && this.currentImageFileContent.length > 0;
    }

    get sendButtonEnabled(): boolean {
        return this.imageSelected || this.newMessageText.length > 0;
    }

    getConversationImageClass(message: any): string {
        if (message.attributes.mediaHeight > message.attributes.mediaWidth)
            return "conversation-image-portrait";
        return "conversation-image-landscape";
    }

    isCurrentUser(author: string) {
        return author === this.currentUserId;
    }

    getLocalMessageTime(dateCreated: Date) {
        return dateCreated.toLocaleTimeString([], { timeStyle: "short" });
    }

    getName(author: string) {
        const user = this.users.find((u) => u.identity === author);
        return user ? user.friendlyName : author;
    }

    getOriginalMessage(messageSid: string): Message | undefined {
        return this.messages.find((m) => m.sid === messageSid);
    }

    showDateDivider(messageIndex: number): boolean {
        if (messageIndex === 0) return true;

        const prevDateStr = this.messages[messageIndex - 1].dateCreated.toDateString();
        const thisDateStr = this.messages[messageIndex].dateCreated.toDateString();

        // console.log("showDateDivider", messageIndex, prevDateStr, thisDateStr);
        return prevDateStr !== thisDateStr;
    }

    async onChatChannelsLoaded() {
        // await this.loadConversation();
    }

    async onMessageAdded(message: Message) {
        // console.log("<--- messageAdded [ConversationView]", message);
        if (message.media) {
            await this.getMessageMediaUrl(message);
        }
        this.messages.push(message);
        this.annotateMessage(message, this.messages.length - 1);
        this.visibleMessages.push(message);
        await this.setAllMessagesRead();
        this.$nextTick(() => this.scrollToBottom());
    }

    unloadConversation() {
        this.showEmojiPicker = false;
        this.replyToMessage = null;
        if (this.currentConversation !== null) {
            this.currentConversation.off("messageAdded", this.onMessageAdded);

            // await this.currentConversation?.leave();
            this.currentConversation = null;
        }
    }

    async loadConversation(conversationSid) {
        this.showEmojiPicker = false;
        this.replyToMessage = null;
        this.newMessageText = "";
        if (conversationSid !== "") {
            const chatClient = globalThis.chatService.getChatClient();
            if (chatClient) {
                this.loading = true;
                // console.log(this.loading);
                this.mediaUrlsLoaded = false;
                chatClient
                    .getConversationBySid(conversationSid)
                    .then(async (conversation) => {
                        // console.log("getConversationBySid() returned ", conversation);
                        this.currentConversation = conversation;

                        this.messages = [];
                        this.paginator = await this.currentConversation.getMessages(
                            100,
                            undefined,
                            "forward"
                        );
                        // console.log("*** got first page of messages ***", this.paginator!.items.length);
                        this.messages.push(...this.paginator!.items);
                        await this.loadNextMessagesPage();

                        this.firstMessageIndex = Math.max(0, this.messages.length - 50);
                        this.visibleMessages = this.messages.slice(
                            this.firstMessageIndex,
                            this.messages.length
                        );

                        // // Assign a key so we can rerender when an image url is added
                        // console.log("before", this.messages);
                        // this.messages.forEach((message) => {
                        //     (message as any).key = message.sid;
                        // });
                        // console.log("after", this.messages);

                        this.participants = [];
                        this.participants = await conversation.getParticipants();
                        // console.log("participants", this.participants);

                        this.users = [];
                        const userResults: Array<Promise<User>> = [];
                        this.participants.forEach((participant) => {
                            userResults.push(participant.getUser());
                        });
                        this.users = await Promise.all(userResults);
                        // console.log("users", this.users);

                        // eslint-disable-next-line
                        this.messages.forEach((m: Message, index: number) => {
                            (m as any).mediaUrl = m.media ? null : undefined;
                            this.annotateMessage(m, index);
                        });

                        if (this.currentConversation) {
                            this.currentConversation.on("messageAdded", this.onMessageAdded);
                        }

                        // Mark all messages read
                        await this.setAllMessagesRead();

                        this.loading = false;
                        // console.log(this.loading);

                        this.$nextTick(async () => {
                            this.scrollToBottom();
                            if (this.focusOnLoaded) {
                                (this.$refs["message-input"] as HTMLElement).focus();
                            }
                            const promises = this.messages
                                .filter((message) => message.media)
                                .map((message) => this.getMessageMediaUrl(message));
                            // console.log("promises", promises);
                            await Promise.allSettled(promises);
                            this.mediaUrlsLoaded = true;
                        });
                    })
                    .catch((err) => {
                        LogUtils.Error("Failed to get channel", err, "ConversationView");
                        this.loading = false;
                    });
            }
        }
    }

    private annotateMessage(m: Message, index: number) {
        (m as any).localMessageTime = this.getLocalMessageTime(m.dateCreated);

        if ((m.attributes as any).replyTo) {
            (m as any).originalMessage = this.getOriginalMessage(
                (m.attributes as any).replyTo.messageSid
            );
        }

        (m as any).authorName = this.getName((m as any).state.author);
        // console.log("Set author name", (m as any).authorName);

        if (m.media) {
            (m as any).imageClass = this.getConversationImageClass(m);
        }

        (m as any).showDateDivider = this.showDateDivider(index);
    }

    async setAllMessagesRead() {
        await this.currentConversation!.setAllMessagesRead();
    }

    displayPreviousMessages() {
        const container = this.$refs["message-list-container"] as HTMLElement;
        const { scrollTop, scrollHeight } = container;
        // console.log(scrollTop, scrollHeight);

        this.firstMessageIndex = Math.max(0, this.firstMessageIndex! - 50);
        // console.log("displayPreviousMessages", this.firstMessageIndex, this.messages.length);
        this.visibleMessages = this.messages.slice(this.firstMessageIndex, this.messages.length);

        this.$nextTick(() => {
            const scrollHeightNew = container.scrollHeight;
            container.scrollTop = scrollHeightNew - scrollHeight + scrollTop;
        });
    }

    protected async loadNextMessagesPage() {
        if (this.paginator!.hasNextPage) {
            this.paginator = await this.paginator!.nextPage();
            // console.log("*** got next page of messages ***", this.paginator.items.length);
            this.messages.push(...this.paginator.items);
            await this.loadNextMessagesPage();
        }
    }

    myAvatarCallback(userId) {
        return `${process.env.VUE_APP_API_BASE_URL}/v1/users/${userId}/avatar?redirect=true`;
    }

    onGoBack() {
        // console.log("onGoBack() called");
        this.unloadConversation();
        this.$emit("go-back-clicked");
    }

    onAttachClicked() {
        // console.log("onAttachClicked", this.$refs.imageFileInput);
        (this.$refs.imageFileInput as HTMLElement).click();
    }

    async imageFileChosen(ev: any) {
        // console.log("onFileChosen", ev.target.files[0]);
        if (ev.target.files.length === 1) {
            const [imageFile] = ev.target.files;
            // console.log("originalFile instanceof Blob", imageFile instanceof Blob); // true
            // console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

            const options = {
                maxSizeMB: 0.5,
                // maxWidthOrHeight: 1920,
                useWebWorker: true,
            };
            try {
                const compressedFile = await imageCompression(imageFile, options);
                // console.log("compressedFile instanceof Blob", compressedFile instanceof Blob); // true
                // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
                this.currentImageFile = compressedFile;

                const reader = new FileReader();
                reader.onload = (e) => {
                    // console.log("File loaded", e);
                    this.currentImageFileContent = e!.target!.result as string;
                    this.scrollToBottom();
                };
                reader.readAsDataURL(this.currentImageFile!);
            } catch (error) {
                console.log(error);
            }
        }
        this.resetFileInput();
    }

    resetFileInput() {
        (this.$refs.imageFileInput as HTMLInputElement).value = "";
    }

    onEmojiPickerClicked() {
        this.showEmojiPicker = !this.showEmojiPicker;
        this.scrollToBottom();
    }

    onInputClicked() {
        this.showEmojiPicker = false;
        this.scrollToBottom();
    }

    onEmojiSelected(emoji: any) {
        // console.log(emoji);
        this.newMessageText += emoji.data;
        this.scrollToBottom();
    }

    async onSend() {
        try {
            this.sending = true;

            const replyToAttributes = this.replyToMessage
                ? {
                      replyTo: {
                          messageSid: this.replyToMessage.sid,
                      },
                  }
                : {};

            if (this.currentImageFileContent) {
                // get the intrinsic size of the image
                const img = this.$refs.currentImageElement as HTMLImageElement;
                const attributes = {
                    ...replyToAttributes,
                    mediaType: "image",
                    mediaWidth: img.naturalWidth,
                    mediaHeight: img.naturalHeight,
                };

                await this.currentConversation!.sendMessage(
                    {
                        contentType: this.currentImageFile?.type,
                        filename: this.currentImageFile?.name,
                        media: (await this.currentImageFile) as any,
                    } as Conversation.SendMediaOptions,
                    attributes
                );
            } else {
                await this.currentConversation!.sendMessage(this.newMessageText, replyToAttributes);
            }

            this.sending = false;
            this.currentImageFile = null;
            this.currentImageFileContent = null;
            this.newMessageText = "";
            this.replyToMessage = null;
            this.showEmojiPicker = false;
            this.$nextTick(() => {
                (this.$refs["message-input"] as HTMLElement).focus();
            });
            await this.setAllMessagesRead();
        } catch (error) {
            this.sending = false;
        }
    }

    onReplyTo(message: Message) {
        this.replyToMessage = message;
        (this.$refs["message-input"] as HTMLElement).focus();
        this.scrollToBottom();
    }

    onDelete(message: Message) {
        Utilities.gpModalConfirm(
            this.$bvModal,
            "Delete this message?",
            "This action cannot be undone.",
            "Delete",
            "Cancel",
            true
        ).then((confirmed) => {
            if (confirmed) {
                Vue.prototype.$http
                    .delete(`/v1/channels/${message.conversation.sid}/messages/${message.sid}`)
                    .catch((err) => console.log(err));
            }
        });
    }

    onCancelReplyTo() {
        this.replyToMessage = null;
        const input = this.$refs["message-input"];
        if (input) {
            (input as HTMLElement).focus();
        }
    }

    onCancelCurrentImage() {
        this.currentImageFile = null;
        this.currentImageFileContent = null;
        this.resetFileInput();
        this.$nextTick(() => {
            (this.$refs["message-input"] as HTMLElement).focus();
        });
    }

    getMessageMediaUrl(message: Message): Promise<void> {
        return new Promise((resolve) => {
            message.media.getContentTemporaryUrl().then((url) => {
                (message as any).mediaUrl = url;
                resolve();
            });
        });
    }

    scrollToBottom() {
        if (this.messages.length > 0) {
            const container = this.$refs["message-list-container"] as HTMLElement;
            container.scrollTop = container.scrollHeight;
            this.$nextTick(() => {
                container.scrollTop = container.scrollHeight;
                this.$nextTick(() => {
                    container.scrollTop = container.scrollHeight;
                });
            });
        }
    }

    onImageClicked(imageUrl: string) {
        // console.log("onImageClicked", imageUrl);
        this.imagePreviewUrl = imageUrl;
        this.$nextTick(() => {
            this.$bvModal.show("image-resource-preview-dialog");
        });
    }

    onImagePreviewClosed() {
        this.imagePreviewUrl = "";
    }
}
