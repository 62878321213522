var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"card-footer"},[_c('b-dropdown',{attrs:{"id":"dropdown-divider","variant":"primary","size":"sm"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('b-icon',{staticClass:"mr-1",attrs:{"icon":"plus-circle","aria-hidden":"true"}}),_vm._v(" Add ")]},proxy:true}])},[_c('b-dropdown-item-button',{on:{"click":function($event){return _vm.$router.push({
                    name: 'AddEvent',
                    params: { programmeId: _vm.programmeId, sectionId: _vm.sectionId },
                })}}},[_c('b-icon',{attrs:{"icon":"calendar2-event","variant":"info","aria-hidden":"true"}}),_vm._v(" Event ")],1),_c('b-dropdown-item-button',{on:{"click":function($event){return _vm.$router.push({
                    name: 'AddActivity',
                    params: { programmeId: _vm.programmeId, sectionId: _vm.sectionId },
                })}}},[_c('b-icon',{attrs:{"icon":"hammer","variant":"primary","aria-hidden":"true"}}),_vm._v(" Activity ")],1),_c('b-dropdown-item-button',{attrs:{"disabled":!_vm.hasProSubscription},on:{"click":function($event){return _vm.$router.push({
                    name: 'AddQuestionnaire',
                    params: { programmeId: _vm.programmeId, sectionId: _vm.sectionId },
                })}}},[_c('b-icon',{attrs:{"disabled":!_vm.hasProSubscription,"icon":"question-circle","variant":"secondary","aria-hidden":"true"}}),_vm._v(" Questionnaire "),(!_vm.hasProSubscription)?_c('b-badge',{staticClass:"ml-2 text-white",attrs:{"variant":"dark","pill":""}},[_vm._v("Pro")]):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }