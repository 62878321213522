
import { Component, Vue } from "vue-property-decorator";
import store from "@/store/index";
import Utilities from "@/utils/Utilities";
import LogUtils from "@/utils/LogUtils";
import UserAvatar from "@/components/UserAvatar.vue";
import Conversation from "@/components/ConversationView.vue";
import ChannelUnreadMessagesBadge from "@/components/ChannelUnreadMessagesBadge.vue";
import PersonCard from "@/components/PersonCard.vue";
import { Trainer } from "@/contracts/v1/responses/Trainer";

@Component({
    components: {
        "user-avatar": UserAvatar,
        Conversation,
        "channel-unread-messages-badge": ChannelUnreadMessagesBadge,
        "person-card": PersonCard,
    },
})
export default class TeamView extends Vue {
    loading = true;

    chatActivated = false;

    userIsTenantAdmin: boolean = Utilities.userIsTenantAdmin(store.state.user);

    currentUserId: string = this.$store.state.user.userId;

    activeTenantSubscription: boolean = Utilities.userHasActiveTenantSubscription(store.state.user);

    userHasTeamChatPermission: boolean = Utilities.userHasTeamChatPermission(store.state.user);

    trainers: Array<Trainer> = [];

    chatChannelId: string | null = null;

    public created() {
        Vue.prototype.$http
            .get("/v1/trainers")
            .then((res: any) => {
                console.log("Trainers", res.data);
                this.trainers = res.data;
                this.loading = false;
            })
            .catch((err: any) => {
                LogUtils.Error("Could not load trainers", err, "TeamView");
            });

        Vue.prototype.$http
            .get(`/v1/accounts/${Utilities.userTenantId(store.state.user)}`)
            .then((res) => {
                console.log(res);
                this.chatChannelId = res.data.chatChannelId;
            })
            .catch((err) => {
                LogUtils.Error("Could not load team chat details", err, "TeamView");
            });
    }

    onTabActivated(index: any) {
        console.log("Tab activated: ", index);
        if (index === 1) {
            this.chatActivated = true;
        }
    }

    get orderedTrainers(): any[] {
        const sortedTrainers = this.trainers.sort((trainer1, trainer2) =>
            trainer1.id < trainer2.id ? -1 : 1
        );
        return sortedTrainers;
    }

    fullName(firstName: string, lastName: string) {
        return `${firstName} ${lastName}`;
    }
}
