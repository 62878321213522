import { IListOption } from "./IListOption";

export default class ListOptions {
    public static reminderEmailOptions(isEvent = false): IListOption[] {
        console.log("reminderEmailOptions(isEvent)", isEvent);
        return isEvent
            ? [
                  { value: null, text: "Send no reminder email" },
                  { value: 7, text: "Send reminder email a week before start" },
                  { value: 3, text: "Send reminder email 3 days before start" },
                  { value: 2, text: "Send reminder email 2 days before start" },
                  { value: 1, text: "Send reminder email 1 day before start" },
                  { value: 0, text: "Send reminder email on start date" },
              ]
            : [
                  { value: null, text: "Send no reminder email" },
                  { value: 7, text: "Send reminder email a week before due" },
                  { value: 3, text: "Send reminder email 3 days before due" },
                  { value: 2, text: "Send reminder email 2 days before due" },
                  { value: 1, text: "Send reminder email 1 day before due" },
                  { value: 0, text: "Send reminder email on due date" },
              ];
    }

    public static overdueEmailOptions(): IListOption[] {
        return [
            { value: null, text: "Send no overdue email" },
            { value: 1, text: "Send overdue email 1 day after due" },
            { value: 2, text: "Send overdue email 2 days after due" },
            { value: 3, text: "Send overdue email 3 days after due" },
            { value: 7, text: "Send overdue email a week after due" },
        ];
    }

    public static reminderEmailDaysText(days: number | null, isEvent = false): string {
        const option = ListOptions.reminderEmailOptions(isEvent).find((o) => o.value === days);
        return option ? option.text : "";
    }

    public static overdueEmailDaysText(days: number | null): string {
        const option = ListOptions.overdueEmailOptions().find((o) => o.value === days);
        return option ? option.text : "";
    }
}
