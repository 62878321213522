
import { Component, Vue } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import axios from "axios";
import LogUtils from "@/utils/LogUtils";
import Utilities from "@/utils/Utilities";
import { Programme } from "@/contracts/v1/responses/Programme";
import BannerImage from "@/components/BannerImage.vue";

@Component({
    components: {
        "banner-image": BannerImage,
    },
})
export default class ProgrammeSelfRegistrationView extends Vue {
    private validationObserver!: InstanceType<typeof ValidationObserver>;

    formData: any = {
        registrationCode: "",
        email: "",
    };

    error = "";

    signingUp = false;

    signedUp = false;

    loading = true;

    programme = {} as Programme;

    programmeSelfRegistrationNotEnabled = false;

    created() {
        console.log("Query", this.$route.query);
        this.formData.registrationCode = this.$route.query.code as string;

        console.log(this);

        // Call api with regsitration code here to get programme details
        const vm = this;
        Vue.prototype.$http
            .get(`/v1/programmes/byregistrationcode/${this.formData.registrationCode}`)
            .then((res) => {
                vm.programme = res.data;
                console.log("Programme", vm.programme);
                vm.loading = false;
                if (!vm.programme.selfRegistrationEnabled) {
                    this.programmeSelfRegistrationNotEnabled = true;
                } else {
                    this.$nextTick(() => (this.$refs.email as any).focus());
                }
            })
            .catch((err: any) => {
                LogUtils.Error(
                    "Error getting programme details",
                    err,
                    "ProgrammeSelfRegistrationView"
                );
                this.signingUp = false;
                this.error = "Registration attempt failed  ";
            });
    }

    async trySignUp() {
        this.signingUp = true;
        console.log(this.formData);
        const vm = this;
        axios
            .post(
                `${process.env.VUE_APP_API_BASE_URL}/v1/programmes/selfregister/${this.formData.registrationCode}`,
                this.formData
            )
            .then((res: any) => {
                this.signedUp = true;
            })
            .catch((err: any) => {
                LogUtils.Error(
                    "Error completing self registration",
                    err,
                    "ProgrammeSelfRegistrationView"
                );
                this.signingUp = false;
                this.error = "Self registration attempt failed  ";
            });
    }

    tenantLogoUrl(tenantId) {
        return Utilities.tenantLogoUrl(tenantId);
    }
}
