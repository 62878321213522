
import { defineComponent } from "vue";
import pdf from "vue-pdf";

export default defineComponent({
    components: {
        pdf,
    },
    props: {
        fileName: {
            type: String,
            required: true,
        },
        url: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            src: null,
            loading: true,
            progressRatio: 0,
            error: null,
            numPages: undefined,
            showPasswordEntry: false,
            passwordMessage: "",
            submitPasswordFunction: null,
            password: "",
        };
    },
    mounted() {
        this.src = pdf.createLoadingTask(this.url, {
            onPassword: this.onPasswordRequired,
            onProgress: this.onProgessUpdate,
        });
        this.src.promise.then((doc) => {
            this.numPages = doc.numPages;
            this.loading = false;
            // })
            // .catch((error) => {
            //     this.loading = false;
            //     console.log("Caught loading exception: ", error);
            //     if (error.name === "PasswordException") {
            //         this.error = "PDFs requiring a password to view must be downloaded.";
            //     } else {
            //         this.error = "An error occurred while loading this resource.";
            //     }
        });
    },
    methods: {
        onHidden() {
            this.$emit("close");
        },
        onError(/*error*/) {
            // console.log("onError", error);
        },
        onProgessUpdate(update) {
            this.progressRatio = update.loaded / update.total;

            if (this.showPasswordEntry) {
                this.$nextTick(() => {
                    this.$refs["password-input"].focus();
                });
            }
        },
        onPasswordRequired(fnUpdatePassword, reason) {
            this.submitPasswordFunction = fnUpdatePassword;
            if (reason === 1) {
                this.passwordMessage = "Please enter the password";
            } else {
                this.passwordMessage = "Incorrect - please try again";
            }

            this.showPasswordEntry = true;
            this.$nextTick(() => {
                this.$refs["password-input"].focus();
            });
        },
        onSubmitPassword() {
            this.submitPasswordFunction(this.password);
            this.password = "";
        },
    },
});
