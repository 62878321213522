
import { Component, Vue } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import axios from "axios";
import LogUtils from "@/utils/LogUtils";
import TimeZoneUtils from "@/utils/TimeZoneUtils";

@Component({
    components: {},
})
export default class UpgradeToPaidView extends Vue {
    private validationObserver!: InstanceType<typeof ValidationObserver>;

    formData: any = {
        companyName: "",
        paymentcurrency: "GBP",
        timeZone: "Europe/London",
        countryCode: "GB",
        tier: "Essentials",
    };

    error = "";

    signingUp = false;

    timeZones: any[] = [];

    mounted() {
        this.validationObserver = this.$refs.form as InstanceType<typeof ValidationObserver>;
    }

    created() {
        axios
            .get(
                "https://api.ipstack.com/check?access_key=cc59ca4e657fa177e3d8ce07612f05a8&format=1"
            )
            .then((res) => {
                console.log("IP location data", res.data);
                this.formData.paymentcurrency = res.data.currency.code;
                this.formData.timeZone = res.data.time_zone.id;
                this.formData.countryCode = res.data.country_code;
                console.log("Detected location currency: ", this.formData.paymentcurrency);
            });

        TimeZoneUtils.GetTimeZones(null, false).then((res) => {
            this.timeZones = res;
        });
    }

    async tryUpgrade() {
        this.signingUp = true;
        console.log(this.formData);
        globalThis.chatService.disconnect(); // avoid seeing sample messages increment unread messages badge
        Vue.prototype.$http
            .post(`/v1/tenants/${this.$store.state.user.userId}`, this.formData)
            .then((res: any) => {
                console.log("User: ", res.data);

                this.$store
                    .dispatch("updateUser", res.data)
                    .then(() => {
                        globalThis.chatService.initialise(); // re-establish the chat service
                        this.$router.push({ name: "Account" });
                    })
                    .catch((err) => {
                        LogUtils.Error("Sign in after upgrade failed", err, "UpgradeToPaidView");
                        this.signingUp = false;
                        this.error = "Sign in failed  ";
                        console.log("Sign in failed", err);

                        this.$router.push({ name: "EditProfile" });
                    });
            })
            .catch((err: any) => {
                LogUtils.Error("Error upgrading", err, "UpgradeToPaidView");
                this.signingUp = false;
                globalThis.chatService.initialise(); // re-establish the chat service
            });
    }
}
