
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
    components: {},
})
export default class TheEnvironmentIndicator extends Vue {
    @Prop() environmentName!: string;

    isVisible = false;

    environmentDisplayName = "";

    created() {
        this.isVisible = this.environmentName !== "production";
        console.log(this.environmentName);
        this.environmentDisplayName = this.environmentName.toUpperCase();
        if (this.environmentDisplayName === "DEVELOPMENT") {
            this.environmentDisplayName = "DEV";
        } else if (this.environmentDisplayName === "TEST") {
            this.environmentDisplayName = "TST";
        }
    }
}
