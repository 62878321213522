
import { Component, Prop, Vue } from "vue-property-decorator";
import store from "@/store/index";
import Utilities from "@/utils/Utilities";
import LogUtils from "@/utils/LogUtils";
import { UserProgrammePermissions } from "@/contracts/v1/responses/UserProgrammePermissions";
import { Programme } from "@/contracts/v1/responses/Programme";
import { ProgrammeSection } from "@/contracts/v1/responses/ProgrammeSection";
import { EventSummary } from "@/contracts/v1/responses/EventSummary";
import ProgrammeAutoInviteSection from "@/components/programmes/ProgrammeAutoInviteSection.vue";
import ProgrammeAutoInviteItem from "@/components/programmes/ProgrammeAutoInviteItem.vue";

@Component({
    components: {
        "programme-auto-invite-section": ProgrammeAutoInviteSection,
        "programme-auto-invite-item": ProgrammeAutoInviteItem,
    },
})
export default class ProgrammeAutoInviteView extends Vue {
    @Prop({ required: true }) programmeId!: any;

    currentProgrammeId: number | undefined;

    programmePermissions: UserProgrammePermissions | undefined;

    programme = {
        id: 0,
        title: "",
        autoInviteLearners: false,
        events: [] as EventSummary[],
        programmeSections: [] as ProgrammeSection[],
    } as Programme;

    sections: ProgrammeSection[];

    nullSection: ProgrammeSection | undefined;

    userIsTenantAdmin: boolean = Utilities.userIsTenantAdmin(store.state.user);

    loading = true;

    get hasProSubscription(): boolean {
        return Utilities.userTenantHasProSubscription(store.state.user);
    }

    // get nullSection(): ProgrammeSection | undefined {
    //     return this.programme.programmeSections.find((ps) => ps.id === undefined);
    // }

    created() {
        const vm = this;
        vm.loading = true;
        Vue.prototype.$http
            .get(`/v1/programmes/${this.programmeId}?timeZone=${this.$store.state.user.timeZone}`)
            .then((res) => {
                vm.programmePermissions = JSON.parse(res.headers["x-gp-permissions"]).programme;
                let programme = res.data as Programme;
                vm.programme.id = programme.id;
                vm.programme.title = programme.title;
                vm.programme.autoInviteLearners = programme.autoInviteLearners;
                vm.programme.events = programme.events.filter((e) => !e.isArchived);
                vm.programme.programmeSections = programme.programmeSections;
                console.log("Programme", vm.programme);

                vm.loading = false;
                console.log("programmePermissions", vm.programmePermissions);

                vm.sections = [];
                if (this.programme.events.filter((e) => e.programmeSectionId === null).length > 0) {
                    this.nullSection = {
                        id: null,
                        title: "items not in a section",
                        items: this.programme.events.filter((e) => e.programmeSectionId === null),
                        order: 0,
                        autoInviteLearners: false,
                    } as ProgrammeSection;
                }

                vm.programme.programmeSections
                    .map((ps) => ({
                        id: ps.id,
                        title: ps.title,
                        items: this.programme.events.filter(
                            (e) => e.programmeSectionId !== null && e.programmeSectionId === ps.id
                        ),
                        order: ps.order,
                        autoInviteLearners: ps.autoInviteLearners,
                    }))
                    .forEach((item) => vm.sections.push(item));

                vm.sections.sort((a, b) => (a.id === null || a.order < b.order ? -1 : 1));

                // this.logSections("Created sections");
            })
            .catch((err) => {
                console.log(err);
                if (err.response?.status === 401) {
                    vm.$router.replace({ name: "AccessDenied" });
                } else if (err.response?.status === 404) {
                    vm.$router.replace({ name: "NotFound" });
                } else {
                    LogUtils.Error(
                        "Could not load programme details",
                        err,
                        "ProgrammeAutoInviteView"
                    );
                }
            });
    }

    onProgrammeAutoInviteChanged(autoInvite: boolean) {
        console.log("onProgrammeAutoInviteChanged", autoInvite);
        this.programme.autoInviteLearners = autoInvite;
        if (autoInvite) {
            Vue.prototype.$http.post(`/v1/programmes/${this.programme.id}/autoinvite`).then(() => {
                this.programme.autoInviteLearners = true;
            });
        } else {
            Vue.prototype.$http
                .delete(`/v1/programmes/${this.programme.id}/autoinvite`)
                .then(() => {
                    this.programme.autoInviteLearners = false;
                });
        }
    }

    onSectionAutoInviteChanged(sectionId: number, autoInviteLearners: boolean) {
        console.log("onSectionAutoInviteChanged", sectionId, autoInviteLearners);

        const sectionIndex = this.sections.findIndex((s) => s.id === sectionId);
        console.log("sectionIndex", sectionIndex);
        if (sectionIndex !== -1) {
            if (!autoInviteLearners) {
                Vue.prototype.$http
                    .delete(`/v1/programmes/${this.programme.id}/sections/${sectionId}/autoinvite`)
                    .then(() => {
                        this.sections[sectionIndex].autoInviteLearners = false;
                    });
            } else {
                Vue.prototype.$http
                    .post(`/v1/programmes/${this.programme.id}/sections/${sectionId}/autoinvite`)
                    .then(() => {
                        this.sections[sectionIndex].autoInviteLearners = true;
                    });
            }
        }
    }

    onItemAutoInviteChanged(itemId: number, autoInviteLearners: boolean) {
        console.log("onItemAutoInviteChanged", itemId, autoInviteLearners);

        const itemIndex = this.programme.events.findIndex((e) => e.id === itemId);
        console.log("itemIndex", itemIndex);
        if (itemIndex !== -1) {
            if (!autoInviteLearners) {
                Vue.prototype.$http
                    .delete(`/v1/programmes/${this.programme.id}/items/${itemId}/autoinvite`)
                    .then(() => {
                        this.programme.events[itemIndex].autoInviteLearners = false;
                    });
            } else {
                Vue.prototype.$http
                    .post(`/v1/programmes/${this.programme.id}/items/${itemId}/autoinvite`)
                    .then(() => {
                        this.programme.events[itemIndex].autoInviteLearners = true;
                    });
            }
        }
    }
}
