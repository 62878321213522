
/* eslint-disable max-len */
import Vue, { defineComponent } from "vue";
import Utilities from "@/utils/Utilities";
import UserAvatar from "@/components/UserAvatar.vue";
import ResourcePreviewer from "@/components/resources/ResourcePreviewer.vue";

export default defineComponent({
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Resource",
    props: ["resource", "compact", "canDrag"],
    components: {
        "user-avatar": UserAvatar,
        "resource-previewer": ResourcePreviewer,
    },
    data() {
        return {
            loadingUser: true,
            firstName: null,
            lastName: null,
            previewVisible: false,
        };
    },
    created() {
        const vm = this;
        Vue.prototype.$http.get(`/v1/users/${this.resource.uploadedByUserId}`).then((result) => {
            if (result.data.hasCompletedRegistration) {
                vm.firstName = result.data.firstName;
                vm.lastName = result.data.lastName;
                vm.loadingUser = false;
            }
        });
    },
    computed: {
        showEmbeddedVideoPlayer() {
            return (
                this.resource &&
                (this.resource.youTubeId || this.resource.vimeoId || this.resource.loomId)
            );
        },
        isPDF() {
            return this.resource && Utilities.resourceIsPDF(this.resource);
        },
        isImage() {
            return this.resource && Utilities.resourceIsImage(this.resource);
        },
        isUploadedVideo() {
            return this.resource && Utilities.resourceIsUploadedVideo(this.resource);
        },
    },
    methods: {
        videoUrl() {
            if (this.resource.youTubeId)
                return `https://www.youtube-nocookie.com/embed/${this.resource.youTubeId}?rel=0`;
            if (this.resource.vimeoId)
                return `https://player.vimeo.com/video/${this.resource.vimeoId}`;
            if (this.resource.loomId) return `https://www.loom.com/embed/${this.resource.loomId}`;
            return "";
        },
        avatarUrl(userId) {
            return Utilities.avatarUrl(userId);
        },
        showPreview(modalId) {
            this.previewVisible = true;

            this.$nextTick(() => {
                this.$bvModal.show(modalId);
            });
        },
        onPreviewClosed() {
            console.log("onPreviewClosed()");
            this.previewVisible = false;
        },
    },
});
