
import Vue, { defineComponent } from "vue";
import Utilities from "@/utils/Utilities";
import UserAvatar from "@/components/UserAvatar.vue";
import Conversation from "@/components/ConversationView.vue";
import ChannelUnreadMessagesBadge from "@/components/ChannelUnreadMessagesBadge.vue";

export default defineComponent({
    name: "PersonCard",
    components: {
        "user-avatar": UserAvatar,
        Conversation,
        "channel-unread-messages-badge": ChannelUnreadMessagesBadge,
    },
    data() {
        return {
            about: null,
            chatActivated: false,
            chatChannelId: null,
            startingChat: false,
        };
    },
    props: {
        member: {
            type: Object,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        canNavigate: {
            type: Boolean,
            required: true,
        },
    },
    created() {
        Vue.prototype.$http
            .get(`/v1/users/${this.member.userId}`)
            .then((res) => {
                this.about = res.data.about;
                this.chatChannelId = res.data.privateChatChannelId;
            })
            .catch((err) => console.log(err));
    },
    methods: {
        onTabActivated(index) {
            console.log("Tab activated: ", index);
            if (index === 1) {
                this.chatActivated = true;
            }
        },
        avatarUrl(userId) {
            return Utilities.avatarUrl(userId);
        },
        fullName(firstName, lastName) {
            return `${firstName} ${lastName}`;
        },
        startChat() {
            this.startingChat = true;
            Vue.prototype.$http
                .post(`/v1/users/${this.member.userId}/privateChatChannel`)
                .then((res) => {
                    this.chatChannelId = res.data.chatChannelId;
                    this.startingChat = false;
                })
                .catch(() => {
                    this.startingChat = false;
                });
        },
    },
    computed: {
        route() {
            return { name: "TeamMember", params: { memberId: this.member.id } };
        },
        showChat() {
            return this.chatActivated && this.chatChannelId;
        },
        isCurrentUser() {
            return this.member.userId === this.$store.state.user.userId;
        },
        titles() {
            return this.title.split(",");
        },
    },
});
