
import { defineComponent } from "vue";
import moment from "moment";
import Utilities from "@/utils/Utilities";
import UserAvatar from "../UserAvatar.vue";
import ChannelUnreadMessagesBadge from "@/components/ChannelUnreadMessagesBadge.vue";

export default defineComponent({
    name: "EventCard",
    components: {
        "user-avatar": UserAvatar,
        "channel-unread-messages-badge": ChannelUnreadMessagesBadge,
        // 'messages-badge': window.httpVueLoader('/js/components/MessagesBadge.vue')
    },
    props: {
        eventId: {
            type: Number,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        clientId: {
            type: Number,
            required: false,
        },
        clientName: {
            type: String,
            required: true,
        },
        eventType: {
            type: Number,
            required: true,
        },
        location: {
            type: String,
            required: false,
        },
        start: {
            type: String,
            required: true,
        },
        end: {
            type: String,
            required: true,
        },
        tenantId: {
            type: Number,
            required: false,
        },
        showTenantLogo: {
            type: Boolean,
            required: true,
        },
        chatChannelId: {
            type: String,
            required: true,
        },
        trainer: {
            required: true,
        },
        additionalTrainers: {
            required: true,
        },
        userIsFacilitating: {
            type: Boolean,
            required: true,
        },
        canNavigate: {
            type: Boolean,
            required: true,
        },
        isSample: {
            type: Boolean,
            required: true,
        },
        isArchived: {
            type: Boolean,
            required: true,
        },
        activeTenantSubscription: {
            type: Boolean,
            required: true,
        },
        standalone: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        startDateString() {
            const startDate = Date.parse(this.start);
            return moment(startDate).format("YYYY-MM-DD");
        },
        navigationDisabled() {
            return !this.canNavigate || (this.userIsFacilitating && !this.activeTenantSubscription);
        },
        route() {
            if (this.userIsFacilitating) {
                return { name: "Event", params: { eventId: this.eventId } };
            }

            return { name: "EventInfo", params: { eventId: this.eventId } };
        },
        locationIsHyperlink() {
            return this.location.startsWith("https:");
        },
    },
    methods: {
        formattedDateTimeSpan(dateFormat, date1, date2) {
            return Utilities.formattedDateTimeSpan(dateFormat, date1, date2);
        },
        tenantLogoUrl(tenantId) {
            return Utilities.tenantLogoUrl(tenantId);
        },
        avatarUrl(userId) {
            return Utilities.avatarUrl(userId);
        },
    },
});
