
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class UserAvatar extends Vue {
    @Prop() userId!: string;

    @Prop() firstName!: string;

    @Prop() lastName!: string;

    @Prop() size!: number;

    @Prop({ default: false }) redirect!: boolean;

    get avatarUrl() {
        return `${process.env.VUE_APP_API_BASE_URL}/v1/users/${this.userId}/avatar?redirect=${this.redirect}`;
    }
}
