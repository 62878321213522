
import { Component, Prop, Vue } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import axios from "axios";
import LogUtils from "@/utils/LogUtils";
import store from "@/store";
import ChatService from "@/services/ChatService";
import TimeZoneUtils from "@/utils/TimeZoneUtils";

declare global {
    interface Window {
        rewardful: any;
    }
}

@Component({
    components: {},
})
export default class SignUpTenantView extends Vue {
    @Prop({ type: String, required: false, default: "" }) emailProp!: string;

    @Prop({ type: String, required: true }) tier!: string;

    @Prop({ type: String, required: true }) currency!: string;

    private validationObserver!: InstanceType<typeof ValidationObserver>;

    formData: any = {
        email: "",
        firstName: "",
        lastName: "",
        companyName: "",
        password: "",
        confirmPassword: "",
        paymentcurrency: "",
        timeZone: "Europe/London",
        countryCode: "GB",
        tier: "",
    };

    step = 1;

    error = "";

    signingUp = false;

    timeZones: any[] = [];

    mounted() {
        this.validationObserver = this.$refs.form as InstanceType<typeof ValidationObserver>;

        console.log(this.emailProp);
        if (this.emailProp.length) {
            this.formData.email = this.emailProp;
            (this.$refs.firstName as any).focus();
        }

        console.log(this.formData);
    }

    created() {
        this.formData.tier = this.tier;
        this.formData.paymentcurrency = this.currency;

        axios
            .get(
                "https://api.ipstack.com/check?access_key=cc59ca4e657fa177e3d8ce07612f05a8&format=1"
            )
            .then((res) => {
                console.log("IP location data", res.data);
                this.formData.timeZone = res.data.time_zone.id;
                this.formData.countryCode = res.data.country_code;
            });

        TimeZoneUtils.GetTimeZones(null, false).then((res) => {
            this.timeZones = res;
        });

        store.dispatch("signOut");
    }

    async trySignUp() {
        this.step = 4;
        this.signingUp = true;
        console.log(this.formData);
        Vue.prototype.$http
            .post("/v1/tenants", this.formData)
            .then((res: any) => {
                console.log("User: ", res.data);

                this.$store
                    .dispatch("updateUser", res.data)
                    .then(() => {
                        // Record any referrals with Rewardful
                        window.rewardful("convert", { email: this.formData.email });

                        globalThis.chatService = new ChatService();
                        globalThis.chatService.initialise();
                        this.$router.push({ name: "Home" });
                    })
                    .catch((err) => {
                        LogUtils.Error(
                            "Sign in after tenant registration failed",
                            err,
                            "SignUpTenantView"
                        );
                        this.signingUp = false;
                        this.error = "Sign in failed  ";

                        this.$router.push({ name: "SignIn" });
                    });
            })
            .catch((err: any) => {
                LogUtils.Error("Error registering new tenant", err, "SignUpTenantView");
                this.signingUp = false;
            });
    }
}
