
import { Component, Vue } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import axios from "axios";
import LogUtils from "@/utils/LogUtils";
import store from "@/store";

@Component({
    components: {},
})
export default class CompleteUserRegistrationView extends Vue {
    private validationObserver!: InstanceType<typeof ValidationObserver>;

    formData: any = {
        email: "",
        firstName: "",
        lastName: "",
        password: "",
        emailVerificationCode: "",
    };

    confirmPassword = "";

    userId = "";

    error = "";

    signingUp = false;

    loading = false;

    created() {
        this.userId = this.$route.query.userId as string;
        this.formData.emailVerificationCode = this.$route.query.code as string;
        this.formData.email = this.$route.query.email as string;

        console.log(this);
        this.$nextTick(() => (this.$refs.firstName as any).focus());

        axios
            .get(`${process.env.VUE_APP_API_BASE_URL}/v1/users/${this.userId}`)
            .then((res: any) => {
                const user = res.data;
                if (user.hasCompletedRegistration) {
                    this.$router.push({ name: "SignIn" });
                }
                this.formData.email = user.email;
                this.formData.firstName = user.firstName;
                this.formData.lastName = user.lastName;
            })
            .catch(() => {
                this.$router.push({ name: "SignIn" });
            });

        store.dispatch("signOut");
    }

    async trySignUp() {
        this.signingUp = true;
        console.log(this.formData);
        axios
            .post(
                `${process.env.VUE_APP_API_BASE_URL}/v1/users/${this.userId}/completeregistration`,
                this.formData
            )
            .then((res: any) => {
                console.log("Updated user: ", res.data);

                this.$store
                    .dispatch("signIn", {
                        email: this.formData.email,
                        password: this.formData.password,
                    })
                    .then(() => {
                        this.$router.push({ name: "Programmes" });
                    })
                    .catch((err) => {
                        LogUtils.Error(
                            "Sign in after completing user registration failed",
                            err,
                            "CompleteUserRegistrationView"
                        );
                        this.signingUp = false;
                        this.error = "Sign in failed  ";
                    });
            })
            .catch((err: any) => {
                LogUtils.Error(
                    "Error completing user registration",
                    err,
                    "CompleteUserRegistrationView"
                );
                this.signingUp = false;
                this.error = "Registration attempt failed  ";
            });
    }
}
