// import Country from '@/types/customer/Country';
import Vue from "vue";
import { Client } from "@twilio/conversations";
import { Message } from "@twilio/conversations/lib/message";
import { Conversation } from "@twilio/conversations/lib/conversation";
import { Participant } from "@twilio/conversations/lib/participant";
import { Paginator } from "@twilio/conversations/lib/interfaces/paginator";
import EventBus from "@/eventBus";
import LogUtils from "@/utils/LogUtils";

export default class ChatService {
    private chatClient: Client | null = null;

    private conversations: Conversation[] = [];

    private isInitialising = false;

    public async initialise() {
        if (this.isInitialising) {
            // console.log("[ChatService] XXXX Chat client already initialising XXXX");
            return;
        }

        this.isInitialising = true;

        if (this.isConnecting()) {
            // console.log("[ChatService] XXXX Chat client already connecting XXXX");
            return;
        }

        if (this.isConnected()) {
            // console.log("[ChatService] XXXX Chat client already connected XXXX");
            return;
        }

        ChatService.getChatToken()
            .then((token: string) => {
                Client.create(token)
                    .then(async (client) => {
                        this.chatClient = client;
                        console.log("[ChatService] **** Chat client created: ****", client);

                        this.chatClient.on("messageAdded", this.onMessageAdded);
                        this.chatClient.on("connectionStateChanged", this.onConnectionStateChanged);

                        this.chatClient.on("tokenAboutToExpire", () => {
                            ChatService.refreshToken(this.chatClient!);
                        });

                        this.chatClient.on("tokenExpired", () => {
                            ChatService.refreshToken(this.chatClient!);
                        });

                        this.chatClient.on("conversationUpdated", this.onConversationUpdated);
                        // console.log("************* ADDING HANDLER FOR channelUpdated ******************");
                        this.chatClient.on("participantUpdated", this.onParticipantUpdated);

                        console.log("[ChatService] Event handlers initialised");

                        await this.loadUserConversations();

                        this.isInitialising = false;
                    })
                    .catch(() => {
                        if (this.chatClient) {
                            this.chatClient.shutdown();
                            // console.log("[ChatService] XXXX Chat client destroyed: XXXX", this.chatClient);
                            this.chatClient = null;
                            this.isInitialising = false;
                        }
                    });
            })
            .catch((err: any) => {
                LogUtils.Error("Failed to get initial chat token", err, "ChatService");
            });
    }

    public async loadUserConversations() {
        this.conversations = [];
        await this.chatClient!.getSubscribedConversations().then(async (paginator) => {
            await this.loadNextPage(paginator);
        });

        console.log(
            "[ChatService] Loaded conversations:",
            this.conversations.map((c) => c.friendlyName)
        );

        console.log("---> CHAT_CHANNELS_LOADED (ChatService)");
        EventBus.$emit("CHAT_CHANNELS_LOADED");
    }

    public static async getChatToken(): Promise<string> {
        const tokenResponse = await Vue.prototype.$http.get(
            "/v1/users/me/chatToken?deviceId=browser"
        );
        return tokenResponse.data.token;
    }

    protected static refreshToken(client: Client) {
        ChatService.getChatToken()
            .then((token) => {
                client
                    .updateToken(token)
                    .then(() => {
                        console.log("[ChatService] Token refreshed");
                    })
                    .catch((err: any) => {
                        LogUtils.Error("Failed to update chat token", err, "ChatService");
                    });
            })
            .catch((err: any) => {
                LogUtils.Error("Failed to get updated chat token", err, "ChatService");
            });
    }

    public getChatClient(): Client | null {
        return this.chatClient;
    }

    public connectionState(): string | null {
        if (this.chatClient) {
            return this.chatClient!.connectionState;
        }
        return null;
    }

    public isConnected(): boolean {
        if (this.chatClient) {
            return this.chatClient!.connectionState === "connected";
        }
        return false;
    }

    public isConnecting(): boolean {
        if (this.chatClient) {
            return this.chatClient!.connectionState === "connecting";
        }
        return false;
    }

    public async disconnect() {
        if (this.chatClient) {
            return this.chatClient!.shutdown();
        }
        return null;
    }

    public async getChannels(): Promise<Conversation[]> {
        if (this.chatClient) {
            return this.conversations;
            // return this.chatClient!.getLocalChannels({ criteria: "lastMessage" });
        }
        // console.log("ChatClient not initialised so getChannels() returning empty array.");
        return [];
    }

    public async getUserUnreadMessageCount(): Promise<number> {
        const channels = await this.getChannels();

        const results: Array<Promise<number>> = [];

        channels.forEach((channel) => {
            results.push(ChatService.getChannelUnreadMessageCount(channel));
        });
        const userUnreadMessageCounts = await Promise.all(results);
        const userUnreadMessageCount = userUnreadMessageCounts.reduce((a, b) => a + b, 0);

        console.log("ChatService.getUserUnreadMessageCount() returning ", userUnreadMessageCount);
        return userUnreadMessageCount;
    }

    protected async loadNextPage(paginator: Paginator<Conversation>) {
        // console.log("Page", paginator);
        this.conversations.push(...paginator.items);
        // console.log("conversations", this.conversations);

        if (paginator.hasNextPage) {
            const newPaginator = await paginator.nextPage();
            await this.loadNextPage(newPaginator);
        }
    }

    public async getChannelUnreadMessageCountById(channelId: string): Promise<number | undefined> {
        const channels = await this.getChannels();
        const channel = channels.find((c) => c.sid === channelId);
        if (channel) {
            const channelUnreadMessageCount = await ChatService.getChannelUnreadMessageCount(
                channel
            );
            // console.log(`[ChatService] Found channel ${channelId} (${channel.friendlyName}) with unread message count: ${channelUnreadMessageCount}`);
            return channelUnreadMessageCount;
        }
        // console.log("[ChatService] Did not find channel: ", channelId);
        return undefined;
    }

    public async ensureConnected() {
        // console.log("[ChatService] ensureConnected() called, connection state is: ", this.connectionState());
        if (!this.isConnected() && !this.isConnecting()) {
            await this.disconnect();
            await this.initialise();
        }
    }

    protected onConnectionStateChanged(connectionState: any) {
        console.log("---> CHAT_CONNECTION_STATE_CHANGED (ChatService)", `*${connectionState}*`);
        EventBus.$emit("CHAT_CONNECTION_STATE_CHANGED", connectionState);
    }

    protected onMessageAdded(message: Message) {
        console.log("[ChatService] Message added", message);
        // console.log("---> CHAT_MESSAGE_ADDED (ChatService)", message);
        // EventBus.$emit("CHAT_MESSAGE_ADDED", message);
    }

    protected async onConversationUpdated(conversationUpdated: Conversation) {
        console.log("[ChatService] Conversation updated", conversationUpdated);

        console.log("---> CHAT_CHANNEL_UPDATED (ChatService)", conversationUpdated);
        EventBus.$emit("CHAT_CHANNEL_UPDATED", conversationUpdated);
    }

    protected onParticipantUpdated(memberUpdated: Participant) {
        console.log("[ChatService] Member updated", memberUpdated);
        // console.log("---> CHANNEL_UPDATED_MEMBER (ChatService)", memberUpdated);
        // EventBus.$emit("CHANNEL_UPDATED_MEMBER", memberUpdated);
    }

    public static getChannelMessageCount(conversation: Conversation): number {
        // console.log("getChannelMessageCount()", conversation);
        return conversation.lastMessage && conversation.lastMessage.index !== null
            ? conversation.lastMessage.index! + 1
            : 0;
    }

    public static async getChannelUnreadMessageCount(conversation: Conversation): Promise<number> {
        const messageCount = ChatService.getChannelMessageCount(conversation);

        const unreadCount =
            conversation.lastReadMessageIndex === null
                ? messageCount
                : (await conversation.getUnreadMessagesCount()) || 0;

        if (unreadCount > 0) {
            console.log(
                "getChannelUnreadMessageCount()",
                conversation.friendlyName,
                unreadCount,
                conversation.lastReadMessageIndex
            );
        }
        return unreadCount;
    }
}
