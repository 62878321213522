var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('time-line-item',{attrs:{"badge-type":"primary","badge-icon":"camera-video"}},[_c('b-row',{staticClass:"mb-3"},[_c('b-col',[_c('h5',{staticClass:"text-uppercase text-muted mb-0"},[(_vm.canDrag)?_c('b-icon',{staticClass:"mr-1 drag-handle",attrs:{"variant":"primary","icon":"grip-horizontal"}}):_vm._e(),_vm._v(" Video Activity "),(_vm.item.isArchived)?_c('b-badge',{staticClass:"ml-2 text-white",attrs:{"pill":"","variant":"dark"}},[_vm._v("Archived")]):_vm._e()],1),(_vm.userIsLearner)?_c('router-link',{attrs:{"to":{
                    name: 'VideoActivityInfo',
                    params: { programmeId: _vm.programmeId, activityId: _vm.item.id, fromTab: _vm.fromTab },
                }}},[_vm._v(" "+_vm._s(_vm.item.title)+" "),_c('b-icon',{attrs:{"id":`person-${_vm.item.id}-status`,"variant":_vm.status.variant,"icon":_vm.status.icon}}),_c('b-tooltip',{attrs:{"target":`person-${_vm.item.id}-status`,"variant":_vm.status.variant,"placement":"topright","title":_vm.status.tooltip,"triggers":"hover"}})],1):(_vm.learnerId && (_vm.userIsAdmin || _vm.item.facilitating))?_c('router-link',{attrs:{"to":{
                    name: 'VideoActivityLearner',
                    params: { programmeId: _vm.programmeId, activityId: _vm.item.id, learnerId: _vm.learnerId },
                }}},[_vm._v(" "+_vm._s(_vm.item.title)+" "),_c('b-icon',{attrs:{"id":`person-${_vm.item.id}-status`,"variant":_vm.status.variant,"icon":_vm.status.icon}}),_c('b-tooltip',{attrs:{"target":`person-${_vm.item.id}-status`,"variant":_vm.status.variant,"placement":"topright","title":_vm.status.tooltip,"triggers":"hover"}})],1):(_vm.userIsAdmin || _vm.item.facilitating)?_c('router-link',{attrs:{"to":{
                    name: 'VideoActivity',
                    params: { programmeId: _vm.programmeId, activityId: _vm.item.id, fromTab: _vm.fromTab },
                }}},[_vm._v(" "+_vm._s(_vm.item.title)+" "),(_vm.showStatus)?_c('b-icon',{attrs:{"variant":_vm.status.variant,"icon":_vm.status.icon}}):_vm._e()],1):_c('span',[_vm._v(" "+_vm._s(_vm.item.title)+" ")]),_c('br'),(_vm.item.endDateAndTime)?_c('small',{staticClass:"text-dark font-weight-bold"},[_vm._v("Due "+_vm._s(_vm.formattedDateTimeSpan("DD/MM/YYYY", _vm.item.endDateAndTime)))]):_c('small',{staticClass:"text-dark font-weight-bold"},[_c('em',[_vm._v("No due date set")])]),(_vm.isOverdue(_vm.item))?_c('b-badge',{staticClass:"ml-2",attrs:{"pill":"","variant":"danger"}},[_vm._v("Overdue")]):_vm._e(),(_vm.outOfDateOrder)?_c('b-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top.v-danger",value:('This item is out of date order'),expression:"'This item is out of date order'",modifiers:{"hover":true,"top":true,"v-danger":true}}],staticClass:"ml-1",attrs:{"variant":"danger","icon":"exclamation-circle-fill"}}):_vm._e(),_c('p',{staticClass:"text-sm mt-1 mb-0"},[_vm._v(" "+_vm._s(_vm.item.shortDescription)+" ")])],1),_c('b-col',{attrs:{"cols":"auto"}},[_c('div',{staticClass:"gp-avatar-group"},[(_vm.item.trainer)?_c('user-avatar',{attrs:{"size":"40","userId":_vm.item.trainer.userId,"firstName":_vm.item.trainer.firstName,"lastName":_vm.item.trainer.lastName}}):_vm._e(),_vm._l((_vm.item.trainers),function(t){return _c('user-avatar',{key:t.id,attrs:{"size":"40","userId":t.userId,"firstName":t.firstName,"lastName":t.lastName}})})],2)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }