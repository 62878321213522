import { extend } from "vee-validate";
import { required, email, confirmed } from "vee-validate/dist/rules";

extend("positive", (value: number) => value >= 0);

extend("required", {
    ...required,
    message: "This field is required",
});
extend("email", email);

extend("min", {
    validate(value, args) {
        return value.length >= args.length;
    },
    message: "This field must have at least {length} characters.",
    params: ["length"],
});

extend("max", {
    validate(value, args) {
        return value.length <= args.length;
    },
    message: "This field must have no more than {length} characters.",
    params: ["length"],
});

extend("confirmed", {
    ...confirmed,
    message: "The password fields must match",
});

extend("url", {
    validate(value) {
        const pattern = new RegExp(
            "^(https?:\\/\\/)?" + // protocol
                "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
                "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
                "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
                "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
                "(\\#[-a-z\\d_]*)?$",
            "i"
        ); // fragment locator
        return pattern.test(value);
    },
    message: "Please enter a valid link.",
});
