import { render, staticRenderFns } from "./MessagesView.vue?vue&type=template&id=7f797a73&scoped=true"
import script from "./MessagesView.vue?vue&type=script&lang=ts"
export * from "./MessagesView.vue?vue&type=script&lang=ts"
import style1 from "./MessagesView.vue?vue&type=style&index=1&id=7f797a73&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f797a73",
  null
  
)

export default component.exports