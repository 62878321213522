
import { Component, Prop, Vue } from "vue-property-decorator";
import TimeLineItem from "@/components/Timeline/TimeLineItem.vue";
import UserAvatar from "@/components/UserAvatar.vue";
import Utilities from "@/utils/Utilities";
import ChannelUnreadMessagesBadge from "@/components/ChannelUnreadMessagesBadge.vue";
import TimeZoneFormLabel from "@/components/dateTime/TimeZoneFormLabel.vue";
import { getApiV1 } from "@/services/getApiV1";
import LogUtils from "@/utils/LogUtils";

@Component({
    components: {
        TimeLineItem,
        UserAvatar,
        ChannelUnreadMessagesBadge,
        TimeZoneFormLabel,
    },
})
export default class TimeLineItemEvent extends Vue {
    @Prop({ type: Number, required: true }) programmeId!: number;

    @Prop({ required: true }) item!: any;

    @Prop({ type: Boolean, required: true }) userIsAdmin!: boolean;

    @Prop({ type: Boolean, required: true }) userIsLearner!: boolean;

    @Prop({ type: Number, required: false }) learnerId!: number;

    @Prop({ required: true }) showStatus!: boolean;

    @Prop({ type: Boolean, required: false, default: false }) canDrag!: boolean;

    @Prop({ type: Boolean, required: true }) outOfDateOrder!: boolean;

    @Prop({ type: Number, required: false }) fromTab!: number;

    @Prop({ type: Boolean, required: true }) autoInvite!: boolean;

    formattedDateTimeSpan(dateFormat, date1, date2) {
        return Utilities.formattedDateTimeSpan(dateFormat, date1, date2);
    }

    get status(): any {
        return Utilities.getStatusNew(
            this.item.eventType,
            this.item.completed,
            this.item.recordAttendance,
            this.item.past,
            this.item.attendanceIntended
        );
    }

    busy = false;

    markAttendanceIntended() {
        this.busy = true;
        getApiV1()
            .eventLearners.markAttendanceIntended(this.item.id, this.learnerId)
            .then(() => {
                this.item.attendanceIntended = true;
            })
            .catch((err: any) => {
                LogUtils.Error("Unable to update intention to attend", err, "TimeLineItemEvent");
                Utilities.gpErrorAlert(
                    this.$bvModal,
                    "An error occurred while updating the event."
                );
            })
            .finally(() => (this.busy = false));
    }

    clearAttendanceIntended() {
        this.busy = true;
        getApiV1()
            .eventLearners.clearAttendanceIntended(this.item.id, this.learnerId)
            .then(() => {
                this.item.attendanceIntended = false;
            })
            .catch((err: any) => {
                LogUtils.Error("Unable to update intention to attend", err, "TimeLineItemEvent");
                Utilities.gpErrorAlert(
                    this.$bvModal,
                    "An error occurred while updating the event."
                );
            })
            .finally(() => (this.busy = false));
    }
}
