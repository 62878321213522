
import { Component, Vue } from "vue-property-decorator";
import EventBus from "@/eventBus";

@Component({
    components: {},
})
export default class UserUnreadMessagesBadge extends Vue {
    unreadMessageCount: number | undefined = 0;

    get unreadMessageCountText(): string {
        return this.unreadMessageCount !== undefined && this.unreadMessageCount > 99
            ? "99+"
            : `${this.unreadMessageCount}`;
    }

    get isVisible(): boolean {
        return (
            this.unreadMessageCount !== undefined &&
            this.unreadMessageCount !== null &&
            this.unreadMessageCount > 0
        );
    }

    async created() {
        await this.updateUnreadMessageCount();
    }

    async mounted() {
        EventBus.$on("CHAT_CHANNELS_LOADED", this.onChatChannelsLoaded);
        EventBus.$on("CHAT_CHANNEL_UPDATED", this.onChatChannelUpdated);

        // EventBus.$on("CHAT_MESSAGE_ADDED", this.onChatMessageAdded);

        EventBus.$on("CHANNEL_UPDATED_MEMBER", this.onChannelUpdatedMember);
    }

    beforeDestroy() {
        EventBus.$off("CHAT_CHANNELS_LOADED", this.onChatChannelsLoaded);
        EventBus.$off("CHAT_CHANNEL_UPDATED", this.onChatChannelUpdated);
        // EventBus.$off("CHAT_MESSAGES_CONSUMED");
        // EventBus.$off("CHAT_MESSAGE_ADDED");
        EventBus.$off("CHANNEL_UPDATED_MEMBER", this.onChannelUpdatedMember);
    }

    async onChatChannelsLoaded() {
        console.log("<--- CHAT_CHANNELS_LOADED [UserUnreadMessagesBadge]");
        await this.updateUnreadMessageCount();
    }

    async onChatChannelUpdated(message: any) {
        if (
            (message.updateReasons as string[]).find(
                (reason) => reason === "lastMessage" || reason === "lastReadMessageIndex"
            )
        ) {
            console.log("<--- CHAT_CHANNEL_UPDATED [UserUnreadMessagesBadge]", message);
            await this.updateUnreadMessageCount();
        }
    }

    async onChannelUpdatedMember(arg: any) {
        console.log(
            "<--- CHANNEL_UPDATED_MEMBER [UserUnreadMessagesBadge] calling ChatService.getUserUnreadMessageCount()",
            arg
        );
        await this.updateUnreadMessageCount();
    }

    async updateUnreadMessageCount() {
        setTimeout(async () => {
            const newUnreadMessageCount = await globalThis.chatService.getUserUnreadMessageCount();
            if (this.unreadMessageCount !== newUnreadMessageCount) {
                // console.log(`[UserUnreadMessagesBadge] updated user unread message count to ${newUnreadMessageCount}`);
                this.unreadMessageCount = newUnreadMessageCount;
            }
        }, 1000);
    }
}
