
import { Component, Vue } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import axios from "axios";
import LogUtils from "@/utils/LogUtils";

@Component({
    components: {},
})
export default class ForgotPasswordView extends Vue {
    private validationObserver!: InstanceType<typeof ValidationObserver>;

    email = "";

    submitting = false;

    submitted = false;

    async trySubmit() {
        this.submitting = true;
        axios
            .post(`${process.env.VUE_APP_API_BASE_URL}/v1/users/forgotpassword`, {
                email: this.email,
            })
            .then(() => {
                this.submitting = false;
                this.submitted = true;
            })
            .catch((err: any) => {
                LogUtils.Error("Failure calling forgotpassword API", err, "ForgotPasswordView");
                this.submitting = false;
            });
    }
}
