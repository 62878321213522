
import { defineComponent } from "vue";
export default defineComponent({
    name: "base-button",
    props: {
        round: Boolean,
        icon: Boolean,
        block: Boolean,
        loading: Boolean,
        wide: Boolean,
        disabled: Boolean,
        type: {
            type: String,
            default: "default",
            description: "Button type (primary|secondary|danger etc)",
        },
        nativeType: {
            type: String,
            default: "button",
            description: "Button native type (e.g button, input etc)",
        },
        size: {
            type: String,
            default: "",
            description: "Button size (sm|lg)",
        },
        outline: {
            type: Boolean,
            description: "Whether button is outlined (only border has color)",
        },
        link: {
            type: Boolean,
            description: "Whether button is a link (no borders or background)",
        },
    },
    methods: {
        handleClick(evt) {
            this.$emit("click", evt);
        },
    },
});
