
import { Component, Prop, Vue } from "vue-property-decorator";
import Utilities from "@/utils/Utilities";
import store from "@/store/index";

@Component({})
export default class ResourcesAddItemBar extends Vue {
    @Prop({ required: false, default: false }) learner!: boolean;

    get resourceLibraryAccess(): boolean {
        return Utilities.userHasResourcesAccessLibraryPermission(store.state.user);
    }
}
