
import { Component, Prop, Vue } from "vue-property-decorator";
import moment from "moment";
import store from "@/store/index";
import Utilities from "@/utils/Utilities";
import LogUtils from "@/utils/LogUtils";
import EventbriteEventsDialog from "@/components/programmes/EventbriteEventsDialog.vue";
import { UserProgrammePermissions } from "@/contracts/v1/responses/UserProgrammePermissions";

@Component({
    components: {
        "eventbrite-events-dialog": EventbriteEventsDialog,
    },
})
export default class ProgrammeSettingsView extends Vue {
    @Prop({ required: true }) programmeId!: any;

    programmePermissions: UserProgrammePermissions | undefined;

    programme: any = null;

    selfRegistrationEnabled = false;

    eventbriteEvents: any[] = [];

    accountEventbriteIntegrationEnabled = false;

    userIsTenantAdmin: boolean = Utilities.userIsTenantAdmin(store.state.user);

    loading = true;

    loadingEventbriteEvents = true;

    customInvitationText = "";

    savingCustomInvitation = false;

    linkCopiedToClipboard = false;

    buttonCopiedToClipboard = false;

    get programmeEventbriteIntegrationEnabled(): boolean {
        return this.programme.eventbriteEventId !== null;
    }

    get connectedEventbriteEventName(): string {
        if (this.programme.eventbriteEventId === null) return "";

        const connectedEventbriteEvent = this.eventbriteEvents.find(
            (e) => e.eventId === this.programme.eventbriteEventId
        );
        return connectedEventbriteEvent === undefined ? "" : connectedEventbriteEvent.eventName;
    }

    get connectedEventbriteEventCreatedDate(): string {
        if (this.programme.eventbriteEventId === null) return "";

        const connectedEventbriteEvent = this.eventbriteEvents.find(
            (e) => e.eventId === this.programme.eventbriteEventId
        );
        return connectedEventbriteEvent === undefined
            ? ""
            : moment(connectedEventbriteEvent.created).format("DD/MM/YYYY");
    }

    get hasProSubscription(): boolean {
        return Utilities.userTenantHasProSubscription(store.state.user);
    }

    get defaultInvitationText(): string {
        return `${this.programme.trainer.firstName} ${this.programme.trainer.lastName} has invited you to attend programme '${this.programme.title}' on behalf of ${this.programme.client.name}.`;
    }

    get selfRegistrationUrl(): string {
        return `${process.env.VUE_APP_BASE_URL}/go?code=${this.programme.registrationCode}`;
    }

    created() {
        Promise.all([
            Vue.prototype.$http.get(`/v1/programmes/${this.programmeId}`),
            Vue.prototype.$http.get(
                `/v1/accounts/${Utilities.userTenantId(store.state.user)}/integrations/eventbrite`
            ),
        ])
            .then((res: any) => {
                console.log("Programme: ", res[0].data);
                this.programme = res[0].data;
                this.programmePermissions = JSON.parse(
                    res[0].headers["x-gp-permissions"]
                ).programme;
                console.log("Programme permissions", this.programmePermissions);
                this.customInvitationText = this.programme.customInvitation;
                this.selfRegistrationEnabled = this.programme.selfRegistrationEnabled;

                this.accountEventbriteIntegrationEnabled = res[1].data.integrationEnabled;

                if (this.accountEventbriteIntegrationEnabled) {
                    Vue.prototype.$http
                        .get(
                            `/v1/accounts/${Utilities.userTenantId(
                                store.state.user
                            )}/integrations/eventbrite/events`
                        )
                        .then((result) => {
                            console.log("eventbriteEvents", result);
                            this.eventbriteEvents = result.data.events;
                            this.loadingEventbriteEvents = false;
                        })
                        .catch(() => {
                            this.loadingEventbriteEvents = false;
                        });
                } else {
                    this.loadingEventbriteEvents = false;
                }

                this.loading = false;
            })
            .catch((err: any) => {
                LogUtils.Error("Unable to get programme", err, "ProgrammeSettingsView");
                this.programme = null;
            });
    }

    onDisconnectFromEventbriteEvent() {
        console.log("onDisconnectFromEventbriteEvent()");
        Vue.prototype.$http
            .delete(`/v1/programmes/${this.programmeId}/eventbrite`)
            .then(() => {
                this.programme.eventbriteEventId = null;
            })
            .catch((err) => {
                Utilities.gpErrorAlert(
                    this.$bvModal,
                    "An error occurred while disconnecting the Eventbrite event"
                );
                LogUtils.Error(
                    "Could not disconnect the Eventbrite event",
                    err,
                    "ProgrammeSettingsView"
                );
            });
    }

    onConnectToEventbriteEvent() {
        console.log("onConnectToEventbriteEvent()");
        this.$bvModal.show("eventbrite-events-dialog");
    }

    onEventbriteEventSelected(eventbriteOrganizationId: string, eventbriteEventId: string) {
        console.log("onEventbriteEventSelected()", eventbriteEventId);
        Vue.prototype.$http
            .post(`/v1/programmes/${this.programmeId}/eventbrite`, {
                eventbriteOrganizationId,
                eventbriteEventId,
                programmeId: this.programmeId,
            })
            .then(() => {
                this.programme.eventbriteEventId = eventbriteEventId;
            })
            .catch((err) => {
                Utilities.gpErrorAlert(
                    this.$bvModal,
                    "An error occurred while connecting the Eventbrite event"
                );
                LogUtils.Error(
                    "Could not connect to Eventbrite event",
                    err,
                    "ProgrammeSettingsView"
                );
            });
    }

    saveCustomInvitationText(customInvitationText: string) {
        this.savingCustomInvitation = true;
        Vue.prototype.$http
            .post(`/v1/programmes/${this.programmeId}/customInvitation`, { customInvitationText })
            .then(() => {
                this.customInvitationText = customInvitationText;
                Utilities.gpSuccessAlert(
                    this.$bvModal,
                    customInvitationText == ""
                        ? "Custom programme invitation reset."
                        : "Custom programme invitation updated."
                ).then(() => {
                    this.programme.customInvitation = customInvitationText;
                    this.savingCustomInvitation = false;
                    console.log("refs", this.$refs);
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (this.$refs.customInvitationText as any).setFlags({
                        dirty: false,
                    });
                });
            })
            .catch((err) => {
                Utilities.gpErrorAlert(
                    this.$bvModal,
                    "An error occurred while updating the custom invitation"
                );
                LogUtils.Error("Could not update custom invitation", err, "ProgrammeSettingsView");
                this.savingCustomInvitation = false;
            });
    }

    onCopyLinkToClipboard() {
        navigator.clipboard.writeText(this.selfRegistrationUrl);
        this.linkCopiedToClipboard = true;
        setTimeout(async () => {
            this.linkCopiedToClipboard = false;
        }, 3000);
    }

    onSelfRegistrationEnabledChanged() {
        console.log("SelfRegistrationEnabled", this.selfRegistrationEnabled);
        Vue.prototype.$http
            .post(`/v1/programmes/${this.programme.id}/enableselfregistration`, {
                enable: this.selfRegistrationEnabled,
            })
            .catch((err) => {
                LogUtils.Error(
                    "Unable to enable/disable self registration",
                    err,
                    "ProgrammeSettingsView"
                );
                this.selfRegistrationEnabled = !this.selfRegistrationEnabled;
            });
    }

    onCopyRegisterButtonToClipboard() {
        try {
            const registerButtonHtml =
                '<br/><table border="0" cellspacing="0" cellpadding="0"><tbody><tr><td style="padding: 12px 18px 12px 18px;border-radius: 5px;background-color: #f47920;" align="center"><a rel="noopener" target="_blank" href="__REGISTER_URL__" style="font-size: 18px;font-family: Open Sans, sans-serif;font-weight: bold;color: #ffffff;text-decoration: none;display: inline-block;">Register now →</a></td></tr></tbody></table><br/>';
            const html = registerButtonHtml.replace("__REGISTER_URL__", this.selfRegistrationUrl);
            const type = "text/html";
            const blob = new Blob([html], { type });
            // eslint-disable-next-line no-undef
            const data = [new ClipboardItem({ [type]: blob })];

            navigator.clipboard.write(data).then(
                () => {
                    /* success */
                    this.buttonCopiedToClipboard = true;
                    setTimeout(async () => {
                        this.buttonCopiedToClipboard = false;
                    }, 3000);
                },
                () => {
                    Utilities.gpErrorAlert(
                        this.$bvModal,
                        `An error occurred copying to the clipboard.`
                    );
                }
            );
        } catch (error) {
            Utilities.gpErrorAlert(
                this.$bvModal,
                `Your browser does not support copying html content to the clipboard.`,
                "Clipboard error"
            );
        }
    }
}
