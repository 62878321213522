
import { Component, Prop, Vue } from "vue-property-decorator";
import { EventSummary } from "@/contracts/v1/responses/EventSummary";
import { EventType } from "@/contracts/v1/enums/EventType";

@Component({
    components: {},
})
export default class ProgrammeAutoInviteItem extends Vue {
    @Prop({ required: true }) programmeId!: any;
    @Prop({ required: true }) sectionId!: any;
    @Prop({ required: true }) programmeAutoInviteLearners!: boolean;
    @Prop({ required: true }) sectionAutoInviteLearners!: boolean;
    @Prop({ required: true }) item!: EventSummary;

    itemAutoInviteLearners = false;

    created() {
        this.itemAutoInviteLearners = this.item.autoInviteLearners;
    }

    get valueToDisplay() {
        return this.programmeAutoInviteLearners || this.sectionAutoInviteLearners
            ? true
            : this.itemAutoInviteLearners;
    }

    get itemType() {
        switch (this.item.eventType) {
            case EventType.Virtual:
            case EventType.InPerson:
            case EventType.ZoomMeeting:
                return "Event";
            case EventType.GenericActivity:
                return "Activity";
            case EventType.Questionnaire:
                return "Questionnaire";

            default:
                return "";
        }
    }

    onItemAutoInviteChanged(checked: boolean) {
        this.itemAutoInviteLearners = checked;
        this.$emit("item-auto-invite-changed", this.item.id, checked);
    }
}
