
import { Component, Vue } from "vue-property-decorator";
import store from "@/store/index";
import Utilities from "@/utils/Utilities";
import { IKnowledgeBaseCategory } from "./IKnowledgeBaseCategory";

@Component({
    components: {},
})
export default class TheKnowledgeBaseLinks extends Vue {
    loading = true;

    categories: IKnowledgeBaseCategory[] = [
        {
            id: "Z496D0mX",
            name: "Getting started",
            url: "https://giraffepad.outseta.com/support/kb#/categories/Z496D0mX/getting-started-manage-your-account",
            userRoles: ["Trainer", "TenantAdmin"],
        },
        {
            id: "krQV0eW6",
            name: "Creating and managing programmes",
            url: "https://giraffepad.outseta.com/support/kb#/categories/krQV0eW6/creating-and-managing-programmes",
            userRoles: ["Trainer", "TenantAdmin"],
        },
        {
            id: "dpWrOnmn",
            name: "Support for learners",
            url: "https://giraffepad.outseta.com/support/kb#/categories/dpWrOnmn/support-for-learners",
            userRoles: ["Learner"],
        },
        {
            id: "oz9MZJW4",
            name: "Using Zoom with GiraffePad",
            url: "https://giraffepad.outseta.com/support/kb#/categories/oz9MZJW4/using-zoom-with-giraffepad",
            userRoles: ["Trainer", "TenantAdmin"],
        },
        {
            id: "O79OwZ9E",
            name: "F.A.Q.",
            url: "https://giraffepad.outseta.com/support/kb#/categories/O79OwZ9E/frequently-asked-questions",
            userRoles: ["Trainer", "TenantAdmin"],
        },
        {
            id: "By9qoKmA",
            name: "Support",
            url: "https://giraffepad.outseta.com/support/kb#/categories/By9qoKmA/support",
            userRoles: ["Trainer", "TenantAdmin"],
        },
        {
            id: "Kj9bXMmn",
            name: "Privacy",
            url: "https://giraffepad.outseta.com/support/kb#/categories/Kj9bXMmn/privacy",
            userRoles: ["Trainer", "TenantAdmin"],
        },
        {
            id: "By9qxqQA",
            name: "Terms of Service",
            url: "https://giraffepad.outseta.com/support/kb#/categories/By9qxqQA/terms-of-service",
            userRoles: ["Trainer", "TenantAdmin"],
        },
    ];

    get userCategories(): IKnowledgeBaseCategory[] {
        if (this.loading) {
            return [];
        }

        return this.categories.filter((t) =>
            Utilities.userIsInAnyOfRoles(store.state.user, t.userRoles)
        );
    }

    get isVisible(): boolean {
        return !this.loading;
    }

    created() {
        this.loading = false;
    }
}
