
import { defineComponent } from "vue";
import Utilities from "@/utils/Utilities";
import UserAvatar from "../UserAvatar.vue";
import ChannelUnreadMessagesBadge from "@/components/ChannelUnreadMessagesBadge.vue";

export default defineComponent({
    name: "ProgrammeCard",
    components: {
        "user-avatar": UserAvatar,
        "channel-unread-messages-badge": ChannelUnreadMessagesBadge,
        // 'messages-badge': window.httpVueLoader('/js/components/MessagesBadge.vue')
    },
    props: {
        programmeId: {
            type: Number,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        bannerImageId: {
            type: String,
            required: false,
        },
        clientId: {
            type: Number,
            required: true,
        },
        clientName: {
            type: String,
            required: true,
        },
        tenantId: {
            type: Number,
            required: false,
        },
        tenantHasLogo: {
            type: Boolean,
            required: false,
            default: false,
        },
        chatChannelId: {
            type: String,
            required: false,
            default: null,
        },
        trainer: {
            required: true,
        },
        additionalTrainers: {
            required: true,
        },
        userIsFacilitating: {
            type: Boolean,
            required: true,
        },
        canEdit: {
            type: Boolean,
            required: true,
        },
        canNavigate: {
            type: Boolean,
            required: true,
        },
        isSample: {
            type: Boolean,
            required: true,
        },
        isArchived: {
            type: Boolean,
            required: true,
        },
        activeTenantSubscription: {
            type: Boolean,
            required: true,
        },
        eventbriteConnection: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    methods: {
        urlProgrammeEdit(programmeId) {
            return `programme/edit/${programmeId}`;
        },
        urlClientDetails(clientId) {
            return `client/details/${clientId}`;
        },
        tenantLogoUrl(tenantId) {
            return Utilities.tenantLogoUrl(tenantId);
        },
        avatarUrl(userId) {
            return Utilities.avatarUrl(userId);
        },
        bannerImageUrl() {
            return this.bannerImageId
                ? `${process.env.VUE_APP_BANNER_IMAGE_BASE_URL}/${this.tenantId}/${this.bannerImageId}`
                : null;
        },
    },
    computed: {
        navigationDisabled() {
            return !this.canNavigate || (this.userIsFacilitating && !this.activeTenantSubscription);
        },
        route() {
            // if (!this.canNavigate || (this.userIsFacilitating && !this.activeTenantSubscription)) {
            //     return null;
            // }
            if (this.userIsFacilitating) {
                return { name: "Programme", params: { programmeId: this.programmeId } };
            }

            return { name: "ProgrammeInfo", params: { programmeId: this.programmeId } };
        },
    },
});
