
import { Component, Prop, Vue } from "vue-property-decorator";
import EventBus from "@/eventBus";

@Component({
    components: {},
})
export default class ChannelUnreadMessagesBadge extends Vue {
    @Prop({ required: true, default: "" }) channelId!: string;

    @Prop({ required: true }) viewName!: string;

    unreadMessageCount: number | undefined = 0;

    get unreadMessageCountText(): string {
        return this.unreadMessageCount !== undefined && this.unreadMessageCount > 99
            ? "99+"
            : `${this.unreadMessageCount}`;
    }

    get isVisible(): boolean {
        return (
            this.unreadMessageCount !== undefined &&
            this.unreadMessageCount !== null &&
            this.unreadMessageCount > 0
        );
    }

    async created() {
        // console.log(`[ChannelUnreadMessagesBadge] (${this.viewName}) ${this.channelId} created`);
        await this.updateUnreadMessageCount(false);
    }

    mounted() {
        EventBus.$on("CHAT_CHANNEL_UPDATED", this.onChatChannelUpdated);

        EventBus.$on("CHAT_CHANNELS_LOADED", this.onChatChannelsLoaded);
    }

    beforeDestroy() {
        EventBus.$off("CHAT_CHANNEL_UPDATED", this.onChatChannelUpdated);
        EventBus.$off("CHAT_CHANNELS_LOADED", this.onChatChannelsLoaded);
    }

    async onChatChannelUpdated(message: any) {
        if (
            message.conversation.sid === this.channelId &&
            (message.updateReasons as string[]).find(
                (reason) => reason === "lastMessage" || reason === "lastReadMessageIndex"
            )
        ) {
            console.log(
                `<--- CHAT_CHANNEL_UPDATED [ChannelUnreadMessagesBadge] (${this.viewName}) ${this.channelId}`,
                message
            );
            await this.updateUnreadMessageCount(true);
        }
    }

    async onChatChannelsLoaded() {
        console.log(
            `<--- CHAT_CHANNELS_LOADED [ChannelUnreadMessagesBadge] (${this.viewName}) ${this.channelId}`
        );
        await this.updateUnreadMessageCount(false);
    }

    async updateUnreadMessageCount(delay: boolean) {
        if (delay) {
            await this.updateUnreadMessageCountNow();
        } else {
            await this.updateUnreadMessageCountNow();
        }
    }

    async updateUnreadMessageCountNow() {
        if (globalThis.chatService !== undefined) {
            setTimeout(async () => {
                const newUnreadMessageCount = await globalThis.chatService
                    .getChannelUnreadMessageCountById(this.channelId)
                    .catch(() => (this.unreadMessageCount = undefined));
                if (this.unreadMessageCount !== newUnreadMessageCount) {
                    console.log(
                        `[ChannelUnreadMessagesBadge] (${this.viewName}) ${this.channelId} updated unread message count to ${newUnreadMessageCount}`
                    );
                    this.unreadMessageCount = newUnreadMessageCount;
                }
            }, 1000);
        }
    }
}
