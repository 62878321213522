var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"wrapper",attrs:{"id":"the-app"}},[(_vm.$store.state.menuVisible)?_c('side-bar',{attrs:{"logo":require("@/assets/gp-logo-combined.png"),"data-tour":"side-menu"}},[_c('template',{slot:"links"},_vm._l((_vm.userItems),function(item){return _c('sidebar-item',{key:item.routeName,attrs:{"link":{
                    name: `${item.text}`,
                    icon: `${item.icon}`,
                    path: { name: `${item.routeName}` },
                },"showUnreadMessages":item.showUserUnreadMessages,"data-tour":`side-menu-${item.text}`}},_vm._l((item.children),function(childItem){return _c('sidebar-item',{key:childItem.routeName,attrs:{"link":{
                        name: `${childItem.text}`,
                        icon: 'ni ni-shop text-primary',
                        path: { name: `${childItem.routeName}` },
                    },"data-tour":`side-menu-${item.text}-${childItem.text}`}})}),1)}),1)],2):_vm._e(),_c('main',{staticClass:"main-content",class:_vm.$mq,attrs:{"role":"main"}},[_c('dashboard-navbar',{attrs:{"type":"default"}}),_c('div',{staticClass:"the-app-container",on:{"click":function($event){return _vm.$sidebar.displaySidebar(false)}}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }